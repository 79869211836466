import { medalRequirement } from "./../domain/medalRequirement";
import { QuaggaJSConfigObject, QuaggaJSStatic } from "@ericblade/quagga2";

const idealVideoSize = { width: 1280, height: 720 };

export const getDeviceInfos = async (): Promise<
  { deviceId: string; label: string }[]
> => {
  const stream = await navigator.mediaDevices.getUserMedia({
    video: { ...idealVideoSize },
  });
  const devices = await navigator.mediaDevices.enumerateDevices();
  const deviceInfo = devices
    .filter((device) => device.kind === "videoinput")
    .map((device) => ({ deviceId: device.deviceId, label: device.label }));

  return deviceInfo;
};

let Quagga: QuaggaJSStatic | undefined;

export const startBarcodeReader = async (
  cameraNumber: number,
  onDetected: (results: any) => void
): Promise<number> => {
  // カメラの数を取得してデバイスIDを決定
  const deviceInfos = await getDeviceInfos();
  const N = deviceInfos.length;
  const newCameraNumber =
    0 <= cameraNumber && cameraNumber < N ? cameraNumber : 0;
  const deviceId = N > 1 ? deviceInfos[newCameraNumber].deviceId : undefined;

  // Quaggaを動的インポート
  Quagga = (await import("@ericblade/quagga2")).default;
  if (typeof Quagga === "undefined") return 0;
  const config: QuaggaJSConfigObject = {
    inputStream: {
      name: "Live",
      type: "LiveStream",
      target: document.querySelector("#cameraArea") as Element,
      constraints: {
        ...idealVideoSize,
        deviceId,
      },
    },
    numOfWorkers: 2,
    decoder: {
      readers: ["ean_reader", "ean_8_reader"],
      debug: {
        drawBoundingBox: true,
        showFrequency: true,
        drawScanline: true,
        showPattern: true,
      },
      multiple: true,
    },
    frequency: 10,
  };

  // 初期化が完了したらスタート
  Quagga.init(config, (err) => {
    if (err) {
      return;
    }
    if (typeof Quagga !== "undefined") Quagga.start();
  });

  // 検出したバーコードに対しての処理
  Quagga.onProcessed((results) => {
    if (typeof Quagga === "undefined") return;

    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (!results) return;

    drawingCtx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);

    // @ts-expect-error: QuaggaJSResultObject[] は配列
    (results as QuaggaJSResultObject[]).forEach((result) => {
      if (typeof Quagga === "undefined") return;

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
          color: "#3ba8d1",
          lineWidth: 5,
        });
      }
      // 検出に成功した瞬間の水平の赤い線
      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(
          result.line,
          { x: "x", y: "y" },
          drawingCtx,
          {
            color: "red",
            lineWidth: 3,
          }
        );
      }
    });
  });
  // デコードしたらコールバックを呼ぶ
  Quagga.onDetected(onDetected);

  return newCameraNumber;
};

export const stopBarcodeReader = (): void => {
  if (typeof Quagga !== "undefined") Quagga.stop();
  Quagga = undefined;
};
