import { httpsCallable } from "@firebase/functions";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { getFunctions } from "@/infrastructure/firebase";
import { changePasswordData } from "functions/src/user";

export const changePassword = async (uid: string, password: string) => {
  const data: changePasswordData = {
    id: uid,
    password: password,
  };
  const res = await httpsCallable(getFunctions(), "changePassword")(data);
};

export const setFirebaseUI = async (
  container: string,
  signInsuccessCallback: (uid: string) => void
) => {
  const auth = getAuth();
  if (auth == undefined) {
    console.error("auth is undefined");
    return;
  }
  const firebaseui = await import("firebaseui");
  const ui =
    firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
  const microsoft_provider = new OAuthProvider("microsoft.com");

  ui.start(container, {
    signInSuccessUrl: "/",
    signInOptions: [
      // "apple.com",
      // "microsoft.com",
      GoogleAuthProvider.PROVIDER_ID,
      microsoft_provider.providerId,
    ],
    callbacks: {
      signInSuccessWithAuthResult: function (
        authResult: any,
        redirectUrl: any
      ) {
        signInsuccessCallback(authResult.user.uid);
        return true;
      },
    },
  });
  auth.languageCode = "ja_JP";
};

export class authService {
  private _signInState = false;
  private _userName = "";
  private _userPhotoURL = "";
  private _uid = "";
  private _signInCallback = (uid: string) => {
    return;
  };
  private _signOutCallback = () => {
    return;
  };

  get signInState() {
    return this._signInState;
  }

  // for debug
  static getUid() {
    const auth = getAuth();
    return auth.currentUser?.uid;
  }

  get userName() {
    return this._userName;
  }

  get userPhotoURL() {
    return this._userPhotoURL;
  }

  get uid() {
    return this._uid;
  }

  startAuth(
    signInCallback: (uid: string) => void,
    signOutCallback: () => void
  ) {
    this._signInCallback = signInCallback;
    this._signOutCallback = signOutCallback;

    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this._userName = user.displayName ?? "";
        this._userPhotoURL = user.photoURL ?? "";
        this._uid = user.uid;
        this._signInState = true;
        this._signInCallback(user.uid);
      } else {
        this._userName = "";
        this._userPhotoURL = "";
        this._uid = "";
        this._signInState = false;
        this._signOutCallback();
      }
    });
  }

  // if succeeded return status, if not throw error.
  signIn = async (accountName: string, password: string) => {
    // convert half/full-width @, *, and # to @
    const signInEmailAddress =
      accountName.replace(/[＠＃#*＊]/g, "@") + ".book-card.eteq.co.jp";

    const auth = getAuth();

    try {
      const credential = await signInWithEmailAndPassword(
        auth,
        signInEmailAddress,
        password
      );
      this._uid = credential.user.uid;
    } catch (err) {
      console.log(err);
      if (
        err == "FirebaseError: Firebase: Error (auth/user-not-found)." ||
        err == "FirebaseError: Firebase: Error (auth/wrong-password)."
      )
        throw new Error(`ユーザー名またはパスワードが誤っています。`);
      else if (
        err == "FirebaseError: Firebase: Error (auth/network-request-failed)."
      )
        throw new Error(`インターネットに接続されていません。`);
      else if (err == "FirebaseError: Firebase: Error (auth/invalid-email).")
        throw new Error(`ユーザー名の形式が誤っています。`);
      else throw new Error(`${err}`);
    }
  };

  signOut = async () => {
    const auth = getAuth();
    await auth.signOut();
    if (typeof location !== "undefined") {
      setTimeout(() => location.reload(), 1000);
    }
  };
}
