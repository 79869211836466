<template>
  <div class="modal fade" id="updateInfoModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">最新情報</h3>
          <button type="button" class="btn-close" @click="onClose"></button>
        </div>
        <div class="modal-body">
          <template v-for="(row, i) in props.updateInfo.data">
            <hr v-if="i != 0" />
            <h4 class="">
              {{ getDateString(row.date, store.state.userPreference.hiragana) }}
            </h4>
            <template v-if="row.title == 'like'">
              <pre>{{ row.contents }}</pre>
              <a href="javascript:void(0);" @click="onLikedCard">いいねされたカード</a>
            </template>
            <template v-else>
              <b>{{ row.title }}</b>
              <pre>{{ row.contents }}</pre>
            </template>
          </template>
          <p class="text-center" v-if="props.updateInfo.data == 0">
            最新情報はありません。
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="onClose"
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, onMounted, computed } from "vue";
import { useModal } from "./ModalInterface";
import { UpdateInfo } from "@/domain/UpdateInfo";
import { getDateString } from "@/utilities/misc";
import { useStore } from "@/store/store";

export default defineComponent({
  name: "Modal",

  props: {
    updateInfo: {
      type: Object as PropType<UpdateInfo>,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();

    onMounted(() => {
      // openModal();
    });

    const initModal = () => {};

    const { openModal, closeModal } = useModal("updateInfoModal", initModal, false);

    const onClose = () => {
      props.updateInfo.hasBeenSeen();
      closeModal();
    };

    const onLikedCard = () => {
      props.updateInfo.hasBeenSeen();
      store.commit("setLastLikedFrom", props.updateInfo.lastGetUpdateInfo);
      store.commit("setSort", "lastliked");
      closeModal();
    };

    return {
      store,
      openModal,
      props,
      getDateString,
      onClose,
      onLikedCard,
    };
  },
});
</script>
