// Get: everyone
// Write: root
export class School {
  constructor(
    public id: string | undefined = undefined,
    public schoolName: string = "",
    public city: string = "",
    public numCards: number = 0,
    public schoolType: SchoolType = "elementary" // if not exist on document, it is "elementary"
  ) {}
}

export class SchoolCreateRequest extends School {
  constructor(
    readonly id: string | undefined = undefined,
    readonly schoolName: string = "",
    readonly city: string = "",
    readonly prefix: string = "",
    readonly schoolType: SchoolType = "elementary",
    readonly emailRegex: string = "",
    readonly maxStudentNum: number = 9999
  ) {
    super(id, schoolName, city, 0, schoolType);
    this.numCards = 0;
  }
}

export type SchoolType = "elementary" | "juniorhigh";
