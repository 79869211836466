<script setup lang="ts">
import { defineProps, defineEmits, ref, onMounted } from "vue";
import { getBook } from "@/infrastructure/BookRepository";
import { useStore } from "@/store/store";
import Hiragana from "@/components/Hiragana.vue";
import { Book } from "@/domain/Book";

const props = defineProps<{ isbn: string }>();
const emits = defineEmits(["close"]);

const store = useStore();
const num = ref(0);

onMounted(async () => {
  const book = await getBook(props.isbn, store.state.school.schoolType);
  num.value = book.num;
});

const onNumber = () => {
  store.commit("setIsbn", props.isbn);
  store.commit("setSort", "samebook");
  emits("close");
};
</script>

<template>
  <span
    ><Hiragana f="おなじ ほんの かんそう">同じ本の感想</Hiragana>
    <span class="book-icon">
      <svg class="icon" fill="currentColor">
        <use xlink:href="/icon/icon-books.svg#icon" />
      </svg>
      <a href="javascript:void(0);" @click="onNumber" class="number">{{ num }}</a>
    </span>
  </span>
</template>
