import { SchoolSettings } from "@/domain/SchoolSettings";
import { getFirestore } from "@firebase/firestore";
import {
  FirestoreDataConverter,
  getDoc,
  setDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  Unsubscribe,
  onSnapshot,
} from "firebase/firestore";
import { Banner } from "../domain/Banner";

type docDataType = Partial<
  // need to use ommit to replate type of 'banners'
  Omit<SchoolSettings, "banners"> & { banners?: string[] }
>;

export const schoolSettingsConverter: FirestoreDataConverter<SchoolSettings> = {
  toFirestore(setting: SchoolSettings) {
    const data: docDataType = {
      repeatedPostLimit: setting.repeatedPostLimit, // edit same review if user try to
      tagsTemplate: setting.tagsTemplate,
      inappropriateWords: setting.inappropriateWords,
      inappropreatePublisher: setting.inappropreatePublisher,
      allowedCCodes: setting.allowedCCodes,
      bannedCCodes: setting.bannedCCodes,
      bannedCardIds: setting.bannedCardIds,
      banners: setting.banners.map((banner) => JSON.stringify(banner)), // json(banner)),
      minReviewCharacter: setting.minReviewCharacter,
      enableStudentSignin: setting.enableStudentSignin,
      allowChangingClass: setting.allowChangingClass,
      allowChangingClassMax: setting.allowChangingClassMax,
      enableMedal: setting.enableMedal,
      hideNameAndClass: setting.hideNameAndClass,
    };
    return data;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options) as docDataType;

    const schoolSettings = new SchoolSettings(
      snapshot.ref.parent?.parent?.id, // get schoolId
      data.repeatedPostLimit ?? 1440,
      data.tagsTemplate ?? [],
      data.inappropriateWords ?? [],
      data.inappropreatePublisher ?? [],
      data.allowedCCodes ?? ".*",
      data.bannedCCodes ?? "",
      data.bannedCardIds ?? [],
      data.banners?.map((banner: string) => JSON.parse(banner)) ??
        ([] as Banner[]),
      data.minReviewCharacter ?? [0, 0, 0, 0, 0, 0],
      data.enableStudentSignin ?? true,
      data.allowChangingClass ?? false,
      data.allowChangingClassMax ?? 9,
      data.enableMedal ?? true,
      data.hideNameAndClass ?? false
    );

    return schoolSettings;
  },
};

export const storeSetting = async (setting: SchoolSettings): Promise<void> => {
  const schoolId = setting.schoolId;
  if (typeof schoolId === "undefined")
    throw new Error("SchoolId is not specified.");

  const ref = doc(
    getFirestore(),
    "Schools",
    schoolId,
    "Private",
    "settings"
  ).withConverter(schoolSettingsConverter);

  await setDoc(ref, setting); // School setting is cleated only by function, and setDoc is used
};

export const getSetting = async (schoolId: string): Promise<SchoolSettings> => {
  const ref = doc(
    getFirestore(),
    "Schools",
    schoolId,
    "Private",
    "settings"
  ).withConverter(schoolSettingsConverter);
  const snapshot = await getDoc(ref);
  if (!snapshot.exists())
    throw new Error(`setitng for ${schoolId} does not exist`);

  return snapshot.data();
};

export const getSchoolSettingsByRealtimeUpdate = (
  schoolId: string,
  callback: (schoolsettings: SchoolSettings) => void
): Unsubscribe => {
  const ref = doc(
    getFirestore(),
    "Schools",
    schoolId,
    "Private",
    "settings"
  ).withConverter(schoolSettingsConverter);
  const unsubscribed = onSnapshot(ref, (snapshot) => {
    const schoolYearClass = snapshot.data();

    if (typeof schoolYearClass !== "undefined") callback(schoolYearClass);
  });

  return unsubscribed;
};

export const addBannedCard = (schoolId: string, cardId: string): void => {
  updateDoc(doc(getFirestore(), "Schools", schoolId, "Private", "settings"), {
    bannedCardIds: arrayUnion(cardId),
  });
};

export const removeBannedCard = (schoolId: string, cardId: string): void => {
  updateDoc(doc(getFirestore(), "Schools", schoolId, "Private", "settings"), {
    bannedCardIds: arrayRemove(cardId),
  });
};
