import { createStore, useStore as baseUseStore, Store } from "vuex";
import { InjectionKey } from "vue";
import { SchoolYearClass } from "../domain/SchoolYearClass";
import { User } from "@/domain/User";
import { UserPreference, loadPreference, savePreference } from "./localstorage";
import {
  getUser,
  getUserByRealtimeUpdate,
} from "@/infrastructure/UserRepository";
import * as Sentry from "@sentry/vue";
import { authService } from "@/infrastructure/auth";
import { CardSelector, Filters, Sorts } from "@/domain/cardSelector";
import { School } from "@/domain/School";
import { SchoolSettings } from "@/domain/SchoolSettings";
import { SchoolAccount } from "@/domain/SchoolAccount";
import { getSchoolSettingsByRealtimeUpdate } from "@/infrastructure/SchoolSettingsRepository";
import { getSchoolAccount } from "@/infrastructure/SchoolAccountRepository";
import {
  getSchool,
  getSchoolByRealtimeUpdate,
} from "@/infrastructure/SchoolRepository";
import {
  getSchoolYearClass,
  getSchoolYearClassByRealtimeUpdate,
} from "@/infrastructure/SchoolYearClassRepository";
import { MyLikes } from "@/domain/MyLikes";
import { setListerForMyLikes } from "@/infrastructure/MyLikeRepository";
import { NumLiked } from "@/domain/NumLiked";
import { getNumLikedRealtimeUpdate } from "@/infrastructure/NumLikedRepository";

export interface State {
  mode: "login" | "admin" | "adminuser" | "root" | "home" | "cardnum";
  cardSelector: CardSelector;
  auth: authService;
  user: User;
  school: School;
  schoolYears: number[];
  schoolSettings: SchoolSettings;
  schoolAccount: SchoolAccount;
  schoolYearClass: SchoolYearClass;
  userPreference: UserPreference;
  myLikes: MyLikes;
  numLiked: NumLiked;
  unsubscribeUser?: () => void;
  unsubscribeSchool?: () => void;
  unsubscribeSchoolSettings?: () => void;
  unsubscribeSchoolYearClass?: () => void;
  unsubscribeMyLike?: () => void;
  unsubscribeNumLiked?: () => void;
}

export const key: InjectionKey<Store<State>> = Symbol();

const setUserInfo = async (state: State, uid: string) => {
  // get user infomation for the firsttime to ensure information
  state.user = await getUser(uid);

  Sentry.setUser({
    id: uid,
    username: `${state.user.displayName}`,
  });

  if (state.user.schoolID) {
    state.school = await getSchool(state.user.schoolID);
    Sentry.setContext("school", {
      ...state.school,
    });

    state.unsubscribeSchoolSettings?.();
    state.unsubscribeSchoolSettings = getSchoolSettingsByRealtimeUpdate(
      state.user.schoolID,
      (schoolSettings) => {
        Sentry.setContext("schoolSetting", {
          ...schoolSettings,
        });
        state.schoolSettings = schoolSettings;
        state.cardSelector.tagsTemplate = state.schoolSettings.tagsTemplate;
        state.cardSelector.schoolYear = state.user.schoolYear;
        state.schoolYears =
          state.school.schoolType == "elementary"
            ? [1, 2, 3, 4, 5, 6]
            : [1, 2, 3];
      }
    );

    if (state.user.role === "admin") {
      state.schoolAccount = await getSchoolAccount(state.user.schoolID);
    }
  }
  if (state.user.schoolYearClassHash) {
    state.schoolYearClass = await getSchoolYearClass(
      state.user.schoolYearClassHash
    );
    Sentry.setContext("schoolYearClassHash", {
      ...state.schoolYearClass,
    });
  }

  // set realtime update listeners
  state.unsubscribeUser?.();
  state.unsubscribeUser = getUserByRealtimeUpdate(uid, (user) => {
    state.user = user;
  });

  state.unsubscribeSchoolYearClass?.();
  state.unsubscribeSchoolYearClass = getSchoolYearClassByRealtimeUpdate(
    uid,
    (schoolYearClass) => {
      state.schoolYearClass = schoolYearClass;
    }
  );

  state.unsubscribeSchool?.();
  state.unsubscribeSchool = getSchoolByRealtimeUpdate(uid, (school) => {
    state.school = school;
    state.cardSelector.tagsTemplate = state.schoolSettings.tagsTemplate;
    state.cardSelector.schoolYear = state.user.schoolYear;
  });

  state.unsubscribeMyLike = setListerForMyLikes(uid, (mylikes) => {
    state.myLikes = mylikes;
  });

  state.unsubscribeNumLiked = getNumLikedRealtimeUpdate(uid, (numLiked) => {
    state.numLiked = numLiked;
  });
};

export const mulationTypes = {
  signIn: "signIn",
  signOut: "signOut",
  setFilter: "setFilter",
  setSort: "setSort",
  setSchoolYear: "setSchoolYear",
  setOldestYear: "setOldestYear",
  setOldestMonth: "setOldestMonth",
  setReviewsSchoolYear: "setReviewsSchoolYear",
  setReviewsCreatedFiscalYear: "setReviewsCreatedFiscalYear",
  setUser: "setUser",
  setTags: "setTags",
  setSchoolYearClass: "setSchoolYearClass", // for card selector
  setDefaultCameraNumber: "setDefaultCameraNumber",
};

export default createStore<State>({
  state: {
    mode: "login",
    cardSelector: new CardSelector(),
    auth: new authService(),
    user: {} as User,
    schoolSettings: {} as SchoolSettings,
    schoolAccount: {} as SchoolAccount,
    school: {} as School,
    schoolYearClass: {} as SchoolYearClass,
    schoolYears: [1, 2, 3, 4, 5, 6],
    numLiked: {} as NumLiked,
    unsubscribeUser: undefined,
    unsubscribeSchool: undefined,
    unsubscribeSchoolYearClass: undefined,
    unsubscribeSchoolSettings: undefined,
    userPreference: loadPreference(),
    myLikes: new MyLikes(),
  },
  mutations: {
    // Screen state
    signIn(state) {
      if (state.user.role == "student") {
        state.userPreference.teacherMode = false;
        state.userPreference.showBookFace = true;
      }
      if (state.school.schoolType != "elementary") {
        state.userPreference.hiragana = false;
      }
      return;
    },
    signOut(state) {
      state.user = {} as User;
      state.school = {} as School;
      state.schoolSettings = new SchoolSettings();
      state.unsubscribeUser?.();
      state.unsubscribeSchool?.();
      state.unsubscribeSchoolSettings?.();
      state.unsubscribeSchoolYearClass?.();
      state.unsubscribeMyLike?.();
      state.unsubscribeNumLiked?.();
    },

    // CardSelector
    setFilter(state, filter: Filters) {
      state.cardSelector.filter = filter;
    },

    setSort(state, sort: Sorts) {
      state.cardSelector.sort = sort;
    },

    setSchoolYear(state, schoolYear: number) {
      state.cardSelector.schoolYear = schoolYear;
    },

    setOldestFiscalYear(state, oldestFiscalYear: number) {
      state.cardSelector.oldestFiscalYear = oldestFiscalYear;
    },

    setOldestMonth(state, oldestMonth: number) {
      state.cardSelector.oldestMonth = oldestMonth;
    },

    setReviewsSchoolYear(state, reviewsSchoolYear: number) {
      state.cardSelector.reviewsSchoolYear = reviewsSchoolYear;
    },

    setReviewsCreatedFiscalYear(state, reviewsCreatedFiscalYear: number) {
      state.cardSelector.reviewsCreatedFiscalYear = reviewsCreatedFiscalYear;
    },

    setLastLikedFrom(state, from: Date) {
      state.cardSelector.lastLikedFrom = from;
    },

    setIsbn(state, isbn: string) {
      state.cardSelector.isbn = isbn;
    },

    setUser(state, user: User) {
      state.cardSelector.user = user;
    },

    setTags(state, tags: string[]) {
      state.cardSelector.tags = tags;
    },

    setSchoolYearClass(state, schoolYearClass: SchoolYearClass) {
      state.cardSelector.schoolYearClass = schoolYearClass;
    },

    // Teacher mode
    setTeacherMode(state) {
      state.userPreference.teacherMode = true;
      savePreference(state.userPreference);
    },
    unsetTeacherMode(state) {
      state.userPreference.teacherMode = false;
      savePreference(state.userPreference);
    },

    showBookFace(state) {
      state.userPreference.showBookFace = true;
      savePreference(state.userPreference);
    },
    hideBookFace(state) {
      state.userPreference.showBookFace = false;
      savePreference(state.userPreference);
    },

    setHiragana(state) {
      state.userPreference.hiragana = true;
      savePreference(state.userPreference);
    },
    unsetHiragana(state) {
      state.userPreference.hiragana = false;
      savePreference(state.userPreference);
    },
    setDefaultCameraNumber(state, cameraNumber: number) {
      state.userPreference.defaultCameraNumber = cameraNumber;
      savePreference(state.userPreference);
    },
  },
  actions: {
    async signIn(
      { commit, state },
      params: { accountName: string; password: string }
    ) {
      await state.auth.signIn(params.accountName, params.password);
    },

    async signOut({ commit, state }) {
      await state.auth.signOut();
    },

    // this is called when app starts to set callback for authService
    // in orther for transition to home screen.
    async startAuth({ commit, state }) {
      state.auth.startAuth(
        async (uid: string) => {
          await setUserInfo(state, uid);
          commit("signIn");
        },
        async () => {
          commit("signOut");
        }
      );
    },
  },
  modules: {},
});

export function useStore() {
  return baseUseStore(key);
}
