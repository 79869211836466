export interface UserPreference {
  teacherMode: boolean;
  showBookFace: boolean;
  hiragana: boolean;
  defaultCameraNumber: number;
}

const defaultUserPrefernce: UserPreference = {
  teacherMode: false,
  showBookFace: true,
  hiragana: false,
  defaultCameraNumber: 0,
};

const getBooleanItem = (key: keyof UserPreference): boolean => {
  const value = localStorage.getItem(key);
  if (value === "true") return true;
  if (value === "false") return false;
  return defaultUserPrefernce[key] as boolean;
};

const setBooleanItem = (key: keyof UserPreference, value: boolean) => {
  localStorage.setItem(key, value == true ? "true" : "false");
};

const getNumberItem = (key: keyof UserPreference): number => {
  const value = localStorage.getItem(key);
  if (value === null) return defaultUserPrefernce[key] as number;
  return Number(value);
};

const setNumberItem = (key: keyof UserPreference, value: number) => {
  localStorage.setItem(key, String(value));
};

export const loadPreference = (): UserPreference => {
  return {
    teacherMode: getBooleanItem("teacherMode"),
    showBookFace: getBooleanItem("showBookFace"),
    hiragana: getBooleanItem("hiragana"),
    defaultCameraNumber: getNumberItem("defaultCameraNumber"),
  };
};

export const savePreference = (userPreference: UserPreference): void => {
  setBooleanItem("teacherMode", userPreference.teacherMode);
  setBooleanItem("showBookFace", userPreference.showBookFace);
  setBooleanItem("hiragana", userPreference.hiragana);
  setNumberItem("defaultCameraNumber", userPreference.defaultCameraNumber);
};
