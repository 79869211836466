<template>
  <span v-if="reported" id="onunreport" @click="onUnReport">
    <i class="bi bi-exclamation-triangle reported"></i>
  </span>
  <span v-else id="onreport" @click="onReport">
    <i class="bi bi-exclamation-triangle unreported"></i>
  </span>
</template>

<script setup lang="ts">
import { ref, defineProps, watch, onMounted } from "vue";
import { useStore } from "@/store/store";
import { Card } from "@/domain/Card";
import { reportCard, unreportCard } from "@/infrastructure/CardRepository";

const store = useStore();
const props = defineProps<{ card: Card }>();

const reported = ref(false);

const refresh = () => {
  // student cannot see the status
  if (
    ["admin", "teacher", "root"].includes(store.state.user.role) &&
    props.card.numReported
  )
    reported.value = true;
  else reported.value = false;
};

onMounted(refresh);
watch(props, refresh);

const onReport = async () => {
  reported.value = true;
  await reportCard(props.card, store.state.school.schoolType);
};

const onUnReport = async () => {
  // student cannot cancel report
  if (
    store.state.user.role === "teacher" ||
    store.state.user.role === "admin" ||
    store.state.user.role === "root"
  ) {
    reported.value = false;
    await unreportCard(props.card, store.state.school.schoolType);
  }
};
</script>
<style>
.unreported {
  font-size: 1.2em;
  color: #999999;
}
.reported {
  font-size: 1.2em;
  color: #d8d676;
}
</style>
