import { getUserByAccountNameAndSchoolId } from "@/infrastructure/UserRepository";
import {
  Role,
  User,
  UserDocument,
  UserCreateRequest,
  UserUpdateRequest,
  isSSOUser,
  isValidAccountName,
  isValidEmail,
} from "@/domain/User";
import { InitialPassword } from "@/domain/password";

export type CSVUser =
  | {
      mode: "create";
      createdUser: UserCreateRequest;
      password: string;
      user: User;
    }
  | {
      mode: "update";
      id: string;
      updatedUser: UserUpdateRequest;
      user: User;
    }
  | {
      mode: "delete";
      id: string;
      user: User;
    };

export const perseAndChackCSV = async (
  csvString: string,
  schoolID: string,
  emailRegex: string
): Promise<CSVUser[]> => {
  const lines = csvString.split("\n");

  const pms = lines.map(async (line) => {
    const field = line.replace(/[\"\']/g, "").split(/[,\t]/);

    if (field.length <= 1) return { mode: "skip" };

    if (field.length < 6)
      throw new Error(`CSVファイルの形式が正しくありません:\n${line}`);

    if (!(Number(field[5]) >= 0) || !(Number(field[5]) <= 6))
      throw new Error(`学年を正しく指定してください:\n${line}`);

    field.forEach((f) => {
      if (f.length > 50)
        throw new Error(`各入力は50文字以内で入力してください:\n${line}`);
    });

    const role = String(field[1]) as Role | "delete" | "deleted";
    const accountName = String(field[0]).replace(/[\x00-\x1F\x7F-\x9F]/g, "");
    const displayName = String(field[2]);
    const admissionYear = Number(field[3]);
    const className = String(field[4]);
    const schoolYear = Number(field[5]);

    if (
      !["student", "teacher", "deleted", "delete"].includes(String(field[1]))
    ) {
      throw new Error(
        `ユーザー種別はstudent/teacher/deleteから選択してください:\n${line}`
      );
    }
    const isDelete = role === "delete" || role === "deleted";

    if (!isSSOUser(accountName) && !isValidAccountName(accountName)) {
      throw new Error(`IDは半角英数字を指定してください: \n${line}`);
    }
    if (isSSOUser(accountName) && !isValidEmail(accountName, emailRegex))
      throw new Error(`正しいメールアドレスを入力してください: \n${line}`);

    if (
      className !== "" &&
      role == "student" &&
      !className.match(/^\d+年.+組$/)
    )
      throw new Error(
        `クラス名は○年○組という形式で入力してください: \n${line}`
      );

    const user = await getUserByAccountNameAndSchoolId(accountName, schoolID);

    if (typeof user === "undefined" && !isDelete) {
      // create
      const createdUser: UserCreateRequest = {
        displayName,
        admissionYear,
        className,
        schoolYear,
        accountName,
        role,
      };
      const csvUser: CSVUser = {
        mode: "create",
        createdUser,
        user: new User(undefined, createdUser as UserDocument),
        password: isSSOUser(accountName) ? "" : new InitialPassword().password,
      };
      return csvUser;
    } else if (typeof user !== "undefined" && !isDelete) {
      // update
      if (typeof user.id === "undefined")
        throw new Error("user.id is undefined");

      user.displayName = displayName;
      user.admissionYear = admissionYear;
      user.className = className;
      user.schoolYear = schoolYear;

      const csvUser: CSVUser = {
        mode: "update",
        id: user.id,
        updatedUser: {
          displayName,
          admissionYear,
          className,
          schoolYear,
        },
        user,
      };
      return csvUser;
    } else if (typeof user !== "undefined" && isDelete) {
      // delete
      if (typeof user.id === "undefined")
        throw new Error("user.id is undefined");
      const csvUser: CSVUser = {
        mode: "delete",
        id: user.id,
        user,
      };
      return csvUser;
    } else if (typeof user === "undefined" && isDelete) {
      throw new Error(`存在しないユーザーを削除しようとしています:\n${line}`);
    }
    throw new Error("unknown error");
  });

  const result = (await Promise.all(pms)).filter((v) => v.mode !== "skip");

  if (result.length > 100) {
    throw new Error("同時に100ユーザーを超える更新はできません。");
  }

  //@ts-expect-error: cannot process "skip"
  return result;
};
