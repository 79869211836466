<template>
  <template v-if="store.state.userPreference.hiragana">{{ props.f }}</template>
  <slot v-else></slot>
</template>

<script setup lang="ts">
import { useStore } from "@/store/store";

const store = useStore();

const props = defineProps<{
  f: string;
}>();
</script>
