// Book info
// Read: everyone
// Write: root
export class Book {
  [key: string]: any;

  constructor(
    readonly bookISBN: string = "",
    readonly num: number = 0,
    readonly lastUpdatedAtSecond: number = 0
  ) {}
  //`numsy${card.reviewerSchoolYear}`: number
  //numcy${card.createdYear}`: number
  //`numsy${card.reviewerSchoolYear}cy${card.createdYear}`: number
}
