// Card num info
// Read: Self, Teacher, Admin
// Write: Root

type CardNumStatistics = {
  [key: number]: {
    [key: number]: number;
    total: number;
  };
  total: number;
};
export class CardNum {
  [key: string]: any;

  constructor(
    readonly numAll: number = 0,
    readonly lastUpdatedAtSecond: number = 0
  ) {}
  //`num{$fiscalYear}`: number
  //`num{$fiscalYear}{$Month}`: number

  getStatistics(): CardNumStatistics {
    const today = new Date();
    const thisYear = today.getFullYear();

    const years = this.getYears();
    const result: {
      total: number;
      [key: number]: {
        total: number;
        [key: number]: number;
      };
    } = { total: this.numAll, [thisYear]: { total: 0 } };

    years.forEach((year) => {
      result[year] = { total: this.getYearNum(year) };

      const months = this.getMonths(year);
      months.forEach((month) => {
        result[year][month] = this.getMonthNum(year, month);
      });
    });

    const firstYear = Number(Object.keys(result).sort()[0]);

    for (let y = firstYear; y <= thisYear; y++) {
      for (let m = 1; m <= 12; m++) {
        if (typeof result[y] === "undefined") {
          result[y] = { total: 0 };
        }
        if (typeof result[y][m] === "undefined") {
          result[y][m] = 0;
        }
      }
    }
    return result;
  }

  getYears(): number[] {
    return Object.keys(this)
      .filter((key) => key.match(/^num\d{4}$/))
      .map((key) => Number(key.slice(3, 7)))
      .sort();
  }

  getMonths(year: number): number[] {
    const reg = new RegExp("^num" + String(year).padStart(4, "0") + "\\d{2}$");
    return Object.keys(this)
      .filter((key) => key.match(reg))
      .map((key) => Number(key.slice(7, 9)))
      .sort();
  }

  getYearNum(year: number): number {
    return this[`num${String(year).padStart(4, "0")}`];
  }

  getMonthNum(year: number, month: number): number {
    return this[
      `num${String(year).padStart(4, "0")}${String(month).padStart(2, "0")}`
    ];
  }

  getNumberOfPreviousMonth(monthBefore = 1, today: Date = new Date()): number {
    const date = new Date(today.getTime());
    date.setMonth(date.getMonth() - monthBefore);

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // JavaScriptの月は0から始まるため、1を加えます
    const fiscalYear = month < 4 ? year - 1 : year;
    const yyyymm = `num${fiscalYear}${month < 10 ? "0" + month : month}`; // 月が一桁の場合は先頭に0を追加します

    return this[yyyymm] ?? 0;
  }
}
