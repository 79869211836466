// Get: everyone in the school
// List: admin of the school
// Update: admin of the school

import { h32 } from "xxhashjs";
const HASH_SEED = 0xeeee;
import { SchoolType } from "./School";

function generateHashFromStr(str: string) {
  return h32(str, HASH_SEED).toString(16);
}

export type Role = "student" | "root" | "admin" | "teacher";

export interface UserUpdateRequest {
  displayName: string;
  admissionYear: number;
  className: string;
  schoolYear: number;
}

export interface UserCreateRequest extends UserUpdateRequest {
  // also used in firestore
  accountName: string; // student number in school
  role: Role; // used for front-end
}

export interface UserDocument extends UserCreateRequest {
  schoolName: string;
  schoolID: string;
  schoolType: SchoolType;
  initialPassword: boolean;
  schoolYearClassHash: string;
  numCards: number;
}

export function isSSOUser(accountName: string): boolean {
  const email_reg = /^.+@.+$/;
  return accountName.match(email_reg) == null ? false : true;
}

export function isValidEmail(accountName: string, emailRegex: string): boolean {
  // minimum rule for email
  if (
    !accountName.match(
      /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*|"([\w!#$%&'*+\-\/=?^`{|}~. ()<>\[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d\-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/
    )
  )
    return false;
  // check using original rule
  if (!accountName.match(new RegExp(emailRegex))) return false;

  return true;
}

export function isValidAccountName(accountName: string): boolean {
  if (!accountName.match(/^[0-9a-zA-Z]+$/)) return false;
  return true;
}

export class User implements UserDocument {
  readonly id: string | undefined = undefined;
  // also used in firestore
  readonly schoolID: string = "";
  public role: "student" | "root" | "admin" | "teacher" = "student"; // used for front-end
  readonly schoolType: SchoolType;
  public accountName = ""; // student number in school
  public displayName = "";
  public admissionYear = 0;
  public className = "";
  public schoolYear = 0;
  readonly schoolName: string = "";
  readonly initialPassword: boolean = true;
  readonly schoolYearClassHash: string = "";
  readonly numCards: number = 0;

  constructor(id: string | undefined, userDoc: UserDocument) {
    this.id = id;
    this.schoolID = userDoc.schoolID;
    this.role = userDoc.role;
    this.schoolType = userDoc.schoolType;
    this.accountName = userDoc.accountName;
    this.displayName = userDoc.displayName;
    this.admissionYear = userDoc.admissionYear;
    this.className = userDoc.className;
    this.schoolYear = userDoc.schoolYear;
    this.schoolName = userDoc.schoolName;
    this.initialPassword = userDoc.initialPassword;
    this.schoolYearClassHash = userDoc.schoolYearClassHash;
    this.numCards = userDoc.numCards;
  }

  isSSOUser(): boolean {
    return isSSOUser(this.accountName);
  }

  getTemporaryUID(): string {
    return generateHashFromStr(this.accountName);
  }
}

export class UserInOtherSchool {
  readonly id: "otherschool" = "otherschool";
}
