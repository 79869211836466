import {
  QueryConstraint,
  where,
  orderBy,
  startAt,
  endAt,
  Timestamp,
} from "firebase/firestore";
import { CardSelector } from "@/domain/cardSelector";

export const getCardQueryConstrains = (
  cardSelector: CardSelector,
  userId: string,
  schoolYearClassHash: string,
  schoolId: string,
  isbn?: string
): QueryConstraint[] => {
  const queryConstraints: QueryConstraint[] = [];
  if (cardSelector.sort != "reviews") {
    // Add filter
    switch (cardSelector.filter) {
      case "mine":
        if (cardSelector.sort == "trashed") {
          queryConstraints.push(
            where("userID", "==", userId),
            where("status", "==", "trashed")
          );
        } else {
          if (cardSelector.sort == "tags") {
            queryConstraints.push(
              where("userID", "==", userId),
              where("status", "==", "public")
            );
          } else {
            // 'array-contains-any' filters with 'in' filters cannot be used
            queryConstraints.push(
              where("userID", "==", userId),
              where("status", "in", ["public", "draft", "banned"])
            );
          }
        }
        break;
      case "user":
        queryConstraints.push(
          where("userID", "==", cardSelector.user?.id),
          where("status", "==", "public")
        );
        break;
      case "schoolyear":
        queryConstraints.push(
          where("schoolID", "==", schoolId),
          where("reviewerSchoolYear", "==", cardSelector.schoolYear),
          where("status", "==", "public")
        );
        break;
      case "myclass":
        queryConstraints.push(
          // if schoolYearClallHash of cardselector is defined, use it. otherwise use that of user.
          where("reviewerSchoolYearClassHash", "==", schoolYearClassHash),
          where("status", "==", "public")
        );
        break;
      case "otherclass":
        queryConstraints.push(
          // if schoolYearClallHash of cardselector is defined, use it. otherwise use that of user.
          where(
            "reviewerSchoolYearClassHash",
            "==",
            cardSelector.schoolYearClass?.id ?? schoolYearClassHash
          ),
          where("status", "==", "public")
        );
        break;
      case "school":
        queryConstraints.push(
          where("schoolID", "==", schoolId),
          where("status", "==", "public")
        );
        break;
      case "otherschool":
        // Firestore cannot combine "!=" and "orderBy" for diffent field.
        // Filter in CardList
        queryConstraints.push(where("status", "==", "public"));
        break;
      case "all":
        queryConstraints.push(where("status", "==", "public"));
        break;
    }
    // Add sort

    switch (cardSelector.sort) {
      case "newest":
        queryConstraints.push(orderBy("createdAt", "desc"));
        break;
      case "oldest":
        queryConstraints.push(
          orderBy("createdAt"),
          startAt(Timestamp.fromDate(cardSelector.getStartDateForSort())),
          endAt(Timestamp.fromDate(cardSelector.getEndDateForSort()))
        );
        break;
      case "likes":
        queryConstraints.push(orderBy("numLiked", "desc"));
        break;
      case "recommended":
        queryConstraints.push(where("recommended", "==", true));
        queryConstraints.push(orderBy("createdAt", "desc"));
        break;
      case "lastliked":
        queryConstraints.push(
          where("lastLiked", ">=", cardSelector.lastLikedFrom),
          orderBy("lastLiked", "desc")
        );
        break;
      case "monthlyliked":
        const today = new Date();
        const from = new Date(today.getFullYear(), today.getMonth(), 1);
        queryConstraints.push(
          where("lastLiked", ">=", from),
          orderBy("lastLiked", "desc")
        );
        break;
      case "samebook":
        queryConstraints.push(where("bookISBN", "==", cardSelector.isbn));
        break;
      case "random":
        queryConstraints.push(
          orderBy(`random${Math.floor(Math.random() * 10)}`)
        );
        break;
      case "tags":
        if (cardSelector.tags.length > 0) {
          queryConstraints.push(
            where("tags", "array-contains-any", cardSelector.tags)
          );
        }
        break;
      case "reported":
        queryConstraints.push(
          where("numReported", ">", 0),
          orderBy("numReported", "desc")
        );
        break;
    }
  } else if (
    // for cards in books (sort mode is 'reviews')
    cardSelector.sort == "reviews" &&
    isbn
  ) {
    queryConstraints.push(
      where("bookISBN", "==", isbn),
      where("status", "==", "public")
    );

    if (cardSelector.reviewsSchoolYear > 0) {
      queryConstraints.push(
        where("reviewerSchoolYear", "==", cardSelector.reviewsSchoolYear)
      );
    } else if (cardSelector.reviewsCreatedFiscalYear > 0) {
      queryConstraints.push(
        orderBy("createdAt"),
        startAt(Timestamp.fromDate(cardSelector.getStartDateOfCreatedDate())),
        endAt(Timestamp.fromDate(cardSelector.getEndDateOfCreatedDate()))
      );
    }
  }
  return queryConstraints;
};
