<template>
  <div v-if="validRole" class="container" style="margin-top: 80px">
    <router-link :to="{ name: 'home' }" class="btn btn-primary my-3"
      >メイン画面へ戻る</router-link
    >
    <h1>カード枚数一覧</h1>

    <div class="container">
      <div class="row my-5">
        <div class="col-1">表示年度</div>
        <div class="col-2">
          <select class="form-select col-1" v-model="year">
            <option v-for="(y, idx) in years" :key="idx" :value="y" :selected="year == y">
              {{ y }}年度
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-9">
          <table class="table">
            <thead>
              <tr>
                <th class="col-3" scope="col">クラス名</th>
                <th class="col-3" scope="col">カード枚数</th>
                <th class="col-3" scope="col">アクション</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(s, idx) in schoolYearClasses" :key="idx">
                <tr v-if="s.numCards != 0">
                  <th class="col-3" scope="col">
                    {{ s.className }}
                  </th>
                  <th class="col-3" scope="col">
                    <a href="javascript:void(0);" @click="switchClassCard(s)">
                      {{ s.numCards }}
                    </a>
                  </th>
                  <th class="col-3" scope="col">-</th>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, watch } from "vue";
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";

import { jumpHomeIfNot } from "../router";

import { SchoolYearClass } from "../domain/SchoolYearClass";
import { getSchoolYearClassOfYear } from "../infrastructure/SchoolYearClassRepository";

export default defineComponent({
  name: "CardNumView",

  setup() {
    const store = useStore();
    const router = useRouter();

    const years = ref(
      Array.from(Array(new Date().getFullYear() - 2016), (v, k) => k).map(
        (num) => new Date().getFullYear() - num
      )
    );

    const year = ref(
      new Date().getMonth() + 1 <= 3
        ? new Date().getFullYear() - 1
        : new Date().getFullYear()
    );

    const schoolYearClasses = reactive<SchoolYearClass[]>([]);

    watch(
      year,
      async () => {
        const schoolID = store.state.user.schoolID;
        const newSchoolYearClasses = await getSchoolYearClassOfYear(
          schoolID,
          year.value,
          store.state.schoolYears
        );

        schoolYearClasses.splice(0, schoolYearClasses.length, ...newSchoolYearClasses);
      },
      { immediate: true }
    );

    const switchClassCard = async (schoolYearClass: SchoolYearClass) => {
      store.commit("setSchoolYearClass", schoolYearClass);
      store.commit("setFilter", "otherclass");
      router.push({ name: "home" });
    };

    return {
      store,
      years,
      year,
      schoolYearClasses,
      switchClassCard,
      validRole: jumpHomeIfNot(["root", "admin", "teacher"]),
    };
  },
});
</script>
