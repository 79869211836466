<template>
  <span
    v-for="(tag, index) in store.state.cardSelector.tagsTemplate"
    :key="index"
    class="d-inline-block"
  >
    <input
      type="checkbox"
      class="form-check-input"
      :name="`tag${index}`"
      :id="`tag${index}`"
      autocomplete="off"
      :value="tag"
      v-model="selectedTags"
      @change="changeTag"
    />
    <label class="form-check-label ms-1 me-3" :for="`tag${index}`">{{ tag }}</label>
  </span>
  <span
    v-for="(tag, index) in store.state.schoolYearClass.tags"
    :key="index"
    class="d-inline-block"
  >
    <input
      type="checkbox"
      class="form-check-input"
      :name="`tag${index}`"
      :id="`tag${index}`"
      autocomplete="off"
      :value="tag"
      v-model="selectedTags"
      @change="changeTag"
    />
    <label class="form-check-label ms-1 me-3" :for="`tag${index}`">{{ tag }}</label>
  </span>
  <div v-if="store.state.cardSelector.tags.length == 0" class="me-3 text-danger">
    <Hiragana f="タグをえらんでください">タグを選んでください</Hiragana>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import { useStore } from "@/store/store";
import Hiragana from "@/components/Hiragana.vue";

const store = useStore();

const selectedTags = ref<string[]>([]);

const changeTag = () => {
  store.commit("setTags", selectedTags.value);
};
</script>
