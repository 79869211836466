<template>
  <table class="table table-sm text-center" style="table-layout: fixed">
    <thead>
      <tr>
        <th></th>
        <th v-for="month in [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]" :key="month">
          {{ month }}月
        </th>
        <th>合計</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="year in years" :key="year">
        <th>{{ year }}年度</th>
        <td v-for="month in [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]" :key="month">
          <Medal :level="medalLevels[year][month]" />
          {{ stat[year][month] }}
        </td>
        <th>{{ stat[year].total }}</th>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, reactive, PropType, watch } from "vue";
import { useStore } from "@/store/store";
import { getCardNum } from "@/infrastructure/CardNumRepository";
import { User } from "@/domain/User";
import { medalRequirement } from "@/domain/medalRequirement";
import Medal from "./Medal.vue";

export default defineComponent({
  name: "UserCardNum",

  components: { Medal },

  emits: [],

  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();

    const stat = ref<{
      total: number;
      [key: number]: {
        total: number;
        [key: number]: number;
      };
    }>({ total: 0 });

    const years = ref<number[]>();

    const medalLevels = reactive<number[][]>([]);

    onMounted(async () => {
      if (typeof props.user?.id === "undefined") return;
      const cardNum = await getCardNum(props.user.id);
      stat.value = cardNum.getStatistics();
      years.value = cardNum.getYears();
      years.value.forEach((year) => {
        medalLevels[year] = [];
        [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3].forEach((month) => {
          const level = medalRequirement(stat.value[year][month]);
          medalLevels[year][month] = level;
        });
      });
    });

    return { props, store, stat, years, medalLevels };
  },
});
</script>
