<template>
  <div class="section">
    <Hiragana :f="props.ruby">{{ props.label }}</Hiragana>
  </div>
  <div class="d-flex" v-if="mode == 'fix'">
    <input
      type="text"
      class="w-100 form-control-plaintext pd-3"
      v-model="text"
      readonly
    />
    <!-- edit -->
    <button type="button" class="btn btn-success" @click="onEdit" v-if="!props.readonly">
      <i class="bi bi-pencil-square"></i>
    </button>
  </div>
  <div class="d-flex" v-if="mode == 'input'">
    <input
      type="text"
      class="w-100 form-control pd-3"
      v-model="text"
      onfocus="this.select();"
      @change="onInput"
      @keypress.enter="onOK"
    />
    <!-- cancel -->
    <button type="button" class="btn btn-success" @click="onOK">
      <i class="bi bi-check-lg"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import Hiragana from "@/components/Hiragana.vue";

export default defineComponent({
  name: "TextInput",
  components: { Hiragana },

  props: {
    modelValue: {
      type: String,
      required: true,
    },
    label: String,
    ruby: { type: String, required: false, default: "" },
    readonly: Boolean,
  },

  setup(props, { emit }) {
    const mode = ref("fix");
    const text = ref("");

    watch(props, () => {
      init();
    });

    function init() {
      mode.value = "fix";
      text.value = props.modelValue;
    }

    const onEdit = () => {
      mode.value = "input";
    };

    const onInput = () => {
      emit("update:modelValue", text.value);
    };

    const onOK = () => {
      mode.value = "fix";
      emit("update:modelValue", text.value);
    };

    return { props, mode, text, onEdit, onInput, onOK };
  },
});
</script>
