<template>
  <span class="badge rounded-pill bg-danger" v-if="updateInfo.hasUpdate">{{
    updateInfo.data.length
  }}</span>
  <i class="bi bi-bell" style="font-size: 1.5em" @click="onOpenModal"></i>

  <UpdateInfoModal ref="updateInfoModal" :updateInfo="updateInfo" />
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

import { UpdateInfo } from "@/domain/UpdateInfo";
import { useStore } from "@/store/store";
import UpdateInfoModal from "@/modals/UpdateInfoModal.vue";

const updateInfo = ref(new UpdateInfo());
const updateInfoModal = ref<InstanceType<typeof UpdateInfoModal>>();
const store = useStore();

onMounted(async () => {
  updateInfo.value = await UpdateInfo.init(store.state.user);
});

const onOpenModal = () => {
  updateInfoModal.value?.openModal();
};
</script>
