import { SchoolAccount } from "@/domain/SchoolAccount";
import { getFirestore } from "@firebase/firestore";
import { FirestoreDataConverter, getDoc, doc } from "firebase/firestore";

type docDataType = Partial<SchoolAccount>;

const converter: FirestoreDataConverter<SchoolAccount> = {
  toFirestore(data: SchoolAccount) {
    const doc: docDataType = {
      schoolId: data.schoolId,
      authType: data.authType,
      maxStudentNum: data.maxStudentNum,
      prefix: data.prefix,
      emailRegex: data.emailRegex,
    };
    return doc;
  },
  fromFirestore(snapshot, options) {
    const doc = snapshot.data(options) as docDataType;

    const schoolSettings = new SchoolAccount(
      doc.schoolId,
      doc.authType,
      doc.maxStudentNum,
      doc.prefix,
      doc.emailRegex
    );

    return schoolSettings;
  },
};

export const getSchoolAccount = async (
  schoolId: string
): Promise<SchoolAccount> => {
  const ref = doc(
    getFirestore(),
    "Schools",
    schoolId,
    "Private",
    "account"
  ).withConverter(converter);
  const snapshot = await getDoc(ref);
  if (!snapshot.exists())
    throw new Error(`acount for ${schoolId} does not exist`);

  return snapshot.data();
};
