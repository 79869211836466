import { changePassword } from "@/infrastructure/auth";
import cryptoRandomString from "crypto-random-string";

type SetNewPairResult = { isValid: boolean; errorMessages: string[] };

export class NewPasswordPair {
  private password: string | undefined = undefined;
  private errorMessages: string[] = [];
  private isValid = false;

  setNewPair = async (
    password1: string,
    password2: string
  ): Promise<SetNewPairResult> => {
    const regexAlpha = /[a-zA-Z]/g;
    const regexNum = /[0-9]/g;

    const msg: string[] = [];

    if (password1 !== password2) {
      msg.push("確認用パスワードが異なります。");
    } else {
      if (password1 == "") {
        msg.push("パスワードを入力してください。");
      } else {
        if (password1.length < 8) {
          msg.push("8文字以上で入力してください。");
        }
        if (
          regexAlpha.test(password1) == false ||
          regexNum.test(password1) == false
        ) {
          msg.push("アルファベットと数字の両方を含めてください。");
        }
        const result = (await import("zxcvbn")).default(password1);

        if (result.score < 1) {
          msg.push("複雑なパスワードにしてください。");
        }
      }
    }

    this.errorMessages = msg;

    if (this.errorMessages.length === 0) {
      this.isValid = true;
      this.password = password1;
    } else {
      this.isValid = false;
      this.password = undefined;
    }
    return { isValid: this.isValid, errorMessages: this.errorMessages };
  };

  toUser = async (uid: string): Promise<void> => {
    if (this.isValid && typeof this.password !== "undefined") {
      await changePassword(uid, this.password);
    } else {
      throw Error("Password is empty or not valid.");
    }
  };
}

export class InitialPassword {
  private _password: string;

  get password(): string {
    return this._password;
  }

  constructor() {
    for (let i = 0; i < 1000; i++) {
      const newPassword = cryptoRandomString({
        length: 6,
        type: "numeric",
      });
      if (newPassword.slice(0, 1) !== "0") {
        this._password = newPassword;
        return;
      }
    }
    throw new Error("Can not generate valid password.");
  }

  async toUser(uid: string): Promise<void> {
    await changePassword(uid, this._password);
  }
}
