import { User } from "@/domain/User";
import { SchoolType } from "./School";
import { SchoolYearClass } from "./SchoolYearClass";

export type Filters =
  | "mine"
  | "user"
  | "myclass"
  | "otherclass"
  | "schoolyear"
  | "school"
  | "otherschool"
  | "all";
export type Sorts =
  | "newest"
  | "oldest"
  | "likes"
  | "recommended"
  | "lastliked" // 最近いいねされたカード
  | "monthlyliked" // 今月のいいね
  | "samebook" // 同じ本の感想
  | "reviews"
  | "random"
  | "tags"
  | "trashed"
  | "reported"
  | "banned";

export class CardSelector {
  private _filter: Filters = "mine";
  private _sort: Sorts = "newest";
  user: User | undefined = undefined;
  schoolYear = 1;
  oldestFiscalYear: number = new Date().getFullYear();
  oldestMonth: number = new Date().getMonth();
  reviewsCreatedFiscalYear = 0;
  reviewsSchoolYear = 0;
  tags: string[] = [];
  lastLikedFrom: Date = new Date();
  isbn = "";
  schoolYearClass: SchoolYearClass | undefined = undefined;

  tagsTemplate: string[] = [];

  get years(): number[] {
    return Array.from(Array(new Date().getFullYear() - 2020), (v, k) => k).map(
      (num) => new Date().getFullYear() - num
    );
  }

  get months(): number[] {
    return [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
  }

  get fiscalYear(): number {
    return this.oldestMonth >= 4
      ? this.oldestFiscalYear
      : this.oldestFiscalYear + 1;
  }

  // Use in oldest filter
  getStartDateForSort(): Date {
    return new Date(this.fiscalYear, this.oldestMonth - 1, 1, 0, 0, 0, 0);
  }

  getEndDateForSort(): Date {
    const nextMonth = this.oldestMonth == 12 ? 1 : this.oldestMonth + 1;
    const yearOfNextMonth =
      this.oldestMonth == 12 ? this.fiscalYear + 1 : this.fiscalYear;
    return new Date(yearOfNextMonth, nextMonth - 1, 1, 0, 0, 0, 0);
  }

  // TODO: rename!
  getStartDateOfCreatedDate(): Date {
    return new Date(this.reviewsCreatedFiscalYear, 4 - 1, 1, 0, 0, 0, 0);
  }

  getEndDateOfCreatedDate(): Date {
    return new Date(this.reviewsCreatedFiscalYear + 1, 4 - 1, 1, 0, 0, 0, 0);
  }

  get filter(): Filters {
    return this._filter;
  }

  set filter(filter: Filters) {
    this._filter = filter;

    // side effects
    if (
      ["class", "school", "otherschool", "all"].includes(filter) &&
      this._sort == "trashed"
    ) {
      this._sort = "newest";
    } else if (
      ["mine", "class", "school", "otherschool"].includes(filter) &&
      ["reviews", "random", "banned"].includes(this._sort)
    ) {
      this._sort = "newest";
    } else if (filter == "mine" && this._sort == "trashed") {
      this._sort = "newest";
    } else if (this._sort == "lastliked" || this._sort == "monthlyliked") {
      this._sort = "newest";
    }
    if (filter !== "otherclass") {
      this.schoolYearClass = undefined;
    }
  }

  get sort(): Sorts {
    return this._sort;
  }

  set sort(sort: Sorts) {
    this._sort = sort;

    // side effects
    if (sort == "reviews" || sort == "random") {
      this._filter = "all";
    }
    if (sort == "trashed" || sort == "lastliked" || sort == "monthlyliked") {
      this._filter = "mine";
    }

    if (sort == "banned") {
      this._filter = "all";
    }
    if (sort == "samebook") {
      this._filter = "all";
    }
  }
}
