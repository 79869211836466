import {
  Unsubscribe,
  CollectionReference,
  QueryConstraint,
  QueryDocumentSnapshot,
  getDocs,
  query,
  startAfter,
  limit,
  onSnapshot,
} from "firebase/firestore";

export class Pagenation<T> {
  private unsubscribes: Unsubscribe[] = [];
  private lastDoc: QueryDocumentSnapshot | null = null;

  constructor(
    private collectionRef: CollectionReference,
    private queryConstraints: QueryConstraint[],
    private limit = 24
  ) {}

  loadNextItem = async (
    data: T[],
    useRealtimeUpdate = true
  ): Promise<{ noMoreData: boolean }> => {
    const q =
      this.lastDoc === null
        ? [...this.queryConstraints, limit(this.limit)]
        : [
            ...this.queryConstraints,
            startAfter(this.lastDoc),
            limit(this.limit),
          ];

    // get last data in chunk
    const preSnapshot = await getDocs(
      query(this.collectionRef, ...q, limit(this.limit))
    );
    if (preSnapshot.empty) return { noMoreData: true };
    this.lastDoc = preSnapshot.docs[preSnapshot.size - 1];

    if (useRealtimeUpdate) {
      const unsubscribe = onSnapshot(
        query(this.collectionRef, ...q),
        (snapshot) => {
          const docsData = snapshot.docs.map((doc) => doc.data() as T);
          data.splice(0, data.length, ...docsData);
        }
      );

      this.unsubscribes.push(unsubscribe);
    } else {
      const docsData = preSnapshot.docs.map((doc) => doc.data() as T);

      data.splice(0, data.length, ...docsData);
    }

    return { noMoreData: false };
  };

  clear = (): void => {
    // reset cursor
    this.lastDoc = null;

    // unsubscribe all snapshot listener
    this.unsubscribes.forEach((unsubscribe) => {
      unsubscribe();
    });
    this.unsubscribes.splice(0);
  };
}
