<template>
  <div
    class="border rounded"
    style="width:712px; height 407px;"
    @mousedown="calcsize"
    @touchstart="calcsize"
  >
    <canvas id="handwritecanvas" width="710" height="405" />
  </div>
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from "vue";
import { fabric } from "fabric";
import { deflateSync } from "browserify-zlib";

export default defineComponent({
  name: "HandWriting",
  props: {
    json: Object,
    size: Number,
    maxlength: Number,
  },

  emits: ["update:size"],

  setup(props, { emit }) {
    let canvas: any = null;

    onMounted(() => {
      try {
        canvas = new fabric.Canvas("handwritecanvas", {
          isDrawingMode: true,
          width: 710,
          height: 405,
          backgroundColor: "#ffffff",
        });
      } catch (err) {
        console.warn(err, { fabric });
      }
    });

    const clearCanvas = () => {
      canvas.clear();
    };

    const loadJson = (json: Object | null) => {
      try {
        clearCanvas();
        if (json != null) {
          canvas.loadFromJSON(json, () => {});
        }
      } catch (err) {
        console.warn(err, { canvas, json });
      }
    };

    const tool = ref<"pen" | "eraser">("pen");

    const setTool = (tool_: "pen" | "eraser") => {
      if (tool_ == "pen") {
        canvas.freeDrawingBrush.globalCompositeOperation = "source-over";
        canvas.freeDrawingBrush.width = 2;
        canvas.freeDrawingBrush.color = "#4c4a45";
      } else if (tool_ == "eraser") {
        canvas.freeDrawingBrush.globalCompositeOperation = "destination-out";
        canvas.freeDrawingBrush.width = 40;
        canvas.freeDrawingBrush.color = "#ffffff";
      }
      tool.value = tool_;
    };

    let count = 0;

    const calcsize = () => {
      count = count + 1;
      if (count == 10) {
        count = 0;
        const imageJson = serialize();
        if (typeof imageJson === "undefined") return;
        const stringifiedJson = JSON.stringify(imageJson);
        const zippedStringfiedJson = deflateSync(stringifiedJson);

        emit("update:size", zippedStringfiedJson?.byteLength);
        if (
          props.maxlength &&
          (zippedStringfiedJson?.byteLength ?? 0) > props.maxlength
        ) {
          canvas.forEachObject(function (o: any) {
            o.selectable = false;
          });
          canvas.isDrawingMode = false;
        }
      }
    };

    const serialize = () => {
      const json = canvas.toJSON();
      if (json && json.objects && json.objects.length > 0) {
        json.objects.forEach((object: any) => {
          object.path.forEach((path: any) => {
            path.forEach((point: string | number, idx: number, points: any) => {
              if (typeof point == "number") {
                points[idx] = Math.round(point);
              }
            });
          });
        });
        return json;
      } else {
        return null;
      }
    };

    return { clearCanvas, loadJson, tool, setTool, calcsize, serialize };
  },
});
</script>

<style>
.aaa {
  color: #4c4a45;
}
</style>
