import { User } from "./User";
import {
  getLastGetUpdateInfo,
  storeLastGetUpdateInfo,
  getUpdateInfo,
} from "@/infrastructure/UpdateInfoRepository";

export interface UpdateInfoData {
  title: string;
  date: Date;
  contents: string;
}

export class UpdateInfo {
  lastGetUpdateInfo = new Date();
  hasUpdate = false;
  data: UpdateInfoData[] = [];

  constructor(readonly user: User | undefined = undefined) {}

  static async init(user: User): Promise<UpdateInfo> {
    const updateInfo = new UpdateInfo(user);
    updateInfo.lastGetUpdateInfo = await getLastGetUpdateInfo(user);
    updateInfo.data = await getUpdateInfo(
      user,
      updateInfo.lastGetUpdateInfo,
      user.role
    );
    updateInfo.hasUpdate = updateInfo.data.length > 0 ? true : false;

    return updateInfo;
  }

  hasBeenSeen(): void {
    if (typeof this.user === "undefined") return;
    storeLastGetUpdateInfo(this.user, new Date());
    this.hasUpdate = false;
  }
}
