<template>
  <div v-if="validRole" class="container" style="margin-top: 80px">
    <router-link :to="{ name: 'home' }" class="btn btn-primary my-3"
      >メイン画面へ戻る</router-link
    >
    <h1>タグ管理</h1>
    クラスごとのタグを設定します。<br />{{
      store.state.school.schoolName
    }}全体で使用するタグは管理者アカウントの設定画面から設定できます。

    <div class="row my-5">
      <div class="col-1">表示年度</div>
      <div class="col-2">
        <select class="form-select col-1" v-model="year">
          <option
            v-for="(y, idx) in years"
            :key="'year' + idx"
            :value="y"
            :selected="year == y"
          >
            {{ y }}年度
          </option>
        </select>
      </div>
      <div class="col-1">クラス</div>
      <div class="col-2">
        <select class="form-select col-1" v-model="selectedClass">
          <option v-for="(s, idx) in schoolYearClasses" :key="'class' + idx" :value="idx">
            {{ s.className }}
          </option>
        </select>
      </div>
    </div>

    <div class="row" v-if="selectedClass">
      <div class="mb-3 col-12">
        <span v-for="(tag, idx) in tags" :key="'tag' + idx">
          {{ tag }}
          <button
            type="button"
            class="btn btn-danger btn-sm me-5"
            @click="removeTag(tag)"
          >
            ×
          </button>
        </span>
      </div>
      <div class="mb-3 col-2">
        <input
          type="text"
          class="form-control form-inline"
          id="newtag"
          v-model="newTag"
        />
      </div>
      <div class="mb-3 col-3">
        <button
          type="button"
          class="btn btn-primary form-inline btn-sm mb-3"
          @click="addTag"
        >
          追加
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, ref, reactive, watch } from "vue";
import { useStore } from "@/store/store";

import { SchoolYearClass } from "../domain/SchoolYearClass";
import {
  getSchoolYearClassOfYear,
  getSchoolYearClassByRealtimeUpdate,
  addTagToSchoolYearClass,
  removeTagFromSchoolYearClass,
} from "../infrastructure/SchoolYearClassRepository";
import { jumpHomeIfNot } from "../router";

const store = useStore();

const selectedClass = ref<number | undefined>(undefined);

const newTag = ref<string>("");

const addTag = () => {
  if (newTag.value == "" || tags.includes(newTag.value)) return;
  if (!selectedClass.value) return;
  addTagToSchoolYearClass(schoolYearClasses[selectedClass.value].id, newTag.value);
  newTag.value = "";
};

const removeTag = (tag: string) => {
  if (!selectedClass.value) return;
  removeTagFromSchoolYearClass(schoolYearClasses[selectedClass.value].id, tag);
};

const years = ref(
  Array.from(Array(new Date().getFullYear() - 2016), (v, k) => k).map(
    (num) => new Date().getFullYear() - num
  )
);

const year = ref(
  new Date().getMonth() + 1 <= 3 ? new Date().getFullYear() - 1 : new Date().getFullYear()
);

const schoolYearClasses = reactive<SchoolYearClass[]>([]);

watch(
  year,
  async () => {
    const schoolID = store.state.user.schoolID;
    const newSchoolYearClasses = await getSchoolYearClassOfYear(
      schoolID,
      year.value,
      store.state.schoolYears
    );

    schoolYearClasses.splice(0, schoolYearClasses.length, ...newSchoolYearClasses);
  },
  { immediate: true }
);

const validRole = jumpHomeIfNot(["admin", "teacher"]);

const tags = reactive<string[]>([]);

const unsubscribe = ref(() => {});

watch(selectedClass, () => {
  unsubscribe.value();
  unsubscribe.value = getSchoolYearClassByRealtimeUpdate(
    schoolYearClasses[selectedClass.value].id,
    (newSchoolYearClasses) => {
      tags.splice(0, tags.length, ...newSchoolYearClasses.tags);
    }
  );
});
</script>
