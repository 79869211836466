<template>
  <input
    type="radio"
    class="btn-check"
    :name="props.name"
    :id="`${props.name}-${props.value}`"
    v-model="store.state.cardSelector[props.name]"
    :value="props.value"
    @click="commit"
  />
  <label
    class="btn btn-primary"
    :class="{ left: props.left, right: props.right, icon: props.icon }"
    :for="`${props.name}-${props.value}`"
    ><slot></slot
  ></label>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "@/store/store";

export default defineComponent({
  name: "OptionButton",

  props: {
    name: { type: String, required: true },
    value: {
      type: [Object, String, Number],
      requred: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore();

    const commit = () => {
      if (typeof props.name === "undefined") return;
      const commitType = "set" + props.name.charAt(0).toUpperCase() + props.name.slice(1);
      store.commit(commitType, props.value);
    };

    return {
      props,
      store,
      commit,
    };
  },
});
</script>
