// Read: everyone
// Create: everyone
// Update: owner
// Delete: owner

import { User } from "./User";
import { School } from "./School";
import { SchoolSettings } from "./SchoolSettings";

export type Status = "draft" | "public" | "trashed" | "deleted" | "banned";

export class Card {
  hidden = false;
  reviewHandwrittenDataUrl = "";
  reviewer?: User;
  reviewerSchool?: School;

  constructor(
    // only for domain
    public id: string | undefined = undefined,
    // also used in firestore
    public userID: string = "",
    public schoolID: string = "",
    public status: Status = "public",
    public recommended: boolean = false,
    readonly createdAtSecond: number = 0, // to be converted for firestore
    readonly updatedAtSecond: number = 0, // to be converted for firestore
    public bookISBN: string = "",
    public bookTitle: string = "",
    public bookAuthors: string = "",
    public bookPublishYear: number = 0,
    public bookPublisher: string = "",
    public bookCCode: string = "",
    public bookThumbnailUrl: string = "",
    public reviewTyped: string = "",
    public reviewHandwrittenJson: any = null, // to be converted for firestore
    public tags: string[] = [],
    public reviewerAdmissionYear: number = 0,
    public reviewerSchoolYear: number = 0,
    public reviewerSchoolYearClassHash: string = "",
    readonly random0: string = "",
    readonly random1: string = "",
    readonly random2: string = "",
    readonly random3: string = "",
    readonly random4: string = "",
    readonly random5: string = "",
    readonly random6: string = "",
    readonly random7: string = "",
    readonly random8: string = "",
    readonly random9: string = "",
    readonly numLiked: number = 0,
    readonly numReported: number = 0,
    readonly lastLiked: Date = new Date() // to be converted for firestore
  ) {}

  clone(): Card {
    const newCard = new Card(
      this.id,
      this.userID,
      this.schoolID,
      this.status,
      this.recommended,
      this.createdAtSecond, // to be converted for firestore
      this.updatedAtSecond, // to be converted for firestore
      this.bookISBN,
      this.bookTitle,
      this.bookAuthors,
      this.bookPublishYear,
      this.bookPublisher,
      this.bookCCode,
      this.bookThumbnailUrl,
      this.reviewTyped,
      JSON.parse(JSON.stringify(this.reviewHandwrittenJson)), // to be converted for firestore
      JSON.parse(JSON.stringify(this.tags)),
      this.reviewerAdmissionYear,
      this.reviewerSchoolYear,
      this.reviewerSchoolYearClassHash,
      this.random0,
      this.random1,
      this.random2,
      this.random3,
      this.random4,
      this.random5,
      this.random6,
      this.random7,
      this.random8,
      this.random9,
      this.numLiked,
      this.numReported,
      this.lastLiked
    );

    return newCard;
  }

  isInappropreate = (settings: SchoolSettings): boolean => {
    const allowedCCodes = new RegExp(settings.allowedCCodes);
    const bannedCCodes = new RegExp(settings.bannedCCodes);

    // Banned card
    const bannedCardId = settings.bannedCardIds?.includes(this.id ?? "");

    // Inappropriate words/publisher is banned
    const bannedPublisher =
      settings.inappropreatePublisher?.some(
        (item) => this.bookPublisher?.includes(item) && item != ""
      ) ?? false;
    const bannedTitle =
      settings.inappropriateWords?.some(
        (item) => this.bookTitle?.includes(item) && item != ""
      ) ?? false;
    const bannedCCode =
      (((settings.bannedCCodes ?? "") != "" &&
        bannedCCodes.test(this.bookCCode ?? "")) ??
        0 > 0) ||
      (((settings.allowedCCodes ?? "") != "" &&
        !allowedCCodes.test(this.bookCCode ?? "")) ??
        0 > 0);

    return bannedPublisher || bannedTitle || bannedCCode || bannedCardId;
  };
}

// /////Repository

// // [JSON (domin)] >  Ziped > Bytes (repository)
// const handwritingSerialized = serialized
//   ? Bytes.fromUint8Array(serialized)
//   : Bytes.fromBase64String("");
// card.value.reviewHandwritten = handwritingSerialized;

// // Bytes (repository) > JSON (domain)
// export function getImageJsonFromBlob(blob: Bytes) {
//   if (blob.toUint8Array().length > 0) {
//     return JSON.parse(inflateSync(Buffer.from(blob.toUint8Array())).toString());
//   } else {
//     return null;
//   }
// }

// ///////UI+Repository

// // canvas (UI) > JSON (domain) > ZIPed
// const serialize = () => {
//   const json = canvas.toJSON();
//   if (json && json.objects && json.objects.length > 0) {
//     json.objects.forEach((object: any) => {
//       object.path.forEach((path: any) => {
//         path.forEach((point: string | number, idx: number, points: any) => {
//           if (typeof point == "number") {
//             points[idx] = Math.round(point);
//           }
//         });
//       });
//     });
//     const stringJson = JSON.stringify(json);

//     const zipped = deflateSync(stringJson);

//     return zipped;
//   } else {
//     return null;
//   }
// };

// ////////UI

// // JSON (domain) > canvas (UI)
// const loadJson = (json: Object | null) => {
//   canvas.clear();
//   if (json != null) {
//     canvas.loadFromJSON(json, () => {});
//   }
// };

// // JSON (domain) + (fabric canvas) > ImageDataURL (UI)  (READONLY)
// export function getImageDataUrlFromJson(json: any, canvasId: string) {
//   if (json == null) {
//     return "";
//   }
//   if (!json?.objects) {
//     return "";
//   }
//   if (json.objects.length == 0) {
//     return "";
//   }

//   const canvas = new fabric.Canvas(canvasId, {
//     width: 710,
//     height: 405,
//     allowTouchScrolling: false,
//   });

//   canvas.loadFromJSON(json, () => {});

//   return canvas.toDataURL();
// }
