<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "@/store/store";

const props = defineProps<{ level: number }>();
const store = useStore();

const image = computed(() => {
  return `/icon/medal_0${props.level}.png`;
});
</script>

<template>
  <img
    v-if="store.state.schoolSettings.enableMedal"
    :src="image"
    width="24"
    height="33"
  />
</template>
