<template>
  <template v-for="(numLikeBySchool, idx) in numLikesBySchool" :key="idx">
    <span class="like-button me-5" v-if="numLikeBySchool.numLikes > 0">
      {{ numLikeBySchool.city }}・{{ numLikeBySchool.schoolName }}
      <svg class="icon" fill="currentColor">
        <use xlink:href="/icon/icon-liked.svg#icon" />
      </svg>
      {{ numLikeBySchool.numLikes }}
    </span>
  </template>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { getNumLikesBySchool } from "@/infrastructure/CardRepository";
import { useStore } from "@/store/store";

export default defineComponent({
  name: "CardModalLikes",
  props: {
    cardId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const numLikesBySchool = ref<
      { city: string; schoolName: string; numLikes: number }[]
    >([]);
    const store = useStore();

    onMounted(async () => {
      numLikesBySchool.value = await getNumLikesBySchool(
        props.cardId,
        store.state.school.schoolType
      );
    });

    return { numLikesBySchool };
  },
});
</script>
