import { Modal } from "bootstrap";

export const useModal = (
  id: string,
  initModal: () => void,
  backdrop: "static" | boolean = "static"
): { openModal: () => void; closeModal: () => void } => {
  let modal: Modal;

  const openModal = () => {
    initModal();
    const element = document.getElementById(id);
    modal = new Modal(element as Element, {
      backdrop,
    });
    modal.show();
  };

  const closeModal = () => {
    modal.hide();
  };

  return { openModal, closeModal };
};
