<template>
  <div id="book-list" class="row g-4 p-4">
    <template v-for="(books, idx) in bookSet">
      <template v-for="book in books" :key="book.bookISBN">
        <BookItem :book="book" @open-modal="openModal" />
      </template>
    </template>
    <div v-if="noCard">カードがありません。</div>

    <div ref="scrollComponent"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "@/store/store";
import { mulationTypes } from "@/store/store";

import BookItem from "./BookItem.vue";

import { Book } from "@/domain/Book";
import { Card } from "@/domain/Card";
import { getBooksRef } from "@/infrastructure/BookRepository";
import { getBookQueryConstrains } from "@/infrastructure/BookQuery";
import { Pagenation } from "@/infrastructure/Pagenation";

const numRealtimeUpdateSet = 2;

export default defineComponent({
  name: "BookList",
  components: { BookItem },
  emits: ["openModal"],
  setup(props, { emit }) {
    const store = useStore();

    onMounted(() => {
      newQuery();

      window.addEventListener("scroll", handleScroll);
    });

    const unsubscribeStore = store.subscribe((mutation) => {
      if (
        [
          "setSort",
          "setFilter",
          "setReviewsSchoolYear",
          "setReviewsCreatedFiscalYear",
        ].includes(mutation.type)
      ) {
        clear();
        clearEventHandle();
        newQuery();
      }
    });

    const bookSet = reactive<Book[][]>([]);
    const noCard = ref(false);

    let pagenation: Pagenation<Book>;

    const loadNext = async () => {
      const nextBooks = reactive<Book[]>([]);
      if (typeof pagenation === "undefined") return;
      const useRealtimeUpdate = bookSet.length < numRealtimeUpdateSet;

      const { noMoreData } = await pagenation.loadNextItem(nextBooks, useRealtimeUpdate);
      if (noMoreData) clearEventHandle();

      bookSet.push(nextBooks);

      if (bookSet[0].length == 0 && noMoreData) {
        noCard.value = true;
      } else {
        noCard.value = false;
      }
    };

    async function newQuery() {
      pagenation = new Pagenation<Book>(
        getBooksRef(store.state.school.schoolType),
        getBookQueryConstrains(store.state.cardSelector)
      );
      loadNext();
    }

    const scrollComponent = ref<InstanceType<typeof HTMLDivElement>>();

    let inProcess = false;
    const handleScroll = () => {
      if (scrollComponent.value == undefined || inProcess) return;
      inProcess = true;

      if (scrollComponent.value.getBoundingClientRect().bottom < window.innerHeight * 2) {
        loadNext();
      }
      setTimeout(() => {
        inProcess = false;
      }, 100);
    };

    function clearEventHandle() {
      window.removeEventListener("scroll", handleScroll);
    }

    const openModal = (card: Card) => {
      emit("openModal", card);
    };

    function clear() {
      pagenation?.clear();
      bookSet.forEach((books) => {
        books.splice(0);
      });
      bookSet.splice(0);
    }

    onUnmounted(() => {
      clearEventHandle();
      unsubscribeStore();
      clear();
    });

    return {
      store,
      bookSet,
      noCard,
      scrollComponent,
      openModal,
      loadNext,
    };
  },
});
</script>
