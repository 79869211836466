// Read: root
// Write: root

export class SchoolAccount {
  constructor(
    public schoolId: string | undefined = undefined,
    public authType: "ID" | "google" | "" = "ID", // OAuth
    public maxStudentNum: number = 9999,
    public prefix: string = "",
    public emailRegex: string = ""
  ) {}
}
