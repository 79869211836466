import { UserDocument } from "./../domain/User";
import {
  getFirestore,
  FirestoreDataConverter,
  doc,
  getDoc,
} from "firebase/firestore";
import { getUser } from "@/infrastructure/UserRepository";
import { User, UserInOtherSchool } from "@/domain/User";
import { Reports } from "@/domain/Reports";
import { SchoolType } from "@/domain/School";
import { cardsColName } from "@/infrastructure/CardsColName";

const converter: FirestoreDataConverter<Reports> = {
  toFirestore(reports: Reports) {
    return {};
  },
  fromFirestore(snapshot, options) {
    const userIds = snapshot.data(options).userIds as string[];

    return new Reports(userIds);
  },
};

export const getReport = async (
  cardId: string,
  schoolType: SchoolType
): Promise<(User | UserInOtherSchool)[]> => {
  const snapshot = await getDoc(
    doc(
      getFirestore(),
      cardsColName[schoolType],
      cardId,
      "Reports",
      "users"
    ).withConverter(converter)
  );
  if (!snapshot.exists())
    throw new Error(`Document for ${snapshot.id} does not exist`);

  const reportUserIds = snapshot.data().userIds;

  return await Promise.all(
    reportUserIds.map(async (id) => {
      try {
        return await getUser(id);
      } catch (e) {
        return new UserInOtherSchool();
      }
    })
  );
};
