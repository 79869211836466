<template>
  <div v-if="validRole" class="container" style="margin-top: 80px">
    <router-link :to="{ name: 'home' }" class="btn btn-primary my-3"
      >メイン画面へ戻る</router-link
    >
    <h1>ユーザー管理</h1>

    <AdminUser />

    <template v-if="['root', 'admin'].includes(store.state.user.role)">
      <AdminUserCsv />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "@/store/store";
import { jumpHomeIfNot } from "../router";

import AdminUser from "../components/AdminUser.vue";
import AdminUserCsv from "../components/AdminUserCsv.vue";

export default defineComponent({
  name: "AdminUserView",

  components: {
    AdminUser,
    AdminUserCsv,
  },

  setup() {
    const store = useStore();

    return {
      store,
      validRole: jumpHomeIfNot(["root", "admin", "teacher"]),
    };
  },
});
</script>
