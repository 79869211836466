import { getFirestore } from "@firebase/firestore";
import {
  FirestoreDataConverter,
  doc,
  onSnapshot,
  Unsubscribe,
  setDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { MyLikes } from "@/domain/MyLikes";
import { User } from "@/domain/User";
import { SchoolType } from "./../domain/School";
import * as Sentry from "@sentry/vue";

type docDataType = MyLikes;

export const myLikeConverter: FirestoreDataConverter<MyLikes> = {
  toFirestore(mylikes: MyLikes): Record<string, never> {
    // Frontend cannot write mylikes
    return {};
  },
  fromFirestore(snapshot, options): MyLikes {
    const data = snapshot.data(options) as docDataType;

    return new MyLikes(data.cardIds);
  },
};

export const setListerForMyLikes = (
  userId: string,
  callback: (myLikes: MyLikes) => void
): Unsubscribe => {
  const ref = doc(
    getFirestore(),
    "Users",
    userId,
    "MyLikes",
    "myLikes"
  ).withConverter(myLikeConverter);
  const unsubscribe = onSnapshot(ref, (snapshot) => {
    if (snapshot.exists()) {
      callback(snapshot.data());
    }
  });
  return unsubscribe;
};

export const setMyLike = (
  like: boolean,
  user: User,
  cardID: string,
  schoolType: SchoolType
): void => {
  if (typeof user.id === "undefined") return;
  const ref = doc(getFirestore(), `Users/${user.id}/MyLikes/${cardID}`);

  Sentry.setContext("like", {
    like,
    likeAt: serverTimestamp(),
    userID: user.id,
    schoolID: user.schoolID,
    cardID,
    schoolType,
  });

  if (like == true) {
    setDoc(ref, {
      likeAt: serverTimestamp(),
      userID: user.id,
      schoolID: user.schoolID,
      cardID,
      schoolType,
    });
  } else {
    deleteDoc(ref);
  }
};
