<template>
  <span @click="onClick" v-if="selectedValue.id !== props.sortId"
    >{{ props.label }}<i class="bi bi-filter-left" id="sort-none"></i
  ></span>
  <span
    @click="onClick"
    v-if="selectedValue.id === props.sortId && selectedValue.type === 'asc'"
    >{{ props.label }}<i class="bi bi-sort-up" id="sort-up"></i
  ></span>
  <span
    @click="onClick"
    v-if="selectedValue.id === props.sortId && selectedValue.type === 'dec'"
    >{{ props.label }}<i class="bi bi-sort-down" id="sort-down"></i
  ></span>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, watch } from "vue";

export default defineComponent({
  name: "SortButton",
  props: {
    label: {
      type: String,
      required: false,
    },
    sortId: {
      type: String,
      required: true,
    },
    // value: {
    //   type: Object as PropType<{ id: String; type: "asc" | "dec" | "" }>,
    //   required: true,
    // },
    valueId: {
      type: String,
      required: true,
    },
    valueType: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedValue = reactive({
      id: props.valueId,
      type: props.valueType,
    });

    const update = () => {
      selectedValue.id = props.valueId;
      selectedValue.type = props.valueType;
    };

    onMounted(update);

    watch(props, update);

    const onClick = () => {
      if (selectedValue.id !== props.sortId) {
        selectedValue.id = props.sortId;
        selectedValue.type = "asc";
      } else if (selectedValue.type == "asc") {
        selectedValue.type = "dec";
      } else if (selectedValue.type == "dec") {
        selectedValue.type = "asc";
      }
      emit("update:value-id", selectedValue.id);
      emit("update:value-type", selectedValue.type);
      emit("change");
    };

    return { props, selectedValue, onClick };
  },
});
</script>

<style>
#sort-none {
  color: gray;
}
#sort-up #sort-down {
  color: blue;
}
</style>