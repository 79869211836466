<template>
  <NavBar />
  <RouterView v-if="store.state.user?.role" />
  <LoginView v-else />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "@/store/store";

import NavBar from "@/components/NavBar.vue";
import LoginView from "@/views/Login.vue";

export default defineComponent({
  name: "app",
  components: {
    NavBar,
    LoginView,
  },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
});
</script>

<style></style>
