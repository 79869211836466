import { getFirestore } from "@firebase/firestore";
import { getFunctions } from "./firebase";
import {
  collection,
  FirestoreDataConverter,
  getDoc,
  setDoc,
  doc,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { School, SchoolCreateRequest } from "../domain/School";

type docDataType = Partial<School>;

export const schoolConverter: FirestoreDataConverter<School> = {
  toFirestore(school: School) {
    const docData: docDataType = {
      schoolName: school.schoolName, // edit same review if user try to
      city: school.city,
      numCards: school.numCards,
      schoolType: school.schoolType,
    };
    return docData;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options) as docDataType;

    return new School(
      snapshot.id ?? "", // get schoolId
      data.schoolName ?? "",
      data.city ?? "",
      data.numCards ?? 0,
      data.schoolType ?? "elementary"
    );
  },
};

export const storeSchool = async (school: School): Promise<void> => {
  const schoolId = school.id;
  if (typeof schoolId === "undefined")
    throw new Error("SchoolId is not specified.");
  const ref = doc(getFirestore(), "Schools", schoolId).withConverter(
    schoolConverter
  );

  await setDoc(ref, school); // School document is cleated only by function, and setDoc is used
};

export const getSchool = async (schoolId: string): Promise<School> => {
  if (!schoolId) return new School();
  const ref = doc(getFirestore(), "Schools", schoolId).withConverter(
    schoolConverter
  );
  const snapshot = await getDoc(ref);
  if (!snapshot.exists())
    throw new Error(`Document for ${schoolId} does not exist`);

  const schoool = snapshot.data();

  return schoool;
};

export const getSchoolsByRealtimeUpdate = (
  callback: (schools: School[]) => void
): Unsubscribe => {
  return onSnapshot(
    collection(getFirestore(), "Schools").withConverter(schoolConverter),
    (snapshot) => {
      const schools: School[] = [];
      snapshot.forEach(async (doc) => {
        schools.push(doc.data());
      });
      callback(schools);
    }
  );
};

export const getSchoolByRealtimeUpdate = (
  id: string,
  callback: (school: School) => void
): Unsubscribe => {
  const ref = doc(getFirestore(), "Schools", id).withConverter(schoolConverter);
  const unsubscribed = onSnapshot(ref, (snapshot) => {
    const school = snapshot.data();

    if (typeof school !== "undefined") callback(school);
  });

  return unsubscribed;
};

export const createSchool = (request: SchoolCreateRequest): void => {
  httpsCallable(
    getFunctions(),
    "addSchool"
  )({
    schoolName: request.schoolName,
    city: request.city,
    prefix: request.prefix,
    emailRegex: request.emailRegex,
    maxStudentNum: request.maxStudentNum,
    schoolType: request.schoolType,
  });
};

export const deleteSchool = (schoolId: string): void => {
  httpsCallable(
    getFunctions(),
    "deleteSchool"
  )({
    id: schoolId,
  });
};
