<template>
  <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-if="!data.opened">
    <div
      :id="'book-' + props.book.bookISBN"
      class="card h-100 book-item"
      @click="data.opened = true"
    >
      <!-- This is 'card' of Bootstrap -->
      <img :src="data.cardExample.bookThumbnailUrl" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">
          {{ data.cardExample.bookTitle }}
        </h5>
        <p class="card-text">
          {{ data.cardExample.bookAuthors }}<br />{{ data.cardExample.bookPublisher
          }}{{
            data.cardExample.bookPublishYear
              ? "・" + data.cardExample.bookPublishYear
              : ""
          }}
        </p>
      </div>
      <div class="mb-3 mt-2 ms-3 col-3 book-icon">
        <svg class="icon" fill="currentColor">
          <use xlink:href="/icon/icon-books.svg#icon" />
        </svg>
        <span class="number">{{ data.num }}</span>
      </div>
    </div>
  </div>
  <!-- cards in books-->
  <div class="col-12" v-if="data.opened">
    <div @click="data.opened = false">
      <button type="button" class="btn-close" aria-label="Close"></button>
    </div>

    <div class="mt-1 py-5">
      <CardList :isbn="props.book.bookISBN" @open-modal="openModal" child-list />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, onMounted } from "vue";
import { useStore } from "@/store/store";
import CardList from "@/components/CardList.vue";
import { Card } from "@/domain/Card";
import { getCardExampleByIsbn } from "@/infrastructure/CardRepository";
import { Book } from "@/domain/Book";
import { convertBookThumbnailURL } from "../utilities/misc";

export default defineComponent({
  name: "BookItem",
  components: { CardList },
  emits: ["openModal"],
  props: {
    book: {
      type: Object as PropType<Book>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      cardExample: new Card(),
      opened: false,
      num: 0,
    });

    const setBookInfo = async () => {
      const keyForNum =
        store.state.cardSelector.reviewsCreatedFiscalYear > 0 &&
        store.state.cardSelector.reviewsSchoolYear > 0
          ? `numsy${store.state.cardSelector.reviewsSchoolYear}cy${store.state.cardSelector.reviewsCreatedFiscalYear}`
          : store.state.cardSelector.reviewsSchoolYear > 0
          ? `numsy${store.state.cardSelector.reviewsSchoolYear}`
          : store.state.cardSelector.reviewsCreatedFiscalYear > 0
          ? `numcy${store.state.cardSelector.reviewsCreatedFiscalYear}`
          : "num";
      data.cardExample = await getCardExampleByIsbn(
        props.book.bookISBN,
        store.state.user.schoolType
      );
      // 書影をアップデート
      data.cardExample.bookThumbnailUrl = convertBookThumbnailURL(
        data.cardExample.bookThumbnailUrl,
        data.cardExample.createdAtSecond
      );

      data.num = props.book[keyForNum];
      data.cardExample.bookThumbnailUrl;
    };

    onMounted(setBookInfo);

    const openModal = (card: Card) => {
      emit("openModal", card);
    };

    return {
      props,
      data,
      openModal,
    };
  },
});
</script>
