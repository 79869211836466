<template>
  <div
    v-if="processing"
    class="spinner-border spinner-border-sm text-light"
    role="status"
  ></div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export const ProcessingSpinner = defineComponent({
  name: "ProcessingSpinner",

  setup() {
    const processing = ref(false);

    const startProcess = () => {
      processing.value = true;
    };
    const finishProcess = (callback = () => {}) => {
      setTimeout(() => {
        processing.value = false;
        callback();
      }, 1000);
    };

    return {
      processing,
      startProcess,
      finishProcess,
    };
  },
});

export const useSpinner = () => {
  const spinner = ref<InstanceType<typeof ProcessingSpinner>>();
  const startProcess = () => {
    spinner.value?.startProcess();
  };
  const finishProcess = (callback: () => void) =>
    spinner.value?.finishProcess(callback);
  return { spinner, startProcess, finishProcess };
};

export default ProcessingSpinner;
</script>
