<template>
  <div
    v-if="
      (['root', 'admin', 'teacher'].includes(store.state.user.role) &&
        store.state.userPreference.teacherMode == true) ||
      store.state.schoolSettings.enableStudentSignin == true
    "
    class="container-lg"
    style="margin-top: 80px"
  >
    <CardViewOption />
    <div class="row mb-3">
      <div class="col mt-0 text-center">
        <button
          type="button"
          id="create-card"
          class="btn btn-lg btn-success rounded-pill px-3"
          @click="openCardModal()"
        >
          カードを<Hiragana f="つくる">作る</Hiragana>
        </button>
      </div>
    </div>
    <CardList
      v-if="store.state.cardSelector.sort != 'reviews'"
      @open-modal="openCardModal"
      ref="cardList"
    />
    <BookList
      v-if="store.state.cardSelector.sort == 'reviews'"
      @open-modal="openCardModal"
      ref="bookLiset"
    />
    <CardModal ref="cardModal" @create="update" />
  </div>
  <div v-else class="container-lg" style="margin-top: 100px">
    現在Yomumoは管理者によって停止されています。
    <span v-if="['root', 'admin', 'teacher'].includes(store.state.user.role)"
      >管理者および教員は教員モードにすることで利用可能です。</span
    >
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "@/store/store";
import Hiragana from "../components/Hiragana.vue";

import { Card } from "@/domain/Card";

import CardViewOption from "../components/CardViewOption.vue";
import CardList from "../components/CardList.vue";
import BookList from "../components/BookList.vue";
import CardModal from "../modals/CardModal.vue";

const store = useStore();

const cardModal = ref<InstanceType<typeof CardModal>>();
const cardList = ref<InstanceType<typeof CardList>>();
const bookList = ref<InstanceType<typeof BookList>>();

const openCardModal = (card: Card | undefined = undefined) => {
  cardModal.value?.openModal(card);
};

const update = () => {
  cardList.value?.loadNext();
  bookList.value?.loadNext();
};
</script>
