import { Timestamp } from "@google-cloud/firestore";
import { SchoolType } from "./School";
// Read: user
// Write: root
export class MyLikes {
  constructor(readonly cardIds: string[] = []) {}
}

// Read: root
// Write: user
// emit trigger
export interface MyLike {
  likeAt: Timestamp;
  userID: string; // user ID who likes, not card reviewer's
  schoolID: string; // school ID who likes
  cardID: string;
  schoolType: SchoolType;
}
