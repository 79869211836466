<template>
  <div class="row"></div>
  <button class="btn btn-success me-3" @click="onNewUser">新規ユーザー</button>
  <button class="btn btn-primary me-3" @click="exportUsers" :disabled="users.length == 0">
    ユーザーデータをCSVファイルへエクスポート
  </button>
  <PdfWriter :users="users" />

  <NewUserModal ref="newUserModal" />
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="col-2" scope="col">
          <SortButton
            label="ID"
            sort-id="accountName"
            v-model:value-id="sort.id"
            v-model:value-type="sort.type"
          />
        </th>
        <th class="col-2" scope="col">
          <SortButton
            label="ユーザー種別"
            sort-id="role"
            v-model:value-id="sort.id"
            v-model:value-type="sort.type"
          />
        </th>
        <th class="col-2" scope="col">
          <SortButton
            label="氏名"
            sort-id="displayName"
            v-model:value-id="sort.id"
            v-model:value-type="sort.type"
          />
        </th>
        <th class="col-1" scope="col">
          <SortButton
            label="入学年"
            sort-id="admissionYear"
            v-model:value-id="sort.id"
            v-model:value-type="sort.type"
          />
        </th>
        <th class="col-1" scope="col">
          <SortButton
            label="クラス"
            sort-id="className"
            v-model:value-id="sort.id"
            v-model:value-type="sort.type"
          />
        </th>
        <th class="col-1" scope="col">
          <SortButton
            label="学年"
            sort-id="schoolYear"
            v-model:value-id="sort.id"
            v-model:value-type="sort.type"
          />
        </th>
        <th class="col-1" scope="col">
          <SortButton
            label="カード数"
            sort-id="numCards"
            v-model:value-id="sort.id"
            v-model:value-type="sort.type"
          />
        </th>
        <th class="col-1" scope="col">アクション</th>
      </tr>
      <tr>
        <th>
          <input type="text" class="form-control" v-model="filter.accountName" />
        </th>
        <th>
          <select class="form-select" v-model="filter.role" @change="clearFilter()">
            <option value="">全員</option>
            <option value="student">児童・生徒</option>
            <option value="teacher">教員</option>
            <option value="admin">管理者</option>
          </select>
        </th>
        <th>
          <input type="text" class="form-control" v-model="filter.displayName" />
        </th>
        <th>
          <YearSelector v-model:year="filter.admissionYear" :yearNum="7" />
        </th>
        <th>
          <input type="text" class="form-control" v-model="filter.className" />
        </th>
        <th>
          <select class="form-select" v-model.number="filter.schoolYear">
            <option value="0"></option>
            <option
              v-for="(year, idx) in store.state.schoolYears"
              :key="idx"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
        </th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="user in users" v-bind:key="user.id">
        <tr>
          <td>
            {{ user.accountName }}
          </td>

          <td>
            {{
              {
                ["student"]: "生徒・児童",
                ["teacher"]: "教員",
                ["admin"]: "管理者",
                ["root"]: "管理者",
              }[user.role]
            }}
          </td>

          <td v-if="data.userIdEditing == user.id">
            <input class="form-control form-control-input" v-model="user.displayName" />
          </td>
          <td v-else>{{ user.displayName }}</td>

          <td v-if="data.userIdEditing == user.id">
            <YearSelector v-model:year="user.admissionYear" :yearNum="7" />
          </td>
          <td v-else>
            {{ user.admissionYear !== 0 ? user.admissionYear : "" }}
          </td>

          <td v-if="data.userIdEditing == user.id">
            <input class="form-control" v-model="user.className" />
          </td>
          <td v-else>{{ user.className }}</td>

          <td v-if="data.userIdEditing == user.id">
            <select
              class="form-select"
              name="schoolYear"
              v-model.number="user.schoolYear"
            >
              <option value="0"></option>
              <option
                v-for="(year, idx) in store.state.schoolYears"
                :key="idx"
                :value="year"
              >
                {{ year }}
              </option>
            </select>
          </td>
          <td v-else>{{ user.schoolYear !== 0 ? user.schoolYear : "" }}</td>

          <td>
            <a href="javascript:void(0);" @click="switchUserCard(user)">{{
              user.numCards
            }}</a>
          </td>

          <td>
            <div class="dropdown">
              <button
                class="btn btn-sm btn-primary"
                type="button"
                id="menubutton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-show="
                  (store.state.user.role === 'root' && data.userIdEditing === '') ||
                  (store.state.user.role === 'admin' &&
                    data.userIdEditing === '' &&
                    user.role !== 'admin') ||
                  (store.state.user.role === 'teacher' && user.role === 'student')
                "
              >
                <i class="bi bi-three-dots"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li v-show="['root', 'admin'].includes(store.state.user.role)">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    @click="data.userIdEditing = user.id ?? ''"
                    >編集</a
                  >
                </li>
                <li
                  v-show="
                    (store.state.user.role == 'root' ||
                      (store.state.user.role == 'admin' &&
                        ['teacher', 'student'].includes(user.role)) ||
                      (store.state.user.role == 'teacher' && user.role == 'student')) &&
                    user.isSSOUser() == false
                  "
                >
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    @click="resetPassword(user)"
                    >パスワードリセット</a
                  >
                </li>
                <li
                  v-show="
                    store.state.user.role === 'root' ||
                    (store.state.user.role === 'admin' &&
                      ['teacher', 'student'].includes(user.role))
                  "
                >
                  <hr class="dropdown-divider" />
                </li>

                <li
                  v-show="
                    store.state.user.role === 'root' ||
                    (store.state.user.role === 'admin' &&
                      ['teacher', 'student'].includes(user.role))
                  "
                >
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    @click="onDeleteUser(user)"
                    >削除</a
                  >
                </li>
              </ul>
            </div>

            <button
              type="button"
              class="btn btn-sm btn-success"
              v-if="data.userIdEditing === user.id"
              @click="onUpdateUser(user)"
            >
              決定
            </button>
          </td>
        </tr>
        <tr v-if="cardListUser && user.id === cardListUser.id">
          <td colspan="8">
            <div>
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                @click="cardListUser = undefined"
              ></button>
            </div>
            <UserCard @open-modal="openCardModal" :user="cardListUser" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
  <CardModal ref="cardModal" />

  <div style="display: none">
    <canvas id="imgcanvas" width="720" height="405"></canvas>
  </div>
</template>

<script lang="ts">
import { onMounted, onUnmounted, defineComponent, reactive, watch, ref } from "vue";
import { useStore } from "@/store/store";

import { User } from "@/domain/User";
import { Card } from "@/domain/Card";
import { queryUsersSnapshot } from "@/infrastructure/UserRepository";
import {
  updateUser,
  deleteUserCompletely,
  getUserCSV,
} from "@/infrastructure/UserRepository";

import PdfWriter from "@/components/PdfWriter.vue";
import YearSelector from "@/components/YearSelector.vue";
import { InitialPassword } from "@/domain/password";
import CardModal from "@/modals/CardModal.vue";
import UserCard from "@/components/UserCard.vue";
import SortButton from "./SortButton.vue";
import NewUserModal from "@/modals/NewUserModal.vue";

export default defineComponent({
  name: "AdminUser",
  props: {
    schoolID: {
      type: String,
      default: "",
    },
  },

  components: {
    PdfWriter,
    YearSelector,
    CardModal,
    UserCard,
    SortButton,
    NewUserModal,
  },
  setup(props) {
    const store = useStore();

    const users = reactive<User[]>([]);

    const data = reactive({
      newPassword: "",
      userIdEditing: "",
      creating: false,
    });

    // Filter
    const filter = reactive({
      accountName: "",
      role: "admin",
      displayName: "",
      admissionYear: 0,
      className: "",
      schoolYear: 0,
    });

    const clearFilter = () => {
      filter.accountName = "";
      filter.displayName = "";
      filter.admissionYear = 0;
      filter.className = "";
      filter.schoolYear = 0;
    };

    let unsubscribe = () => {};

    const setSubscribe = () => {
      unsubscribe();
      unsubscribe = queryUsersSnapshot(
        users,
        props.schoolID === "" ? store.state.user.schoolID : props.schoolID,
        filter
      );
    };

    onMounted(setSubscribe);

    watch(filter, setSubscribe);
    watch(props, setSubscribe);

    onUnmounted(unsubscribe);

    // Sort
    const sort = reactive({ id: "id", type: "asc" as "asc" | "dec" | "" });

    watch(sort, () => {
      const key = sort.id as keyof User;
      if (sort.type === "asc") {
        users.sort((a, b) => {
          if (typeof key === "undefined") return 0;
          return (a[key] ?? 0) > (b[key] ?? 0) ? 1 : -1;
        });
      } else if (sort.type === "dec") {
        users.sort((a, b) => {
          if (typeof key === "undefined") return 0;
          return (a[key] ?? 0) > (b[key] ?? 0) ? -1 : 1;
        });
      }
    });

    // Create/edit/delete user
    const newUserModal = ref<InstanceType<typeof NewUserModal>>();

    const onNewUser = () => {
      newUserModal.value?.openModal();
    };

    const onDeleteUser = (user: User) => {
      if (typeof user.id === "undefined") return;
      if (
        confirm(
          `ユーザー「${user.displayName}」を削除しますか？\nユーザーのカードも削除されます。この操作は元に戻せません。
          `
        ) == true
      ) {
        deleteUserCompletely(user.id);
      }
    };

    const onUpdateUser = (user: User) => {
      if (typeof user.id === "undefined") return;
      data.userIdEditing = "";
      updateUser(user.id, user);
    };

    const resetPassword = async (user: User) => {
      if (typeof user.id === "undefined") return;
      if (
        confirm(`ユーザー「${user.displayName}」のパスワードをリセットしますか？`) == true
      ) {
        const initialPassword = new InitialPassword();
        try {
          await initialPassword.toUser(user.id);
          alert(
            `ユーザー「${user.displayName}」の新しいパスワードは「${initialPassword.password}」です。\n 一度ユーザーのブラウザを終了してから再度ログインしてください。`
          );
        } catch (err) {
          alert(`ユーザー「${user.displayName}」のパスワード変更に失敗しました。${err}`);
        }
      }
    };

    const exportUsers = async () => {
      const element = document.createElement("a");
      element.download = "data.csv";
      element.href = window.URL.createObjectURL(getUserCSV(users));
      element.click();
    };

    // Card list/number of a user
    const cardListUser = ref<User>();

    const switchUserCard = (user: User) => {
      if (typeof user.id === "undefined") return;
      if (user.id === cardListUser.value?.id) {
        cardListUser.value = user;
      } else {
        cardListUser.value = user;
      }
    };

    const cardModal = ref<InstanceType<typeof CardModal>>();

    const openCardModal = (card: Card | undefined = undefined) => {
      cardModal.value?.openModal(card);
    };

    return {
      store,
      data,
      filter,
      sort,
      users,

      clearFilter,

      newUserModal,
      onNewUser,
      onDeleteUser,
      onUpdateUser,
      resetPassword,

      exportUsers,

      switchUserCard,
      cardListUser,
      cardModal,
      openCardModal,
    };
  },
});
</script>
