import { initializeApp, getApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore";
import {
  getFunctions as getFunctions_,
  connectFunctionsEmulator,
  Functions,
} from "firebase/functions";

// use after initializeApp
export const enableEmulators = (): void => {
  const auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:9099");
  const db = getFirestore();
  connectFirestoreEmulator(db, "localhost", 8000);
  const func = getFunctions();
  connectFunctionsEmulator(func, "localhost", 5001);
};

export const initializeFirebase = (): void => {
  const firebaseConfig = {
    apiKey: process.env.VITE_FIREBASE_APIKEY,
    authDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VITE_FIREBASE_APP_ID,
  };

  initializeApp(firebaseConfig);

  const runningOnJest = typeof window !== "object";
  if (runningOnJest) {
    // Jest
    enableEmulators();
  } else {
    // Emulator
    const runningOnLocalhost = window.location.hostname === "localhost";
    if (runningOnLocalhost) {
      enableEmulators();
    }
  }
};

export const getFunctions = (): Functions => {
  return getFunctions_(getApp(), "asia-northeast1");
};

// try {
//   if (!runningOnJest) enableIndexedDbPersistence(firestore);
// } catch (error) {
//   console.error(error);
// }
