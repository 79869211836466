<template>
  <select class="form-select" v-model.number="selectedYear" @change="selected">
    <option v-for="(year, idx) in years" :key="idx" :value="year">
      {{ year == 0 ? "" : year }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, PropType } from "vue";

export default defineComponent({
  name: "YearSelector",
  props: {
    year: {
      type: Number,
      required: false,
    },
    yearList: {
      type: Object as PropType<number>,
      required: false,
    },
    yearNum: {
      type: Number,
      required: false,
    },
  },
  setup(props, { emit }) {
    const years = ref<number[]>([]);
    const selectedYear = ref(0);

    onMounted(() => {
      updateYear();

      const date = new Date();
      if (typeof props.yearList !== "undefined") {
        years.value.splice(0, 0, props.yearList);
      } else {
        years.value = Array.from(Array(props?.yearNum ?? 100), (v, k) => k).map(
          (num) => date.getFullYear() - num
        );
        years.value.unshift(0);
      }
    });

    watch(props, () => {
      updateYear();
    });

    const updateYear = () => {
      if (props.year != null) {
        selectedYear.value = props.year;
      }
    };

    const selected = () => {
      emit("update:year", selectedYear.value);
      emit("change");
    };

    return { years, selectedYear, selected };
  },
});
</script>