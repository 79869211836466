import { getFirestore } from "@firebase/firestore";
import { getFunctions } from "./firebase";
import { httpsCallable } from "firebase/functions";
import {
  User,
  UserCreateRequest,
  UserDocument,
  UserUpdateRequest,
} from "../domain/User";
import { ChangeClassData } from "../../functions/src/user";

// import { Converter } from "../infrastructure/converter";

import {
  FirestoreDataConverter,
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  QueryConstraint,
  onSnapshot,
} from "firebase/firestore";

import {
  createUserData,
  updateUserData,
  deleteUserData,
} from "../../functions/src/user";

///////////

export const userReadConverter: FirestoreDataConverter<User> = {
  toFirestore(user: User) {
    throw new Error("'userConverter' can be used only for read.");
  },
  fromFirestore(snapshot, options) {
    const doc = snapshot.data(options) as UserDocument;

    // validation
    if (typeof doc.role == "undefined")
      throw new Error("User's role is not defined");
    doc.schoolType = doc.schoolType ?? "elementary";

    return new User(snapshot.id, doc);
  },
};

///////////

////////Update is done only by admin function

// export const storeUser = async (user: User): Promise<string> => {
//   const id = user.id;
//   if (typeof id === "undefined") {
//     const ref = collection(firestore, "Users").withConverter(userConverter);
//     const newRef = await addDoc(ref, user);
//     return newRef.id;
//   } else {
//     const ref = doc(firestore, "Users", id).withConverter(userConverter);
//     await updateDoc(ref, user);
//     return user.id as string;
//   }
// };

export const getUser = async (id: string): Promise<User> => {
  const ref = doc(getFirestore(), "Users", id).withConverter(userReadConverter);
  const snapshot = await getDoc(ref);
  if (!snapshot.exists()) throw new Error(`Document for ${id} does not exist`);

  const user = snapshot.data();

  return user;
};

export const getUserByAccountNameAndSchoolId = async (
  accountName: string,
  schoolId: string | undefined
) => {
  if (typeof schoolId === "undefined")
    throw new Error("schoolId is undefined.");
  const ref = collection(getFirestore(), "Users").withConverter(
    userReadConverter
  );
  const snapshot = await getDocs(
    query(
      ref,
      where("schoolID", "==", schoolId),
      where("accountName", "==", accountName)
    )
  );
  if (snapshot.size != 1) return undefined;

  const user = snapshot.docs[0].data();

  return user;
};

export const getUserByRealtimeUpdate = (
  id: string,
  callback: (user: User) => void
) => {
  const ref = doc(getFirestore(), "Users", id).withConverter(userReadConverter);
  const unsubscribed = onSnapshot(ref, (snapshot) => {
    const user = snapshot.data();

    if (typeof user !== "undefined") callback(user);
  });

  return unsubscribed;
};

export const queryUsersSnapshot = (
  users: User[],
  schoolId: string | undefined,
  {
    accountName = "",
    role = "",
    displayName = "",
    admissionYear = 0,
    className = "",
    schoolYear = 0,
  }
): (() => void) => {
  if (typeof schoolId === "undefined") {
    throw new Error("SchoolId is undefined");
  }
  const usersRef = collection(getFirestore(), "Users").withConverter(
    userReadConverter
  );
  const queryConstraint: QueryConstraint[] = [];

  queryConstraint.push(where("schoolID", "==", schoolId));

  if (accountName != "") {
    queryConstraint.push(where("accountName", "==", accountName));
  }
  if (role != "") {
    queryConstraint.push(where("role", "==", role));
  } else {
    queryConstraint.push(where("role", "in", ["student", "admin", "teacher"]));
  }

  if (displayName != "") {
    queryConstraint.push(where("displayName", "==", displayName));
  }
  if (admissionYear != 0) {
    queryConstraint.push(where("admissionYear", "==", admissionYear));
  }
  if (className != "") {
    queryConstraint.push(where("className", "==", className));
  }
  if (schoolYear != 0) {
    queryConstraint.push(where("schoolYear", "==", schoolYear));
  }

  queryConstraint.push(orderBy("accountName"));
  const unsubscribe = onSnapshot(
    query(usersRef, ...queryConstraint),
    (snapshot) => {
      users.splice(0);
      snapshot.forEach((doc) => {
        users.push(doc.data());
      });
    }
  );

  return unsubscribe;
};

export const createUser = async (
  user: UserCreateRequest,
  password?: string,
  schoolID?: string,
  prefix?: string
): Promise<void> => {
  const data: createUserData = {
    user,
    password,
    schoolID,
    prefix,
  };
  await httpsCallable(getFunctions(), "createUser")(data);
};

export const updateUser = async (
  id: string,
  user: UserUpdateRequest
): Promise<void> => {
  const data: updateUserData = {
    user,
    id,
  };
  await httpsCallable(getFunctions(), "updateUser")(data);
};

export const deleteUserCompletely = async (id: string): Promise<void> => {
  const data: deleteUserData = {
    id,
  };
  await httpsCallable(getFunctions(), "deleteUserCompletely")(data);
};

export const getUserCSV = (users: User[]) => {
  const lines = users.map(
    (user) =>
      `${user.accountName},${user.role},${user.displayName},${user.admissionYear},${user.className},${user.schoolYear}`
  );
  const text = lines.join("\n");
  return new Blob(["\uFEFF" + text], { type: "text/plain" });
};

export const changeClass = async (
  schoolYear: number,
  classNameNumber: number
): Promise<void> => {
  const data: ChangeClassData = { schoolYear, classNameNumber };

  await httpsCallable(getFunctions(), "changeClass")(data);
};
