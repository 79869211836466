<template>
  <div v-if="validRole" class="container" style="margin-top: 80px">
    <router-link :to="{ name: 'home' }" class="btn btn-primary my-3"
      >メイン画面へ戻る</router-link
    >
    <h1>設定</h1>

    <AdminSetting />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "@/store/store";
import { jumpHomeIfNot } from "../router";

import AdminSetting from "../components/AdminSetting.vue";

export default defineComponent({
  name: "AdminView",

  components: {
    AdminSetting,
  },

  setup() {
    const store = useStore();

    jumpHomeIfNot(["root", "admin"]);

    return {
      store,
      validRole: jumpHomeIfNot(["root", "admin"]),
    };
  },
});
</script>
