<template>
  <div class="modal fade" id="classChangerModal" tabindex="-1">
    <div class="modal-dialog">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <Hiragana f="クラスのへんこう">クラスの変更</Hiragana>
            </h5>
          </div>
          <div class="modal-body">
            <div class="container">
              <p>
                {{ store.state.user.displayName }}さんのクラスを<Hiragana f="にゅうりょく"
                  >入力</Hiragana
                >してください。<br />
                <Hiragana f="クラスをへんこうすると いちどサインアウトされます。">
                  クラスを変更すると一度サインアウトされます。
                </Hiragana>
              </p>
              <div class="row">
                <div class="col-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="schoolYear"
                      aria-label="school year"
                      v-model="schoolYear"
                    >
                      <option v-for="n in store.state.schoolYears" :value="n">
                        {{ n }}<hiragana f="ねん">年</hiragana>
                      </option>
                    </select>
                    <label for="schoolYear"><hiragana f="がくねん">学年</hiragana></label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="className"
                      aria-label="class name"
                      v-model="classNameNumber"
                    >
                      <option v-for="n in classNameNumberMax" :value="n">
                        {{ n }}<hiragana f="くみ">組</hiragana>
                      </option>
                    </select>
                    <label for="className"><hiragana f="くみ">組</hiragana></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              キャンセル
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="onSubmit"
              :disabled="spinner?.processing"
            >
              <ProcessingSpinner ref="spinner" />
              <Hiragana f="へんこう">変更</Hiragana>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "@/store/store";
import { useModal } from "./ModalInterface";
import { changeClass } from "@/infrastructure/UserRepository";

import ProcessingSpinner, { useSpinner } from "@/components/ProcessingSpinner.vue";
import Hiragana from "@/components/Hiragana.vue";

export default defineComponent({
  name: "ClassChanger",
  components: { ProcessingSpinner, Hiragana },
  setup() {
    const store = useStore();

    const schoolYear = ref(1);
    const classNameNumber = ref(1);
    const classNameNumberMax = ref(1);

    const initModal = () => {
      schoolYear.value = store.state.user.schoolYear;
      classNameNumber.value = Number(
        store.state.user.className.match(/.+[年\-]([0-9]+)組{0,1}/)?.[1] ?? 1
      );
      classNameNumberMax.value = store.state.schoolSettings.allowChangingClassMax;
    };

    const _useModal = useModal("classChangerModal", initModal);
    const openModal = () => {
      _useModal.openModal();
    };
    const closeModal = _useModal.closeModal;

    const { spinner, startProcess, finishProcess } = useSpinner();

    const onSubmit = async () => {
      startProcess();
      if (typeof store.state.user.id === "string") {
        await changeClass(schoolYear.value, classNameNumber.value);
      }
      finishProcess(() => {
        closeModal();
      });
    };

    return {
      openModal,
      closeModal,
      schoolYear,
      classNameNumber,
      classNameNumberMax,
      spinner,
      onSubmit,
      store,
    };
  },
});
</script>
