import { Banner } from "./Banner";

// Get: everyone in the school
// Write: admin and teacher
export class SchoolSettings {
  constructor(
    public schoolId: string | undefined = undefined,
    public repeatedPostLimit: number = 1440, // edit same review if user try to
    public tagsTemplate: string[] = [],
    public inappropriateWords: string[] = [],
    public inappropreatePublisher: string[] = [],
    public allowedCCodes: string = ".*",
    public bannedCCodes: string = "",
    public bannedCardIds: string[] = [],
    public banners: Banner[] = [],
    public minReviewCharacter: number[] = [50, 50, 50, 50, 50, 50],
    public enableStudentSignin: boolean = true,
    public allowChangingClass: boolean = false,
    public allowChangingClassMax: number = 9,
    public enableMedal: boolean = false,
    public hideNameAndClass: boolean = false
  ) {}
}
