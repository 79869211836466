import { createRouter, createWebHistory, useRouter } from "vue-router";
import { ref, Ref } from "vue";
import { useStore } from "@/store/store";
import { Role } from "./domain/User";

import HomeView from "@/views/Home.vue";
import LoginView from "@/views/Login.vue";
import AdminUserView from "@/views/AdminUser.vue";
import AdminTagView from "@/views/AdminTags.vue";
import CardNumView from "@/views/CardNum.vue";
import AdminView from "@/views/Admin.vue";
import RootAdminView from "@/views/RootAdmin.vue";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", name: "home", component: HomeView },
    { path: "/login", name: "login", component: LoginView },
    { path: "/users", name: "users", component: AdminUserView },
    { path: "/tags", name: "tags", component: AdminTagView },
    { path: "/cardnum", name: "cardnum", component: CardNumView },
    { path: "/root", name: "root", component: RootAdminView },
    { path: "/schooladmin", name: "admin", component: AdminView },
  ],
});

export const jumpHomeIfNot = (roles: Role[]): Ref<boolean> => {
  const store = useStore();
  const router = useRouter();

  if (!roles.includes(store.state.user.role)) {
    router.push("/");
    return ref(false);
  }
  return ref(true);
};
