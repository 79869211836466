<script lang="ts">
import { defineComponent, ref, reactive, onMounted, onUnmounted } from "vue";
import { mulationTypes } from "@/store/store";
import { useStore } from "@/store/store";
import CardItem from "./CardItem.vue";
import LikeAllButton from "./LikeAllButton.vue";

import { Card } from "@/domain/Card";
import { getCard, getCardsRef } from "@/infrastructure/CardRepository";
import { Pagenation } from "@/infrastructure/Pagenation";
import { getCardQueryConstrains } from "@/infrastructure/CardQuery";

const numRealtimeUpdateSet = 2;

export default defineComponent({
  name: "CardList",

  components: { CardItem, LikeAllButton },

  props: {
    childList: {
      type: Boolean,
      required: false,
      default: false,
    },
    isbn: {
      type: String,
      required: false,
    },
  },

  emits: ["openModal"],

  setup(props, { emit }) {
    const store = useStore();

    onMounted(() => {
      newQuery();
    });

    const unsubscribeStore = store.subscribe((mutation) => {
      if (
        [
          "setSort",
          "setFilter",
          "setSchoolYear",
          "setOldestFiscalYear",
          "setOldestMonth",
          "setUser",
          "setTags",
          "signIn",
        ].includes(mutation.type)
      ) {
        clear();
        clearEventHandle();
        newQuery();
      }
    });
    const cardSet = reactive<Card[][]>([]);
    const noCard = ref(false);

    let pagenation: Pagenation<Card>;

    const loadNext = async () => {
      const nextCards = reactive<Card[]>([]);
      if (typeof pagenation === "undefined") return;
      const useRealtimeUpdate = cardSet.length < numRealtimeUpdateSet;

      const { noMoreData } = await pagenation.loadNextItem(nextCards, useRealtimeUpdate);
      if (noMoreData) clearEventHandle();

      cardSet.push(nextCards);

      if (cardSet[0].length == 0 && noMoreData) {
        noCard.value = true;
      } else {
        noCard.value = false;
      }
    };

    async function newQuery() {
      if (typeof store.state.user.id === "undefined") return;

      if (typeof store.state.school.id === "undefined") return;

      // If banned mode, only banned card is displayed
      if (store.state.cardSelector.sort == "banned") {
        const cards = reactive<Card[]>([]);
        store.state.schoolSettings.bannedCardIds.forEach(async (cardId) => {
          if (cardId !== "")
            cards.push(await getCard(cardId, store.state.school.schoolType));
        });
        cardSet.push(cards);
        return;
      }

      pagenation = new Pagenation<Card>(
        getCardsRef(store.state.user.schoolType),
        getCardQueryConstrains(
          store.state.cardSelector,
          store.state.user.id,
          store.state.user.schoolYearClassHash,
          store.state.school.id,
          props.isbn
        )
      );

      loadNext();

      if (props.childList) {
        parentComponent.value?.addEventListener("scroll", handleScroll);
      } else {
        window.addEventListener("scroll", handleScroll);
      }
    }

    const scrollComponent = ref<InstanceType<typeof HTMLDivElement>>();
    const parentComponent = ref<InstanceType<typeof HTMLDivElement>>();

    let inProcess = false;
    function handleScroll() {
      if (scrollComponent.value == undefined || inProcess) return;
      inProcess = true;

      if (props.childList) {
        if (parentComponent.value == undefined) return;
        if (
          scrollComponent.value.getBoundingClientRect().bottom <
          parentComponent.value.getBoundingClientRect().bottom + window.innerHeight
        ) {
          loadNext();
        }
      } else {
        if (
          scrollComponent.value.getBoundingClientRect().bottom <
          window.innerHeight + window.innerHeight
        ) {
          loadNext();
        }
      }
      setTimeout(() => {
        inProcess = false;
      }, 100);
    }

    const openModal = (card: Card) => {
      emit("openModal", card);
    };

    function clearEventHandle() {
      if (props.childList) {
        parentComponent.value?.removeEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll);
      }
    }

    function clear() {
      clearEventHandle();
      pagenation?.clear();
      cardSet.forEach((cards) => {
        cards.splice(0);
      });
      cardSet.splice(0);
    }

    onUnmounted(() => {
      clearEventHandle();
      unsubscribeStore();
      clear();
    });

    return {
      store,
      props,
      cardSet,
      noCard,
      scrollComponent,
      parentComponent,
      openModal,
      loadNext,
    };
  },
});
</script>

<template>
  <div
    id="card-list"
    class="row gx-4 gy-5 p-4"
    :class="{ innerscroll: props.childList, 'child-list': props.childList }"
    ref="parentComponent"
  >
    <template v-for="(cards, idx) in cardSet">
      <template v-for="card in cards">
        <CardItem
          :key="card.id"
          :card="card"
          @open-modal="openModal"
          v-if="
            (store.state.cardSelector.filter === 'mine' ||
              (store.state.cardSelector.sort !== 'banned' &&
                !store.state.schoolSettings.bannedCardIds.includes(card.id ?? '') &&
                !card.isInappropreate(store.state.schoolSettings)) ||
              store.state.cardSelector.sort === 'banned') &&
            ((store.state.cardSelector.filter === 'otherschool' &&
              card.schoolID !== store.state.user.schoolID) ||
              store.state.cardSelector.filter !== 'otherschool')
          "
        />
      </template>
    </template>
    <div style="display: none">
      <canvas id="imgcanvas" width="720" height="405"></canvas>
    </div>
    <div v-if="noCard">カードがありません。</div>

    <div ref="scrollComponent"></div>
  </div>
  <!-- <div v-else class="row">表示するカードはありません。</div> -->
  <LikeAllButton
    v-if="props.childList === false && store.state.userPreference.teacherMode === true"
    :card-set="cardSet"
  />
</template>

<style>
.innerscroll {
  max-height: 80vh;
  overflow: auto;
}

.list-complete-item {
  transition: all 0.8s ease;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}
</style>
