import * as Sentry from "@sentry/vue";

export const useFeedback = (displayName = "") => {
  return (): void => {
    // const hub = Sentry.getCurrentHub();
    Sentry.withScope(function (scope) {
      scope.setLevel("info");
      Sentry.captureException(new Error("Feedback"));
      Sentry.showReportDialog({
        user: {
          name: displayName,
          email: `info@yomumo.net`,
        },
        title: "お困りですか？",
        subtitle: "何が起きたか教えて下さい",
        subtitle2: "",
        labelEmail: "(変更不要)",
        labelComments: "内容",
        labelSubmit: "送信",
        successMessage: "ご報告ありがとうございます！",
      });
    });
  };
};
