<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { Card } from "@/domain/Card";
import { useStore } from "@/store/store";

import { setMyLike } from "@/infrastructure/MyLikeRepository";
import { setting } from "@/setting";

export default defineComponent({
  name: "LikeButton",
  props: {
    card: {
      type: Card,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    // 💖 or 💔
    // const liked = computed(
    //   () =>
    //     typeof props.card.id !== "undefined" &&
    //     store.state.myLikes.cardIds.includes(props.card.id)
    // );

    const liked = ref(
      typeof props.card.id !== "undefined" &&
        store.state.myLikes.cardIds.includes(props.card.id)
    );
    watch(store.state, () => {
      liked.value =
        typeof props.card.id !== "undefined" &&
        store.state.myLikes.cardIds.includes(props.card.id);
    });

    // Number
    const num = ref(props.card.numLiked);
    watch(props, () => {
      num.value = props.card.numLiked;
    });

    const onLike = () => {
      if (typeof props.card.id === "undefined") return;
      if (typeof store.state.user.id === "undefined") return;

      if (!liked.value) {
        // This is for fast response...
        liked.value = true;
        num.value += 1;
        // ...actual like is done by this
        setMyLike(true, store.state.user, props.card.id, store.state.school.schoolType);
      } else {
        // This is for fast response...
        liked.value = false;
        num.value -= 1;
        // ...actual unlike is done by this
        setMyLike(false, store.state.user, props.card.id, store.state.school.schoolType);
      }
    };

    const manylikes = computed(() => num.value >= setting.manylikeRequirement);

    return { liked, num, onLike, manylikes };
  },
});
</script>
<template>
  <span :id="`like-${card.id}`" class="like-button" @click="onLike">
    <span v-if="!liked">
      <svg class="icon" fill="currentColor">
        <use xlink:href="/icon/icon-unlike.svg#icon" />
      </svg>
    </span>
    <span v-else>
      <svg class="icon" fill="currentColor">
        <use xlink:href="/icon/icon-liked.svg#icon" />
      </svg>
    </span>
    <span :class="{ number: true, 'number-manylikes': manylikes }">{{ num }}</span>
  </span>
</template>
