import { Timestamp } from "@google-cloud/firestore";

// Read: self
// Write: root

type NumLikedYYYYMM = `numLiked${number}`;

export class NumLiked {
  [key: NumLikedYYYYMM]: number;

  constructor(readonly lastLiked: Date) {}

  setNumLiked(key: NumLikedYYYYMM, value: number): void {
    this[key] = value;
  }

  getMonthlyNumLiked(): number {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const MM = String(month).padStart(2, "0");
    const FYYY = String(month >= 4 ? year : year - 1).padStart(4, "0");

    const latestNumLiked = this[`numLiked${FYYY}${MM}` as NumLikedYYYYMM] ?? 0;

    return latestNumLiked;
  }
}
