import { Card } from "./Card";

import {
  addBannedCard,
  removeBannedCard,
} from "../infrastructure/SchoolSettingsRepository";

import { setBanCard } from "../infrastructure/CardRepository";

import { SchoolType } from "./School";

export const banCard = (
  schoolId: string,
  schoolType: SchoolType,
  card: Card
): void => {
  if (typeof card.id === "undefined") return;
  addBannedCard(schoolId, card.id);
  if (card.schoolID === schoolId) setBanCard(card.id, schoolType, true);
};

export const unbanCard = (
  schoolId: string,
  schoolType: SchoolType,
  card: Card
): void => {
  if (typeof card.id === "undefined") return;
  removeBannedCard(schoolId, card.id);
  if (card.schoolID === schoolId) setBanCard(card.id, schoolType, false);
};
