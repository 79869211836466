import { getFirestore } from "@firebase/firestore";
import {
  FieldValue,
  FirestoreDataConverter,
  doc,
  getDoc,
  collection,
} from "firebase/firestore";
import { Book } from "@/domain/Book";
import { booksColName } from "./BooksColName";
import { SchoolType } from "@/domain/School";

type docDataTypeFromFirebase = Partial<
  Book & {
    lastUpdatedAt: { seconds: number; nanosecondes: number };
  }
>;

type docDataTypeToFirebase = Partial<
  Book & {
    lastUpdatedAt: FieldValue;
  }
>;

export const bookConverter: FirestoreDataConverter<Book> = {
  toFirestore(book: Book) {
    throw new Error("Book document can be writtin only from admin SDK.");

    const doc: docDataTypeToFirebase = {
      bookISBN: book.bookISBN,
      num: book.num,
      latestEventId: book.latestEventId,
      lastUpdatedAtSecond: book.serverTimestamp(),
    };

    return doc;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options) as docDataTypeFromFirebase;

    // Check important parameteres
    if (
      typeof data.num === "undefined" ||
      typeof data.lastUpdatedAt === "undefined" ||
      typeof data.lastUpdatedAt?.seconds === "undefined"
    ) {
      throw new Error(`Book data is not properly defined ${data}`);
    }
    const book = new Book(snapshot.id, data.num, data.lastUpdatedAt?.seconds);

    Object.keys(data).forEach((key) => {
      if (key.match(/^num(sy[0-9]+|cy[0-9]+|sy[0-9]+cy[0-9]+)$/))
        book[key] = data[key];
    });

    return book;
  },
};

export const getBooksRef = (schoolType: SchoolType) =>
  collection(getFirestore(), booksColName[schoolType]).withConverter(
    bookConverter
  );

export const bookRef = (isbn: string, schoolType: SchoolType) =>
  doc(getFirestore(), booksColName[schoolType], isbn).withConverter(
    bookConverter
  );

export const getBook = async (
  isbn: string,
  schoolType: SchoolType
): Promise<Book> => {
  const bookDoc = await getDoc(bookRef(isbn, schoolType));
  if (!bookDoc.exists()) throw Error(`Document for ${isbn} does not exist`);
  return bookDoc.data();
};
