import { getFunctions } from "@/infrastructure/firebase";
import { httpsCallable } from "firebase/functions";
import axios from "axios";
import { Card } from "@/domain/Card";
import { User } from "@/domain/User";
import { XMLParser } from "fast-xml-parser";

interface bookApiResult {
  hasRecord: boolean;
  bookTitle?: string;
  bookAuthors?: string;
  bookPublishYear?: number;
  bookPublisher?: string;
  bookCCode?: string;
  bookThumbnailUrl?: string;
}

export const getBookInfo = async (isbn: string): Promise<bookApiResult> => {
  // Try to get infomation from Open DB
  const bookInfoOpenBD = await getBookDataFromOpenBD(isbn);
  if (bookInfoOpenBD.hasRecord) {
    // If thumbnail is empty, get from RakutenAPI
    if (bookInfoOpenBD.bookThumbnailUrl === "") {
      const bookInfoRakuten = (
        await httpsCallable(
          getFunctions(),
          "getBookDataFromRakutenAPI"
        )({ isbn })
      ).data as bookApiResult;
      bookInfoOpenBD.bookThumbnailUrl = bookInfoRakuten.bookThumbnailUrl;
    }
    console.log("OpenBD");
    return bookInfoOpenBD;
  }

  // Try to get infomation from NDL
  const bookInfoNDL = await getBookDataFromNDL(isbn);
  if (bookInfoNDL.hasRecord) {
    console.log("NDL");
    return bookInfoNDL;
  }

  // Try to get infomation from Rakuten
  // const bookInfoRakuten = (
  //   await httpsCallable(getFunctions(), "getBookDataFromRakutenAPI")({ isbn })
  // ).data as bookApiResult;

  // if (bookInfoRakuten.hasRecord) return bookInfoRakuten;

  throw new Error("No record");
};

const getBookDataFromOpenBD = async (isbn: string): Promise<bookApiResult> => {
  const url = "https://api.openbd.jp/v1/get?isbn=" + isbn;
  try {
    const result = await axios.get(url);

    if (result?.data[0] == null) return { hasRecord: false };
    // Match database recode
    const bookTitle = result.data[0].summary.title ?? "";
    const bookAuthors = result.data[0].summary.author ?? "";
    const bookPublishYear =
      Number(result.data[0].summary.pubdate.slice(0, 4)) ?? 0;
    const bookPublisher = result.data[0].summary.publisher ?? "";
    const bookCCode =
      result.data[0].onix.DescriptiveDetail.Subject?.[0]?.SubjectCode ?? "";
    const bookThumbnailUrl = result.data[0].summary.cover ?? "";
    return {
      hasRecord: true,
      bookTitle,
      bookAuthors,
      bookPublishYear,
      bookPublisher,
      bookCCode,
      bookThumbnailUrl,
    };
  } catch (err) {
    throw new Error(`(OpenDB API) ${err}`);
  }
};

export const getBookDataFromNDL = async (
  isbn: string
): Promise<bookApiResult> => {
  const url = "https://iss.ndl.go.jp/api/opensearch?cnt=10&isbn=" + isbn;
  try {
    const result = await axios.get(url);
    const parser = new XMLParser();
    const xml = parser.parse(result.data);
    const items = xml.rss.channel.item as any[];
    if (items.length == 0) return { hasRecord: false };
    const item = items.reduce(
      (prev, current) =>
        current.link?.match(/R100000096/)
          ? current
          : current.link?.match(/R100000002/)
          ? current
          : current.link?.match(/R100000098/)
          ? current
          : current.link?.match(/R100000101/)
          ? current
          : current.link?.match(/R100000104/)
          ? current
          : prev,
      items[0]
    );

    // Match database recode
    const bookTitle = item.title ?? "";
    const bookAuthors = item.author ?? "";
    const bookPublishYear = Number(String(item["dc:date"]).slice(0, 4)) ?? 0;
    const bookPublisher = item["dc:publisher"] ?? "";
    const bookCCode = "";
    const bookThumbnailUrl = "";
    return {
      hasRecord: true,
      bookTitle,
      bookAuthors,
      bookPublishYear,
      bookPublisher,
      bookCCode,
      bookThumbnailUrl,
    };
  } catch (err) {
    throw new Error(`(NDL API) ${err}`);
  }
};
