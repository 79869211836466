<template>
  <div class="select-button btn-group mb-3 mx-5" role="group">
    <input
      type="radio"
      class="btn-check"
      name="btnradio"
      id="mode-num"
      v-model="userCardListMode"
      value="num"
      autocomplete="off"
      checked
    />
    <label class="btn btn-primary" for="mode-num">枚数</label>

    <input
      type="radio"
      class="btn-check"
      name="btnradio"
      id="mode-list"
      v-model="userCardListMode"
      value="list"
      autocomplete="off"
    />
    <label class="btn btn-primary" for="mode-list">カード一覧</label>
  </div>
  <UserCardNum v-if="userCardListMode == 'num'" :user="props.user" />
  <CardList
    v-if="userCardListMode == 'list'"
    class="mt-1 py-5 card-list"
    child-list
    @open-modal="openModal"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, PropType } from "vue";
import { useStore } from "@/store/store";
import UserCardNum from "@/components/UserCardNum.vue";
import CardList from "@/components/CardList.vue";
import { User } from "@/domain/User";
import { Card } from "@/domain/Card";

export default defineComponent({
  name: "UserCard",

  components: { CardList, UserCardNum },

  emits: ["openModal"],

  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();

    const userCardListMode = ref<string>("num");

    onMounted(() => {
      store.commit("setUser", props.user);
      store.commit("setFilter", "user");
    });

    const openModal = (card: Card) => {
      emit("openModal", card);
    };

    return { props, store, userCardListMode, openModal };
  },
});
</script>
<style>
.select-button {
  padding-bottom: 20px;
}
</style>
