<template>
  <button
    class="btn btn-primary me-3"
    @click="createZipDataUrl"
    :disabled="data.processing"
  >
    <span
      v-if="data.processing"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    カードデータエクスポート {{ data.prograss }}
  </button>

  <a :href="data.zipUrl" v-if="data.zipUrl" download>ダウンロード</a>
</template>

<script lang="ts">
import { PropType, defineComponent, reactive } from "vue";
import { useStore } from "@/store/store";
import { User } from "@/domain/User";
import { getCardPublicByUserId } from "@/infrastructure/CardRepository";
import { getDateString } from "@/utilities/misc";
import { getImageDataUrlFromJson } from "@/utilities/misc";
import { getBookThumbnail } from "@/infrastructure/bookThumbnail";
import { convertBookThumbnailURL } from "@/utilities/misc";

export default defineComponent({
  name: "PdfWriter",

  components: {},

  props: {
    users: {
      type: Object as PropType<User[]>,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();

    const data = reactive({
      processing: false,
      prograss: "",
      zipUrl: "",
    });

    const createZipDataUrl = async () => {
      data.processing = true;
      let num = 0;
      const total = props.users.length;
      data.prograss = `${num}/${total}`;

      const jszip = await import("jszip");

      const basePdf = await fetch("/basePdf.pdf").then((res) => res.arrayBuffer());
      const IPAFont = await fetch("/ipaexg.ttf").then((res) => res.arrayBuffer());
      const font = { IPAFont };

      const template = {
        fontName: "IPAFont",
        basePdf: basePdf,
        schemas: schemas,
      };

      const zip = new jszip.default();

      await Promise.all(
        props.users.map(async (user) => {
          const pdfBlob = await createPdf(user, template, font);
          if (typeof pdfBlob !== "undefined") {
            const filename = `${user.accountName}_${user.displayName}.pdf`;
            zip.file(filename, pdfBlob);
          }
          num++;
          data.prograss = `${num}/${total}`;
        })
      );

      const zipBlob = await zip.generateAsync({ type: "blob" });

      // convert to data url
      const reader = new FileReader();
      reader.onload = function (e) {
        const zipUrl = e.target?.result;
        if (typeof zipUrl === "string") {
          data.zipUrl = zipUrl;
        } else {
          data.zipUrl = "";
        }
      };
      reader.readAsDataURL(zipBlob);

      data.processing = false;
      data.prograss = "";
    };

    async function createPdf(
      user: User,
      template: any,
      font: any
    ): Promise<Blob | undefined> {
      const labelmake = await import("labelmake");

      if (typeof user.id === "undefined") return undefined;
      const cards = await getCardPublicByUserId(user.id, user.schoolType);

      if (cards.length === 0) return undefined;

      const inputs = (
        await Promise.all(
          cards.map(async (card) => {
            if (card.isInappropreate(store.state.schoolSettings)) {
              return undefined;
            }

            const date = getDateString(card.createdAtSecond);

            const reviewHandwritten =
              card.reviewHandwrittenJson !== null
                ? getImageDataUrlFromJson(card.reviewHandwrittenJson, "imgcanvas")
                : "";

            // const url = convertBookThumbnailURL(
            //   card.bookThumbnailUrl,
            //   card.createdAtSecond
            // );

            const url = "";

            const bookThumbnail = await getBookThumbnail(url);

            const pageData = {
              createdAt: date,
              schoolName: user.schoolName,
              className: user.className,
              accountName: "",
              displayName: user.displayName,
              bookTitle: card.bookTitle,
              bookAuthors: card.bookAuthors,
              bookPublisher: card.bookPublisher,
              bookPublishYear: String(card.bookPublishYear),
              bookThumbnail: bookThumbnail,
              reviewTyped: "",
              reviewHandwritten: reviewHandwritten,
              reviewTypedWithHandWritten: "",
            };

            if (reviewHandwritten === "") {
              pageData.reviewTyped = card.reviewTyped;
            } else {
              pageData.reviewTypedWithHandWritten = card.reviewTyped;
            }

            return Promise.resolve(pageData);
          })
        )
      ).filter((item) => typeof item !== "undefined");
      //@ts-ignore
      const pdf = await labelmake.default({ template, inputs, font });

      const blob = new Blob([pdf.buffer], { type: "application/pdf" });

      return blob;
    }

    return {
      store,
      data,
      createZipDataUrl,
    };
  },
});

const schemas = [
  {
    schoolName: {
      type: "text",
      position: {
        x: 20,
        y: 24,
      },
      width: 56.43,
      height: 6.99,
      alignment: "left",
      fontSize: 12,
      characterSpacing: 0,
      lineHeight: 1,
    },
    className: {
      type: "text",
      position: {
        x: 83.61,
        y: 24,
      },
      width: 17.27,
      height: 7,
      alignment: "right",
      fontSize: 12,
      characterSpacing: 0,
      lineHeight: 1,
    },
    accountName: {
      type: "text",
      position: {
        x: 101.34,
        y: 24,
      },
      width: 33.94,
      height: 7,
      alignment: "right",
      fontSize: 12,
      characterSpacing: 0,
      lineHeight: 1,
    },
    displayName: {
      type: "text",
      position: {
        x: 135.66,
        y: 24,
      },
      width: 47.43,
      height: 7,
      alignment: "right",
      fontSize: 12,
      characterSpacing: 0,
      lineHeight: 1,
    },
    bookTitle: {
      type: "text",
      position: {
        x: 20,
        y: 31.55,
      },
      width: 120,
      height: 34.57,
      alignment: "left",
      fontSize: 30,
      characterSpacing: 0,
      lineHeight: 1,
    },
    bookAuthors: {
      type: "text",
      position: {
        x: 20,
        y: 66.12,
      },
      width: 120,
      height: 18.72,
      alignment: "left",
      fontSize: 20,
      characterSpacing: 0,
      lineHeight: 1,
    },
    bookPublisherPublishYear: {
      type: "text",
      position: {
        x: 20,
        y: 84.84,
      },
      width: 120,
      height: 6.99,
      alignment: "left",
      fontSize: 12,
      characterSpacing: 0,
      lineHeight: 1,
    },
    bookThumbnail: {
      type: "image",
      position: {
        x: 142.1,
        y: 31.1,
      },
      width: 47.96,
      height: 61.5,
    },
    reviewTyped: {
      type: "text",
      position: {
        x: 20,
        y: 94.38,
      },
      width: 170,
      height: 176.59,
      alignment: "left",
      fontSize: 12,
      characterSpacing: 0,
      lineHeight: 1,
    },
    reviewHandwritten: {
      type: "image",
      position: {
        x: 36.24,
        y: 94.72,
      },
      width: 140,
      height: 91,
    },
    reviewTypedWithHandWritten: {
      type: "text",
      position: {
        x: 20,
        y: 185.76,
      },
      width: 170,
      height: 84.78,
      alignment: "left",
      fontSize: 12,
      characterSpacing: 0,
      lineHeight: 1,
    },
    createdAt: {
      type: "text",
      position: {
        x: 103.11,
        y: 16.67,
      },
      width: 80,
      height: 7,
      alignment: "right",
      fontSize: 12,
      characterSpacing: 0,
      lineHeight: 1,
    },
  },
];
</script>
