<template>
  <nav class="navbar navbar-expand-sm fixed-top" style="overscroll-behavior: none">
    <router-link :to="{ name: 'home' }" class="navbar-brand">
      <img src="/navbar-logo.svg" id="navbar-logo" alt="Yomumo"
    /></router-link>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li
          class="nav-item"
          v-for="(banner, idx) in store.state.schoolSettings.banners"
          v-bind:key="idx"
        >
          <a class="nav-link p-0 mx-4" :href="banner.url">
            <img
              v-if="banner.imageUrl"
              :src="banner.imageUrl"
              :alt="banner.label"
              height="40"
            />
            <span v-else>{{ banner.label }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="form-check form-switch p-0 mt-3 me-4" v-if="store.state.user.role">
      <input
        id="bookface"
        type="checkbox"
        class="form-check-input mt-0 me-3"
        v-model="bookface"
        @change="onBookface"
      />
      <label class="form-check-label" for="bookface"
        ><hiragana f="ひょうし">表紙</hiragana></label
      >
    </div>
    <div
      class="form-check form-switch p-0 mt-3 me-4"
      v-if="store.state.school.schoolType === 'elementary'"
    >
      <input
        id="hiragana"
        type="checkbox"
        class="form-check-input mt-0 me-3"
        v-model="hiragana"
        @change="onHiragana"
      />
      <label class="form-check-label" for="hiragana"> ひらがな </label>
    </div>

    <ul class="navbar-nav me-4" v-if="store.state.user.role">
      <li class="nav-item">
        <UpdateInfo />
      </li>
    </ul>

    <ul class="navbar-nav me-4">
      <li class="nav-item">
        <i class="bi bi-info-circle" style="font-size: 1.5em" @click="feedback"></i>
      </li>
    </ul>
    <ul class="navbar-nav" v-if="store.state.user.role">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle py-0 px-4"
          href="#"
          id="navbarDropdownMenuLink"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            v-bind:src="store.state.auth.userPhotoURL"
            id="userphoto"
            class="me-3"
            v-if="store.state.auth.userPhotoURL"
          />
          <span class="ml-0">{{ store.state.user.displayName }} さん</span>
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li>
            <router-link :to="{ name: 'home' }" class="dropdown-item">
              ホーム</router-link
            >
          </li>
          <li v-if="['root'].includes(store.state.user.role)">
            <router-link :to="{ name: 'root' }" class="dropdown-item">
              ETeq管理画面</router-link
            >
          </li>
          <template v-if="['root', 'admin', 'teacher'].includes(store.state.user.role)">
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a
                class="dropdown-item"
                @click="
                  store.commit(
                    store.state.userPreference.showBookFace
                      ? 'hideBookFace'
                      : 'showBookFace'
                  )
                "
                ><input
                  :checked="store.state.userPreference.showBookFace"
                  type="checkbox"
                  class="form-check-input"
                  autocomplete="off"
                />
                書影表示</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                @click="
                  store.commit(
                    store.state.userPreference.teacherMode
                      ? 'unsetTeacherMode'
                      : 'setTeacherMode'
                  )
                "
                ><input
                  :checked="store.state.userPreference.teacherMode"
                  type="checkbox"
                  class="form-check-input"
                  autocomplete="off"
                />
                教員モード</a
              >
            </li>

            <li>
              <router-link :to="{ name: 'cardnum' }" class="dropdown-item">
                カード枚数</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'tags' }" class="dropdown-item">
                クラスのタグ</router-link
              >
            </li>

            <li>
              <router-link :to="{ name: 'users' }" class="dropdown-item">
                ユーザー管理</router-link
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a class="dropdown-item" href="https://yomumo.super.site/">マニュアル</a>
            </li>
            <li>
              <router-link to="/schooladmin" class="dropdown-item">設定</router-link>
            </li>
          </template>
          <li><hr class="dropdown-divider" /></li>
          <li v-if="store.state.schoolSettings.allowChangingClass">
            <a class="dropdown-item" @click="classChangerModal?.openModal()"
              >クラス<Hiragana f="へんこう">変更</Hiragana></a
            >
          </li>
          <li v-if="!store.state.user.isSSOUser()">
            <a class="dropdown-item" @click="passwordChangerModal?.openModal()"
              >パスワード<Hiragana f="へんこう">変更</Hiragana></a
            >
          </li>
          <li><a class="dropdown-item" @click="signOut">サインアウト</a></li>
        </ul>
      </li>
    </ul>
  </nav>

  <ClassChanger ref="classChangerModal" />
  <PasswordChanger ref="passwordChangerModal" />
  <FloatTools />
</template>

<script setup lang="ts">
import { ref, watch, computed, onUnmounted } from "vue";
import { useStore } from "@/store/store";
import { useFeedback } from "@/utilities/trace";
import PasswordChanger from "@/modals/PaswordChanger.vue";
import ClassChanger from "@/modals/ClassChanger.vue";
import UpdateInfo from "@/components/UpdateInfo.vue";
import Hiragana from "@/components/Hiragana.vue";
import { isSSOUser } from "@/domain/User";
import FloatTools from "./FloatTools.vue";

const store = useStore();

const passwordChangerModal = ref<InstanceType<typeof PasswordChanger>>();
const classChangerModal = ref<InstanceType<typeof ClassChanger>>();
const hiragana = ref(false);
const bookface = ref(true);

const setHiragana = () => {
  hiragana.value = store.state.userPreference.hiragana;
};

const unsubscribe = store.subscribe((mutation, state) => {
  if (mutation.type === "signIn") {
    setHiragana();
    if (
      store.state.user.initialPassword == true &&
      isSSOUser(store.state.user.accountName) == false
    )
      passwordChangerModal.value?.openModal();
    else if (store.state.user.className === "" && store.state.user.role === "student")
      classChangerModal.value?.openModal();
  }
});

onUnmounted(() => {
  unsubscribe();
});

watch(store.state.userPreference, setHiragana);

const onHiragana = () => {
  if (hiragana.value == true) store.commit("setHiragana");
  else store.commit("unsetHiragana");
};

const onBookface = () => {
  if (bookface.value == true) store.commit("showBookFace");
  else store.commit("hideBookFace");
};

// Check if user's password is initial password
watch(store.state.user, () => {});

const feedback = useFeedback(store.state.user.displayName);
const signOut = () => store.dispatch("signOut");
</script>

<style>
#userphoto {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
</style>
