// Get: everyone
// Write: root

export class SchoolYearClass {
  constructor(
    readonly id: string,
    readonly schoolID: string = "",
    readonly admissionYear: number = 0,
    readonly className: string = "",
    readonly schoolYear: number = 0,
    readonly numCards: number = 0,
    readonly tags: string[] = []
  ) {}
}
