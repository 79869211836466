<template>
  <div id="like-all" class="fixed-left-button" @click="onLikeAll">
    <span class="btn btn-light btn-lg rounded-pill py-2 mx-2">
      <span v-if="data.liked == 'none'" style="vertical-align: middle">
        <i class="bi bi-suit-heart liked-none"></i>
      </span>
      <span v-if="data.liked == 'some'" style="vertical-align: middle">
        <i class="bi bi-suit-heart-fill liked-some"></i>
      </span>
      <span v-if="data.liked == 'all'" style="vertical-align: middle">
        <i class="bi bi-suit-heart-fill liked-all"></i>
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, watch, computed } from "vue";
import { useStore } from "@/store/store";
import { setMyLike } from "@/infrastructure/MyLikeRepository";
import { Card } from "@/domain/Card";

export default defineComponent({
  name: "LikeAllButton",
  props: {
    cardSet: { type: Object as PropType<Card[][]>, required: true },
  },

  setup(props) {
    const store = useStore();

    const myLikes = computed(() => store.state.myLikes.cardIds);
    const data = reactive({
      numliked: 0,
      liked: "no-card" as "no-card" | "none" | "some" | "all",
    });

    const originalLiked: string[] = [];
    const originalUnliked: string[] = [];

    watch(
      props,
      () => {
        originalLiked.splice(0);
        originalUnliked.splice(0);

        props.cardSet.forEach((cards) => {
          cards.forEach(async (card) => {
            if (typeof card.id === "undefined") throw new Error("card.id is undefined");
            if (myLikes.value.includes(card.id)) {
              originalLiked.push(card.id);
            } else {
              originalUnliked.push(card.id);
            }
          });
        });

        if (originalLiked.length == 0 && originalUnliked.length == 0) {
          data.liked = "no-card";
        } else if (originalLiked.length == 0) {
          data.liked = "none";
        } else if (originalUnliked.length == 0) {
          data.liked = "all";
        } else {
          data.liked = "some";
        }
      },
      { immediate: true }
    );

    const onLikeAll = () => {
      if (typeof store.state.user.id === "undefined") return;

      if (data.liked == "none") {
        originalUnliked.forEach((cardId) => setMyLike(true, store.state.user, cardId));
        data.liked = "all";
      } else if (data.liked == "some") {
        originalUnliked.forEach((cardId) => setMyLike(true, store.state.user, cardId));
        data.liked = "all";
      } else if (data.liked == "all") {
        // originalLiked.forEach((cardId) => unlikeTo(cardId));
        // data.liked = "none";
      }
    };

    return {
      data,
      onLikeAll,
    };
  },
});
</script>

<style>
.liked-none {
  font-size: 2em;
}

.liked-some {
  font-size: 2em;
  color: #999999;
}

.liked-all {
  font-size: 2em;
  color: #fa5e9a;
}

.fixed-left-button {
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 100;
}
</style>
