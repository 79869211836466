import axios from "axios";

export const convertBlobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });

const thumbnailCache: {
  [key: string]: Promise<string>;
} = {};

// this is only for pdf writer
export function getBookThumbnail(url: string): Promise<string> {
  if (url == "")
    return new Promise((resolve, reject) => {
      return resolve("");
    });

  if (url.match(/rakuten/) != null) {
    return new Promise((resolve, reject) => {
      return resolve("");
    });
  }

  try {
    // if hit cache, return cache
    if (url in thumbnailCache) {
      return thumbnailCache[url];
    }

    // else fetch image
    const promise = axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        const data = res.data;
        const base64 = btoa(
          new Uint8Array(data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        return base64;
      });

    thumbnailCache[url] = promise;
    return thumbnailCache[url];
  } catch (err) {
    console.log("err");
    return new Promise((resolve, reject) => {
      return resolve("");
    });
  }
}
