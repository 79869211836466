<template v-if="['root', 'admin'].includes(store.state.user.role)">
  <div @submit.prevent="saveSettings">
    <form action="#">
      <div class="row mb-5">
        <div class="col">
          <p>設定が完了したら保存ボタンを押してください。</p>
          <button type="submit" class="btn btn-success mb-3 px-5 me-5">
            <ProcessingSpinner ref="spinner" />
            保存
          </button>
        </div>
      </div>

      <div class="row my-5">
        <div class="col-12">
          <div class="form-check form-switch ps-0">
            <input
              class="form-check-input mt-0 me-3"
              type="checkbox"
              id="enableStudentSignin"
              v-model="data.enableStudentSignin"
            />
            <label class="form-check-label" for="enableStudentSignin"
              >児童・生徒によるYomumoの利用を許可
              (このスイッチをオフにすると児童からの利用が一時停止されます。)</label
            >
          </div>
          <div class="form-check form-switch ps-0">
            <input
              class="form-check-input mt-0 me-3"
              type="checkbox"
              id="hideNameAndClass"
              v-model="data.hideNameAndClass"
            />
            <label class="form-check-label" for="hideNameAndClass"
              >氏名・クラス名を非表示にする</label
            >
          </div>
        </div>
      </div>

      <div class="row my-5">
        <h3>クラス変更</h3>
        <div class="col-4">
          <div class="form-check form-switch ps-0">
            <input
              class="form-check-input mt-0 me-3"
              type="checkbox"
              id="allowChangingClass"
              v-model="data.allowChangingClass"
            />
            <label class="form-check-label" for="allowChangingClass"
              >児童・生徒によるクラス変更を許可</label
            >
          </div>
          <div class="mb-3" v-if="data.allowChangingClass">
            <label for="allowChangingClassMax" class="form-label">学級数</label>
            <input
              type="number"
              class="form-control"
              id="allowChangingClassMax"
              v-model.number="data.allowChangingClassMax"
            />
          </div>
        </div>
      </div>

      <div class="row my-5">
        <h3>メダル機能</h3>
        <div class="col-4">
          <div class="form-check form-switch ps-0">
            <input
              class="form-check-input mt-0 me-3"
              type="checkbox"
              id="allowChangingClass"
              v-model="data.enableMedal"
            />
            <label class="form-check-label" for="allowChangingClass">メダルの表示</label>
          </div>
        </div>
      </div>

      <div class="row my-3">
        <h3>タグ</h3>
        <p>
          {{
            store.state.school.schoolName
          }}の全てのクラスで使用するタグを設定します。<br />
          クラスごとのタグは<a href="/tags">こちら</a>から設定できます。
        </p>
        <div class="mb-3 col-12">
          <span v-for="(tag, idx) in data.tagsTemplate" :key="'tag' + idx">
            {{ tag }}
            <button
              type="button"
              class="btn btn-danger btn-sm me-5"
              @click="removeTag(tag)"
            >
              ×
            </button>
          </span>
        </div>
        <div class="mb-3 col-2">
          <input
            type="text"
            class="form-control form-inline"
            id="newtag"
            v-model="newTag"
          />
        </div>
        <div class="mb-3 col-3">
          <button
            type="button"
            class="btn btn-primary form-inline btn-sm mb-3"
            @click="addTag"
          >
            追加
          </button>
        </div>
      </div>
      <div class="row my-3">
        <h3>カードの最小文字数</h3>
        <div class="col-4" v-for="y in store.state.schoolYears" :key="y">
          <label :for="`minchar${y}`" class="form-label">{{ y }}年生</label>
          <input
            type="number"
            class="form-control"
            :id="`minchar${y}`"
            v-model.number="data.minReviewCharacter[y - 1]"
          />
        </div>
      </div>
      <div class="row my-3">
        <h3>時間制限</h3>
        <div class="col-4 mb-3">
          <label for="repeatedPostLimit" class="form-label"
            >同一図書に対する連続登録制限(分)</label
          >
          <input
            type="number"
            class="form-control"
            id="repeatedPostLimit"
            v-model.number="data.repeatedPostLimit"
          />
        </div>
      </div>

      <div class="row my-3">
        <h3>図書制限</h3>
        <div class="col-4">
          <div class="mb-3">
            <label for="inappropriateWords" class="form-label"
              >不適切なタイトル(部分一致,カンマ区切り)</label
            >
            <input
              type="text"
              class="form-control"
              id="inappropriateWords"
              v-model="data.inappropriateWords"
            />
          </div>
          <div class="mb-3">
            <label for="inappropreatePublisher" class="form-label"
              >不適切な出版社(部分一致,カンマ区切り)</label
            >
            <input
              type="text"
              class="form-control"
              id="inappropreatePublisher"
              v-model="data.inappropreatePublisher"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="mb-3">
            <label for="allowedCCodes" class="form-label">許可するCコード</label>
            <input
              type="text"
              class="form-control"
              id="allowedCCodes"
              v-model="data.allowedCCodes"
            />
          </div>
          <div class="mb-3">
            <label for="bannedCCodes" class="form-label">禁止するCコード</label>
            <input
              type="text"
              class="form-control"
              id="bannedCCodes"
              v-model="data.bannedCCodes"
              spellcheck="false"
            />
          </div>
        </div>
        <div class="col-4">
          <div>
            ※許可と禁止が両方していされていたら禁止になります。<br />
            ※Cコードが無いものは許可されます。<br />
            例:<br />全て「.*」<br />
            1を含む「1」<br />1から始まる「^1」<br />1で終わる「1$」<br />1x34「1.34」
          </div>
        </div>
      </div>

      <div class="row my-3">
        <h3>バナー</h3>
        <table class="table">
          <thead>
            <tr>
              <th class="col-2" scope="col">ラベル</th>
              <th class="col-4" scope="col">リンク先URL</th>
              <th class="col-4" scope="col">画像URL</th>
              <th class="col-2" scope="col">アクション</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(banner, id) in data.banners" v-bind:key="id">
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="bannerlabel"
                  v-model="banner.label"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="bannerlabel"
                  v-model="banner.url"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="bannerlabel"
                  v-model="banner.imageUrl"
                />
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger mb-3 px-5"
                  @click="removeBanner(id)"
                >
                  削除
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <button
                  type="button"
                  class="btn btn-primary mb-3 px-5"
                  @click="addBanner"
                >
                  バナー追加
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row mb-5">
        <div class="col">
          <button type="submit" class="btn btn-success mb-3 px-5 me-5">
            <ProcessingSpinner ref="spinner" />
            保存
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";

import { getSetting, storeSetting } from "@/infrastructure/SchoolSettingsRepository";
import { SchoolSettings } from "@/domain/SchoolSettings";
import { Banner } from "@/domain/Banner";

import ProcessingSpinner, { useSpinner } from "@/components/ProcessingSpinner.vue";

export default defineComponent({
  name: "AdminSetting",

  components: { ProcessingSpinner },

  setup() {
    const store = useStore();
    const router = useRouter();

    const newTag = ref("");

    const data = reactive({
      repeatedPostLimit: 0,
      tagsTemplate: [] as string[],
      inappropriateWords: "",
      inappropreatePublisher: "",
      allowedCCodes: "",
      bannedCCodes: "",
      bannedCardIds: "",
      banners: [] as Banner[],
      minReviewCharacter: store.state.schoolSettings.minReviewCharacter,
      enableStudentSignin: true,
      allowChangingClass: false,
      allowChangingClassMax: 9,
      enableMedal: true,
      hideNameAndClass: false,
    });

    const { spinner, startProcess, finishProcess } = useSpinner();

    onMounted(async () => {
      const setting = await getSetting(store.state.user.schoolID);

      // convert for form
      const sep = ",";
      data.repeatedPostLimit = setting.repeatedPostLimit ?? 0;
      data.tagsTemplate = setting?.tagsTemplate ?? [];
      data.inappropriateWords = setting?.inappropriateWords?.join(sep) ?? "";
      data.inappropreatePublisher = setting?.inappropreatePublisher?.join(sep) ?? "";
      data.allowedCCodes = setting?.allowedCCodes ?? "";
      data.bannedCCodes = setting?.bannedCCodes ?? "";
      data.bannedCardIds = setting?.bannedCardIds?.join(sep) ?? "";
      data.banners = setting?.banners;
      data.minReviewCharacter = setting?.minReviewCharacter;
      data.enableStudentSignin = setting?.enableStudentSignin;
      data.allowChangingClass = setting?.allowChangingClass;
      data.allowChangingClassMax = setting?.allowChangingClassMax;
      data.enableMedal = setting?.enableMedal;
      data.hideNameAndClass = setting?.hideNameAndClass;
    });

    const saveSettings = async () => {
      startProcess();
      const setting = new SchoolSettings(store.state.user.schoolID);

      const sep = /[,，]/;

      setting.repeatedPostLimit = data.repeatedPostLimit;
      setting.tagsTemplate = data.tagsTemplate;
      setting.inappropriateWords = data.inappropriateWords.split(sep);
      setting.inappropreatePublisher = data.inappropreatePublisher.split(sep);
      setting.allowedCCodes = data.allowedCCodes;
      setting.bannedCCodes = data.bannedCCodes;
      setting.bannedCardIds = data.bannedCardIds.split(sep);
      setting.banners = data.banners;
      setting.minReviewCharacter = data.minReviewCharacter;
      setting.enableStudentSignin = data.enableStudentSignin;
      setting.allowChangingClass = data.allowChangingClass;
      setting.allowChangingClassMax = data.allowChangingClassMax;
      setting.enableMedal = data.enableMedal;
      setting.hideNameAndClass = data.hideNameAndClass;

      await storeSetting(setting);

      finishProcess(() => {
        router.push({ name: "home" });
      });
    };

    const addBanner = () => {
      data.banners.push(new Banner());
    };

    const removeBanner = (idx: number) => {
      data.banners.splice(idx, 1);
    };

    const addTag = () => {
      if (newTag.value == "") return;
      data.tagsTemplate.push(newTag.value);
      newTag.value = "";
    };

    const removeTag = (tag: string) => {
      data.tagsTemplate = data.tagsTemplate.filter((t) => t != tag);
    };

    return {
      store,
      newTag,
      data,
      addBanner,
      removeBanner,
      saveSettings,
      spinner,
      addTag,
      removeTag,
    };
  },
});
</script>
