<template>
  <h2 class="mt-5">一括編集(登録・編集・削除)</h2>
  <div class="row mb-3">
    <div class="col">
      CSVファイルから読み込む方法とテキストボックスを使う方法の2種類があります。<br />
      <a href="/csvsample.png" target="_blank"
        ><img src="/csvsample.png" id="csvsample" width="800" alt=""
      /></a>
    </div>
  </div>
  <div class="row mb-3 ms-5">
    <div class="col-6">
      <h3>CSVファイルからインポート</h3>
      <p>Excelで保存する際には「CSV UTF-8(コンマ区切り)」を指定してください。</p>
      <input class="form-control col-6" type="file" id="formFile" @change="loadCSVFile" />
    </div>
  </div>
  <div class="row mb-3 ms-5">
    <div class="col-12">
      <h3>テキストボックスに貼り付けてインポート</h3>
      <p>
        Excelやスプレッドシートからコピーアンドペーストできます。区切り文字はカンマまたはタブとしてください。
      </p>
      <textarea class="form-control col-12 mb-3" rows="20" v-model="csvText"></textarea>
      <button class="btn btn-success me-3 px-5" @click="onSubmit">登録</button>
    </div>
  </div>

  <div
    class="modal fade"
    id="confirmationModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">確認画面</h5>
          <button type="button" class="btn-close" @click="cancel"></button>
        </div>
        <div class="modal-body">
          <table class="table">
            <thead>
              <tr>
                <th class="col-2" scope="col">ID</th>
                <th class="col-2" scope="col">ユーザー種別</th>
                <th class="col-2" scope="col">氏名</th>
                <th class="col-1" scope="col">入学年</th>
                <th class="col-1" scope="col">クラス</th>
                <th class="col-1" scope="col">学年</th>
                <th class="col-2" scope="col">パスワード</th>
                <th class="col-1" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(csvUser, idx) in csvUsers" v-bind:key="idx">
                <td>{{ csvUser.user.accountName }}</td>
                <td>{{ csvUser.user.role }}</td>
                <td>{{ csvUser.user.displayName }}</td>
                <td>{{ csvUser.user.admissionYear }}</td>
                <td>{{ csvUser.user.className }}</td>
                <td>{{ csvUser.user.schoolYear }}</td>
                <td>{{ csvUser.mode === "create" ? csvUser.password : "" }}</td>
                <td>
                  <div
                    class="badge bg-success"
                    v-show="typeof csvUser.user.id === 'undefined'"
                  >
                    追加
                  </div>
                  <div
                    class="badge bg-primary"
                    v-show="
                      typeof csvUser.user.id !== 'undefined' && csvUser.mode !== 'delete'
                    "
                  >
                    更新
                  </div>
                  <div class="badge bg-danger" v-show="csvUser.mode === 'delete'">
                    削除
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel">
            キャンセル
          </button>
          <button
            v-if="!updating"
            type="button"
            class="btn btn-primary"
            @click.prevent="updateUsers()"
          >
            決定
          </button>
          <button
            v-if="updating"
            type="button"
            class="btn btn-primary"
            @click.prevent="updateUsers()"
            disabled
          >
            <div class="spinner-border spinner-border-sm text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            決定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, reactive } from "vue";
import { useStore } from "@/store/store";
import { Modal } from "bootstrap";

import {
  createUser,
  updateUser,
  deleteUserCompletely,
} from "@/infrastructure/UserRepository";
import { CSVUser, perseAndChackCSV } from "@/domain/userCSV";

const store = useStore();

const csvText = ref("");

const csvUsers = reactive<CSVUser[]>([]);

let modal: Modal;

const updating = ref(false);

onMounted(() => {
  const element = document.getElementById("confirmationModal");
  modal = new Modal(element as Element, {
    backdrop: "static",
  });
});

const loadCSVFile = (e: any) => {
  csvUsers.splice(0);

  // read from file
  const csvFile = e.target.files[0];
  const reader = new FileReader();
  reader.readAsText(csvFile);
  reader.onload = async () => {
    if (!(typeof reader.result == "string")) return;
    const csvString = reader.result;

    await processCSV(csvString);

    const element = document.getElementById("formFile") as HTMLInputElement;
    element.value = "";
  };
};

const onSubmit = async () => {
  await processCSV(csvText.value);
};

const processCSV = async (csvString: string) => {
  const schoolId = store.state.school.id;

  try {
    if (typeof schoolId === "undefined") {
      throw new Error("SchoolIDが設定されていません。再度サインインしてください。");
    }
    csvUsers.push(
      ...(await perseAndChackCSV(
        csvString,
        schoolId,
        store.state.schoolAccount.emailRegex
      ))
    );
  } catch (err) {
    alert(err);
    cancel();
    return;
  }

  modal.show();
};

const downloadUserInfo = () => {
  const copyText = csvUsers
    .filter((csvUser) => csvUser.mode === "create")
    .map((csvUser, i) => {
      if (csvUser.mode !== "create") return "";
      const user = csvUser.user;
      const password = csvUser.password;
      return `${user.accountName},${user.role},${user.displayName},${user.admissionYear},${user.className},${user.schoolYear},${password}`;
    })
    .join("\n");

  //navigator.clipboard.writeText(copyText);

  const blob = new Blob(["\uFEFF" + copyText], { type: "text/plain" });

  const element = document.createElement("a");
  element.download = "yomumo-password.csv";
  element.href = window.URL.createObjectURL(blob);
  element.click();
};

const updateUsers = async () => {
  if (csvUsers.filter((csvUser) => csvUser.mode === "delete").length > 0) {
    if (confirm("削除されるユーザーが含まれています。続行しますか？") == false) {
      cancel();
      return;
    }
  }

  updating.value = true;

  // if csv file contains created user, download password infomation
  if (csvUsers.filter((csvUser) => typeof csvUser.user.id === "undefined").length > 0) {
    downloadUserInfo();
  }

  await Promise.all(
    csvUsers.map((csvUser, i) => {
      if (csvUser.mode === "create") {
        return createUser(csvUser.createdUser, csvUser.password);
      } else if (csvUser.mode === "update") {
        return updateUser(csvUser.id, csvUser.updatedUser);
      } else if (csvUser.mode === "delete") {
        return deleteUserCompletely(csvUser.id);
      }
    })
  );

  updating.value = false;

  csvUsers.splice(0);

  modal.hide();
};

const cancel = () => {
  if (updating.value == true) return;

  csvUsers.splice(0);

  const element = document.getElementById("formFile") as HTMLInputElement;
  element.value = "";

  modal.hide();
};
</script>
