<template>
  <h3 class="section">
    <Hiragana :f="props.ruby">{{ props.label }}</Hiragana>
  </h3>
  <div class="d-flex" v-if="mode == 'fix'">
    <input type="text" class="w-100 form-control-plaintext pd-3" v-model="num" readonly />
    <!-- edit -->
    <button type="button" class="btn btn-success" @click="onEdit" v-if="!props.readonly">
      <i class="bi bi-pencil-square"></i>
    </button>
  </div>
  <div class="d-flex" v-if="mode == 'input'">
    <YearSelector v-model:year="num" @change="onOK" />
    <!-- cancel -->
    <button type="button" class="btn btn-success" @click="onOK">
      <i class="bi bi-check-lg"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import YearSelector from "./YearSelector.vue";
import Hiragana from "@/components/Hiragana.vue";

export default defineComponent({
  name: "YearInput",

  components: { YearSelector, Hiragana },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    label: String,
    ruby: { type: String, required: false, default: "" },
    readonly: Boolean,
  },

  setup(props, { emit }) {
    const mode = ref("fix");
    const num = ref(0);

    watch(props, () => {
      init();
    });

    function init() {
      mode.value = "fix";
      num.value = props.modelValue;
    }

    const onEdit = () => {
      mode.value = "input";
    };

    const onInput = () => {
      emit("update:modelValue", num.value);
    };

    const onOK = () => {
      mode.value = "fix";
      emit("update:modelValue", num.value);
    };

    return { props, mode, num, onEdit, onInput, onOK };
  },
});
</script>
