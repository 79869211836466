<template>
  <div class="modal" id="newusermodal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <label for="accountName" class="form-label">ID</label>
          <input
            type="text"
            class="form-control"
            name="accountName"
            v-model="newUser.accountName"
            placeholder="100123 または 100123@yomumo-sho.ed.jp"
          />

          <label for="role" class="form-label">ユーザー種別</label>
          <select class="form-select" name="role" v-model="newUser.role">
            <option value="student">児童・生徒</option>
            <option value="teacher">教員</option>
            <option value="admin" v-if="store.state.user.role === 'root'">管理者</option>
          </select>

          <label for="displayName" class="form-label">氏名</label>
          <input
            type="text"
            class="form-control"
            name="displayName"
            placeholder="本野太郎"
            v-model="newUser.displayName"
          />

          <label for="role" class="form-label">入学年(1年時の年度)</label>
          <YearSelector name="role" v-model:year="newUser.admissionYear" :yearNum="7" />

          <label for="className" class="form-label">クラス</label>
          <input
            type="text"
            class="form-control"
            name="className"
            v-model="newUser.className"
            placeholder="1年2組"
          />

          <label for="schoolYear" class="form-label">学年</label>
          <select
            class="form-select"
            name="schoolYear"
            v-model.number="newUser.schoolYear"
          >
            <option value="0"></option>
            <option
              v-for="(year, idx) in store.state.schoolYears"
              :key="idx"
              :value="year"
            >
              {{ year }}
            </option>
          </select>

          <template v-if="store.state.user.role === 'root'">
            <label for="schoolid" class="form-label">SchoolID</label>
            <input
              type="text"
              class="form-control"
              name="schoolid"
              v-model="newUser.schoolID"
            />

            <label for="prefix" class="form-label">prefix</label>
            <input
              type="text"
              class="form-control"
              name="prefix"
              v-model="newUser.prefix"
            />
          </template>
        </div>
        <div class="modal-footer">
          <div v-if="message" class="alert alert-danger">{{ message }}</div>
          <div v-if="!showCloseButton">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="closeModal"
            >
              キャンセル
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="onCreateUser"
              :disabled="
                newUser.accountName == '' ||
                newUser.displayName == '' ||
                spinner?.processing
              "
            >
              <ProcessingSpinner ref="spinner" />
              追加
            </button>
          </div>
          <div v-else="!showCloseButton">
            <button type="button" class="btn btn-primary" @click="closeModal">
              <ProcessingSpinner ref="spinner" />
              閉じる
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "@/store/store";
import { useModal } from "./ModalInterface";

import { createUser } from "@/infrastructure/UserRepository";
import {
  UserCreateRequest,
  isSSOUser,
  isValidEmail,
  isValidAccountName,
} from "@/domain/User";
import { InitialPassword } from "@/domain/password";
import ProcessingSpinner, { useSpinner } from "@/components/ProcessingSpinner.vue";
import YearSelector from "@/components/YearSelector.vue";

export default defineComponent({
  name: "NewUserModal",
  components: { YearSelector, ProcessingSpinner },

  setup(props) {
    const store = useStore();

    const newUser = reactive({
      role: "student" as "student" | "teacher" | "admin",
      accountName: "",
      displayName: "",
      admissionYear: 0,
      className: "",
      schoolYear: 0,
      schoolID: "",
      prefix: "",
    });

    const initModal = () => {
      newUser.role = "student";
      newUser.accountName = "";
      newUser.displayName = "";
      newUser.admissionYear = 0;
      newUser.className = "";
      newUser.schoolYear = 0;
      message.value = "";
      showCloseButton.value = false;
    };

    const { openModal, closeModal } = useModal("newusermodal", initModal);

    const { spinner, startProcess, finishProcess } = useSpinner();

    const message = ref("");
    const showCloseButton = ref(false);

    const onCreateUser = async () => {
      message.value = "ユーザーを作成中です";
      startProcess();

      const schoolID = store.state.school.id ?? newUser.schoolID;

      const user: UserCreateRequest = {
        role: newUser.role,
        accountName: newUser.accountName.replace(/[\x00-\x1F\x7F-\x9F]/g, ""),
        displayName: newUser.displayName,
        admissionYear: newUser.admissionYear,
        className: newUser.className,
        schoolYear: newUser.schoolYear,
      };

      try {
        if (
          user.className !== "" &&
          user.role == "student" &&
          !user.className.match(/^\d+年.+組$/)
        )
          throw new Error(`クラス名は○年○組という形式で入力してください。`);
        if (isSSOUser(user.accountName)) {
          if (!isValidEmail(user.accountName, store.state.schoolAccount.emailRegex))
            throw new Error("正しいメールアドレスを入力してください。");
          await createUser(user, undefined, schoolID, newUser.prefix);
          message.value = `新しいユーザーを作成しました。`;
          showCloseButton.value = true;
        } else {
          if (!isValidAccountName(user.accountName))
            throw new Error("正しいユーザー名を入力してください。");
          const initialPassword = new InitialPassword();
          await createUser(user, initialPassword.password, schoolID, newUser.prefix);
          message.value = `新しいユーザーを作成しました。IDは「${user.accountName}」パスワードは「${initialPassword.password}」です。`;
          showCloseButton.value = true;
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message.match("User already exists.")) {
            message.value = "ユーザーはすでに存在します。";
          } else {
            message.value = `新しいユーザーの作成に失敗しました。${err.message}`;
          }
        } else {
          message.value = `新しいユーザーの作成に失敗しました。${err}`;
        }
        showCloseButton.value = false;
      }
      finishProcess(() => {});
    };

    return {
      props,
      store,
      openModal,
      closeModal,
      newUser,
      onCreateUser,
      spinner,
      message,
      showCloseButton,
    };
  },
});
</script>
