<template>
  <h3 class="section"><i class="bi bi-exclamation-triangle"></i>通報者</h3>
  <p class="my-0 reportuser" v-for="(user, index) in reportUser" :key="index">
    {{ user.className }} {{ user.displayName }}
  </p>
  <p class="my-0 reportuser" v-if="containsOtherSchoool">他校のユーザー</p>
</template>

<script setup lang="ts">
import { defineProps, ref, watch, onMounted } from "vue";

import { User } from "@/domain/User";
import { getReport } from "@/infrastructure/ReportRepository";
import { useStore } from "@/store/store";

const props = defineProps<{
  cardId: string;
}>();

const reportUser = ref<User[]>([]);
const containsOtherSchoool = ref(false);
const store = useStore();

async function refresh() {
  const reportUsers = await getReport(props.cardId, store.state.school.schoolType);

  // reset data
  reportUser.value.splice(0);
  containsOtherSchoool.value = false;

  // add data
  reportUsers.forEach((user) => {
    if (user.id === "otherschool") {
      containsOtherSchoool.value = true;
    } else {
      reportUser.value.push(user as User);
    }
  });
}

onMounted(refresh);
watch(props, refresh);
</script>

<style>
.reportuser {
  font-size: 0.8em;
  color: #999999;
}
</style>
