<script setup lang="ts">
import { defineComponent, reactive, onMounted } from "vue";
import { useStore } from "@/store/store";

import { setFirebaseUI } from "@/infrastructure/auth";
import Hiragana from "@/components/Hiragana.vue";

import "firebaseui/dist/firebaseui.css";

const store = useStore();

const data = reactive({
  accountName: "",
  prefix: "",
  password: "",
  alert: "",
  logining: false,
});

onMounted(() => {
  setFirebaseUI("#firebaseui-container", (uid: string) => {
    store.dispatch("sineInByUI", { uid });
  });
});

const login = () => {
  store
    .dispatch("signIn", {
      accountName: data.accountName,
      password: data.password,
    })
    .catch((err) => {
      data.alert = err.message;
    });
};
</script>

<template>
  <div class="d-flex flex-column align-items-center">
    <div id="signin-logo"><img id="logo-signin" src="/logo-signin.svg" /></div>
    <div id="signin">
      <form class="row" @submit.prevent="login()">
        <h3>IDでサインイン</h3>
        <label for="accountName" class="form-label">サインインID</label>
        <input
          type="text"
          name="username"
          autocomplete="username"
          autocorrect="off"
          class="form-control"
          id="accountName"
          v-model="data.accountName"
          @input="data.alert = ''"
        />
        <label for="password" class="form-label">パスワード</label>
        <input
          type="password"
          class="form-control"
          nama="password"
          autocomplete="password"
          id="password"
          v-model="data.password"
          @input="data.alert = ''"
        />
        <div class="text-center">
          <button
            type="submit"
            class="btn btn-success rounded-pill px-5"
            :disabled="data.accountName === '' || data.password === ''"
          >
            <div
              v-if="data.logining"
              class="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            サインイン
          </button>
        </div>
      </form>
      <h4>
        <Hiragana f="もしくはいかのアカウントでサインイン"
          >もしくは以下のアカウントでサインイン</Hiragana
        >
      </h4>
      <div class="" style="height: 140px">
        <div id="firebaseui-container"></div>
      </div>
      <div class="row">
        <div class="col px-0 mb-0">
          <div class="alert alert-danger text-center m-0" v-if="data.alert">
            {{ data.alert }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center my-5">
      <div class="col-lg-6 col-12 text-center text-muted">
        Yomumoをご利用いただくためには、地方自治体または学校法人などの利用者登録が必要です。詳しくは株式会社ETeqの<a
          href="https://eteq.co.jp/yomumo/"
          >製品紹介</a
        >のページよりお問い合わせください。
      </div>
    </div>
    <div class="row justify-content-center my-5">
      <div class="col-lg-3 col-12 text-center">
        <a href="https://eteq.co.jp/yomumo/">製品紹介</a>
      </div>
      <div class="col-lg-3 col-12 text-center">
        <a href="https://yomumo.super.site/f41afa645341465c8c9f772fbc079f50">利用規約</a>
      </div>
      <div class="col-lg-3 col-12 text-center">
        <a href="https://yomumo.super.site/2f6a8bf2d1234ae5aeb0e404dc6a8810"
          >プライバシーポリシー</a
        >
      </div>
    </div>
  </div>
</template>
