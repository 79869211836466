import {
  getFirestore,
  FirestoreDataConverter,
  serverTimestamp,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

import { Role } from "../domain/User";
import { User } from "../domain/User";
import { UserData } from "./../domain/UserData";
import { UpdateInfoData } from "../domain/UpdateInfo";
// import { getLatestLikedCount } from "./CardRepository";

import data from "../../releaseNote.json";
// import { getDateString } from "@/utilities/misc";

const converter: FirestoreDataConverter<UserData> = {
  toFirestore(data: UserData) {
    const docData = {
      lastGetUpdateInfo: data.lastGetUpdateInfo
        ? data.lastGetUpdateInfo
        : serverTimestamp(),
    };
    return docData;
  },
  fromFirestore(snapshot, options) {
    const docData = snapshot.data(options);

    return {
      lastGetUpdateInfo: docData.lastGetUpdateInfo?.toDate(),
    };
  },
};

export const getLastGetUpdateInfo = async (user: User): Promise<Date> => {
  const ref = doc(
    getFirestore(),
    `/Users/${user.id}/UserData/userData`
  ).withConverter(converter);
  try {
    const snapshot = await getDoc(ref);
    const lastGetUpdateInfo = snapshot.data()?.lastGetUpdateInfo;

    if (typeof lastGetUpdateInfo !== "undefined") return lastGetUpdateInfo;
  } catch (err) {}
  storeLastGetUpdateInfo(user, new Date());
  return new Date();
};

export const storeLastGetUpdateInfo = async (
  user: User,
  lastGetUpdateInfo: Date
): Promise<void> => {
  const ref = doc(
    getFirestore(),
    `/Users/${user.id}/UserData/userData`
  ).withConverter(converter);

  await setDoc(ref, { lastGetUpdateInfo }, { merge: true });
};

export const getUpdateInfo = async (
  user: User,
  from: Date,
  role: Role
): Promise<UpdateInfoData[]> => {
  const updateInfoData = data
    .filter((row) => row.role.includes(role) && new Date(row.date) >= from)
    .map((row) => ({
      title: row.title,
      date: new Date(row.date),
      contents: row.contents,
    }));

  // const latestLikeInfo = await getLatestLikedCount(user, from);
  // if (latestLikeInfo.count > 0) {
  //   const dateString = getDateString(from);
  //   updateInfoData.push({
  //     title: "like",
  //     date: latestLikeInfo.date,
  //     contents: `${dateString}から${latestLikeInfo.count}回いいねされました。`,
  //   });
  //   updateInfoData.sort((a, b) => (a.date < b.date ? 1 : -1));
  // }
  return updateInfoData;
};
