import { getFirestore } from "@firebase/firestore";
import {
  FirestoreDataConverter,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  query,
  where,
  orderBy,
  doc,
  onSnapshot,
  Unsubscribe,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { SchoolYearClass } from "@/domain/SchoolYearClass";

type docDataType = Partial<SchoolYearClass>;

export const converter: FirestoreDataConverter<SchoolYearClass> = {
  toFirestore(schoolYearClass: SchoolYearClass) {
    const docData: docDataType = {
      schoolID: schoolYearClass.schoolID,
      admissionYear: schoolYearClass.admissionYear,
      className: schoolYearClass.className,
      schoolYear: schoolYearClass.schoolYear,
      numCards: schoolYearClass.numCards,
      tags: schoolYearClass.tags,
    };
    return docData;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options) as docDataType;

    return new SchoolYearClass(
      snapshot.id, // get schoolId
      data.schoolID,
      data.admissionYear,
      data.className,
      data.schoolYear,
      data.numCards,
      data.tags
    );
  },
};

export const getSchoolYearClassOfYear = async (
  schoolId: string,
  year: number,
  schoolYears: number[]
): Promise<SchoolYearClass[]> => {
  const ref = collection(getFirestore(), "SchoolYearClasses").withConverter(
    converter
  );

  // Get 2D Array: SchoolYearClass[][]
  const schoolYearClassForYeachYear = await Promise.all(
    schoolYears.map(async (y) => {
      const snapshot = await getDocs(
        query(
          ref,
          where("schoolID", "==", schoolId),
          where("schoolYear", "==", y),
          where("admissionYear", "==", year - (y - 1)),
          orderBy("className", "asc")
        )
      );
      return snapshot.docs.map((doc) => doc.data());
    })
  );

  // Convert to 1D Array: SchoolYearClass[]
  const schoolYearClasses = schoolYearClassForYeachYear.reduce(
    (pre, current) => {
      pre.push(...current);
      return pre;
    },
    []
  );

  return schoolYearClasses;
};

export const getSchoolYearClass = async (
  id: string
): Promise<SchoolYearClass> => {
  const ref = doc(getFirestore(), "SchoolYearClasses", id).withConverter(
    converter
  );
  const snapshot = await getDoc(ref);
  if (!snapshot.exists()) throw new Error(`Document for ${id} does not exist`);

  return snapshot.data();
};

export const getSchoolYearClassByRealtimeUpdate = (
  id: string,
  callback: (schoolYearClass: SchoolYearClass) => void
): Unsubscribe => {
  const ref = doc(getFirestore(), "SchoolYearClasses", id).withConverter(
    converter
  );
  const unsubscribed = onSnapshot(ref, (snapshot) => {
    const schoolYearClass = snapshot.data();

    if (typeof schoolYearClass !== "undefined") callback(schoolYearClass);
  });

  return unsubscribed;
};

// タグを追加(teacher, adminのみが可能)
export const addTagToSchoolYearClass = async (
  id: string,
  tag: string
): Promise<void> => {
  const ref = doc(getFirestore(), "SchoolYearClasses", id).withConverter(
    converter
  );
  await updateDoc(ref, { tags: arrayUnion(tag) });
};

// タグを削除(teacher, adminのみが可能)
export const removeTagFromSchoolYearClass = async (
  id: string,
  tag: string
): Promise<void> => {
  const ref = doc(getFirestore(), "SchoolYearClasses", id).withConverter(
    converter
  );
  await updateDoc(ref, { tags: arrayRemove(tag) });
};
