<template>
  <div v-if="validRole" class="container" style="margin-top: 80px">
    <h1>ETeq管理画面</h1>

    <!-- 追加画面 -->
    <form class="mb-3">
      <label for="shoolName" class="col-12 col-form-label">学校名</label>
      <div class="col-12">
        <input
          type="input"
          class="form-control"
          id="schoolName"
          v-model="newSchool.schoolName"
          placeholder="●●小学校"
        />
      </div>
      <label for="city" class="col-12 col-form-label">所在市町村</label>
      <div class="col-12">
        <input
          type="input"
          class="form-control"
          id="city"
          v-model="newSchool.city"
          placeholder="●●市"
        />
      </div>
      <label for="schoolID" class="col-12 col-form-label">Prefix</label>
      <div class="col-12">
        <input
          type="input"
          class="form-control"
          id="schoolID"
          v-model="newSchool.prefix"
          placeholder="123456"
        />
      </div>
      <label for="maxStudentNum" class="col-12 col-form-label">最大人数</label>
      <div class="col-12">
        <input
          type="input"
          class="form-control"
          id="schoolID"
          v-model="newSchool.maxStudentNum"
          placeholder="123456"
        />
      </div>
      <label for="emailRegex" class="col-12 col-form-label"
        >Email正規表現(例「^[a-zA-Z0-9_.+-]+@eteq\.co\.jp$」)</label
      >
      <div class="col-12">
        <input
          type="input"
          class="form-control"
          id="emailRegex"
          v-model="newSchool.emailRegex"
          placeholder="^[a-zA-Z0-9_.+-]+@eteq\.co\.jp$"
        />
      </div>
      <label for="schooltype" class="col-12 col-form-label">学校種別</label>

      <div class="col-12">
        <select class="form-select" id="schooltype" v-model="newSchool.schoolType">
          <option value="elementary">小学校</option>
          <option value="juniorhigh">中学校</option>
        </select>
      </div>
      <button type="button" class="btn btn-primary" @click="onAddSchool()">追加</button>
    </form>

    <!-- 学校一覧 -->
    <table class="table">
      <thead>
        <tr>
          <th scope="col">学校ID</th>
          <th scope="col">学校名</th>
          <th scope="col">所在市町村</th>
          <th scope="col">学校種別</th>
          <th scope="col">アクション</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(school, key) in schools" v-bind:key="key">
          <td>{{ school.id }}</td>
          <td>{{ school.schoolName }}</td>
          <td>{{ school.city }}</td>
          <td>{{ school.schoolType }}</td>
          <td>
            <button
              type="button"
              class="btn btn-primary me-3"
              @click="showUsers(school.id)"
            >
              ユーザー一覧
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="onDeleteSchool(school.id, school.schoolName)"
            >
              削除
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- ユーザー一覧 -->
    <AdminUser v-if="userListSchoolID.length" :schoolID="userListSchoolID" />
  </div>
</template>

<script lang="ts">
import { reactive, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "@/store/store";
import { jumpHomeIfNot } from "../router";

import AdminUser from "../components/AdminUser.vue";

import { School, SchoolCreateRequest } from "@/domain/School";
import {
  createSchool,
  getSchoolsByRealtimeUpdate,
  deleteSchool,
} from "@/infrastructure/SchoolRepository";

export default {
  name: "root-admin",
  components: {
    AdminUser,
  },
  setup() {
    const store = useStore();

    const newSchool = reactive<SchoolCreateRequest>(new SchoolCreateRequest());

    let unsubscribe = () => {};

    const userListSchoolID = ref("");

    const onAddSchool = async () => {
      createSchool(newSchool);
    };

    const onDeleteSchool = (schoolId: string | undefined, schoolName: string) => {
      if (!schoolId) return;
      if (prompt("本当に削除しますか？削除する学校名を入力してください") === schoolName) {
        console.log({ schoolId, schoolName });
        deleteSchool(schoolId);
      } else {
        alert("学校名が異なるのでキャンセルしました");
      }
    };

    const schools = reactive<School[]>([]);

    onMounted(() => {
      unsubscribe = getSchoolsByRealtimeUpdate((data) => {
        schools.splice(0);
        schools.push(...data);
      });
    });

    onUnmounted(() => {
      unsubscribe();
    });

    const showUsers = (key: string) => {
      userListSchoolID.value = key;
    };

    return {
      store,
      newSchool,
      onAddSchool,
      onDeleteSchool,
      schools,
      showUsers,
      userListSchoolID,
      validRole: jumpHomeIfNot(["root"]),
    };
  },
};
</script>
