<template>
  <div id="barcode-reader" class="d-flex mb-3">
    <div class="flex-fill d-flex flex-wrap">
      <div>
        <svg class="icon me-3" fill="currentColor">
          <use xlink:href="/icon/icon-barcode.svg#icon" />
        </svg>
        <input
          type="tel"
          class="form-control-lg col float-center"
          id="isbn"
          v-model="data.isbn"
          placeholder="9784700000000"
        />
      </div>
      <div class="ms-auto">
        <button
          class="btn btn-success rounded-pill px-5 me-3"
          v-if="multipleCamera"
          @click="switchCamera"
        >
          カメラ<Hiragana f="きりかえ">切替</Hiragana>
        </button>

        <button class="btn btn-success rounded-pill px-5 me-5" v-on:click="skip">
          スキップ
        </button>
      </div>
    </div>

    <!-- close button smaller screens -->
    <button type="button" class="btn-close float-end" @click="close"></button>
  </div>
  <div id="cameraArea" style="min-height: 900px"></div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useStore } from "@/store/store";
import { startBarcodeReader, getDeviceInfos, stopBarcodeReader } from "@/device/camera";
import Hiragana from "@/components/Hiragana.vue";

const store = useStore();

const props = defineProps<{
  modelValue: String;
}>();

const emits = defineEmits(["update:modelValue", "on-finish-scan", "close"]);

const multipleCamera = ref(false);

const data = reactive({
  isbn: "",
});

const enableBarcodeReader = (cameraNumber: number) => {
  return startBarcodeReader(cameraNumber, (results) => {
    // @ts-expect-error:
    (results as QuaggaJSResultObject[]).forEach((result) => {
      if (typeof result.codeResult === "undefined") return;
      data.isbn = result.codeResult.code;
      checkCode();
    });
  });
};

const disableBarcodeReader = () => {
  stopBarcodeReader();
};

onMounted(async () => {
  multipleCamera.value = (await getDeviceInfos()).length > 1;
  enableBarcodeReader(store.state.userPreference.defaultCameraNumber);
});

onUnmounted(() => {
  disableBarcodeReader();
});

function checkCode() {
  if (
    (data.isbn.length == 13 &&
      (data.isbn?.slice(0, 3) == "978" || data.isbn?.slice(0, 3) == "979")) ||
    (data.isbn.length == 10 &&
      data.isbn?.slice(0, 3) != "978" &&
      data.isbn?.slice(0, 3) != "979")
  ) {
    emits("update:modelValue", data.isbn);
    emits("on-finish-scan");
    return;
  }
  return;
}

const skip = () => {
  disableBarcodeReader();

  emits("update:modelValue", "");
  emits("on-finish-scan");
};

watch(data, () => {
  checkCode();
});

const switchCamera = async () => {
  const deviceInfos = await getDeviceInfos();
  let cameraNumber: number = store.state.userPreference.defaultCameraNumber;
  if (cameraNumber + 1 < deviceInfos.length) {
    cameraNumber++;
  } else {
    cameraNumber = 0;
  }
  disableBarcodeReader();
  cameraNumber = await enableBarcodeReader(cameraNumber);
  store.commit("setDefaultCameraNumber", cameraNumber);
};

const close = () => {
  emits("update:modelValue", "");
  emits("on-finish-scan");
  emits("close");
};
</script>

<style>
#cameraArea {
  /* overflow: hidden; */
  width: 100%;
  position: relative;
  display: flex;
}
#cameraArea video,
#cameraArea canvas {
  width: 100%;
  position: absolute;
}
</style>
