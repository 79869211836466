import {
  getFirestore,
  FirestoreDataConverter,
  getDoc,
  setDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  Unsubscribe,
  onSnapshot,
} from "firebase/firestore";
import { Timestamp } from "@google-cloud/firestore";

import { NumLiked } from "@/domain/NumLiked";

type NumLikedYYYYMM = `${number}`;

type NumLikedDoc = {
  lastLiked: Timestamp;
  [key: NumLikedYYYYMM]: number;
};

export const getNumLikedRealtimeUpdate = (
  userID: string,
  callback: (numLiked: NumLiked) => void
): Unsubscribe => {
  const ref = doc(getFirestore(), `Users/${userID}/UserData/numLiked`);
  const unsubscribed = onSnapshot(ref, (snapshot) => {
    if (!snapshot.exists()) return;
    const doc = snapshot.data() as NumLikedDoc;

    const numLiked = new NumLiked(
      doc.lastLiked?.toDate() ?? new Date(2020, 0, 1) // if undefined lastLast liked used date old enough
    );

    Object.keys(doc).forEach((key) => {
      if (key === "lastLiked") return;
      if (key.match(/numLiked(\d{4}|\d{2})/)) {
        // @ts-expect-error: will have key error
        numLiked.setNumLiked(key, doc[key]);
      }
    });

    if (typeof doc !== "undefined") callback(numLiked);
  });

  return unsubscribed;
};
