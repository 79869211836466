<template>
  <CardNumModal ref="cardNumModal" />

  <div id="float-tools">
    <span
      class="btn btn-primary btn-lg rounded-pill px-4 mx-2"
      id="cardnum"
      v-if="store.state.cardSelector.filter == 'mine'"
      @click="openCardNumModal"
    >
      わたしのカード： {{ store.state.user?.numCards }}
    </span>
    <span
      class="btn btn-primary btn-lg rounded-pill px-4 mx-2"
      id="cardnum"
      v-if="store.state.cardSelector.filter == 'class'"
      >{{ store.state.schoolYearClass?.className }}のカード：{{
        store.state.schoolYearClass?.numCards
      }}</span
    >
    <span
      class="btn btn-primary btn-lg rounded-pill px-4 mx-2"
      id="cardnum"
      v-if="store.state.cardSelector.filter == 'schoolyear'"
    >
      {{ store.state.cardSelector.schoolYear
      }}<Hiragana f="ねんせい">年生</Hiragana>のカード：{{ schoolYearCardNum }}
    </span>
    <span
      class="btn btn-primary btn-lg rounded-pill px-4 mx-2"
      id="cardnum"
      v-if="store.state.cardSelector.filter == 'school'"
    >
      {{ store.state.school?.schoolName }}のカード：{{ store.state.school?.numCards }}
    </span>
    <span
      class="btn btn-primary btn-lg rounded-pill px-4 mx-2"
      id="monthly-liked"
      v-if="store.state.cardSelector.filter == 'mine'"
      @click="onMonthlyLiked"
    >
      <Hiragana f="こんげつの">今月の</Hiragana>
      <svg class="icon ms-2" fill="currentColor">
        <use xlink:href="/icon/icon-liked2.svg#icon" /></svg
      >：{{ monthlyLiked }}
    </span>
    <button
      type="button"
      class="btn btn-success btn-lg rounded-circle mx-2 p-0"
      style="width: 3rem; height: 3rem"
      @click="onRefresh"
    >
      <svg class="icon" fill="currentColor">
        <use xlink:href="/icon/icon-update.svg#icon" />
      </svg>
    </button>
    <button
      type="button"
      id="scroll-to-top"
      class="btn btn-primary btn-lg rounded-circle mx-2 p-0"
      style="width: 3rem; height: 3rem"
      @click="onScrollToTop"
    >
      <svg class="icon" fill="currentColor">
        <use xlink:href="/icon/icon-top.svg#icon" />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onUnmounted } from "vue";
import { useStore } from "@/store/store";
import Hiragana from "@/components/Hiragana.vue";
import { getSchoolYearCardNum } from "@/infrastructure/CardNumRepository";
import CardNumModal from "@/modals/CardNumModal.vue";

const cardNumModal = ref<InstanceType<typeof CardNumModal>>();

const store = useStore();

const schoolYearCardNum = ref(0);

const onRefresh = () => {
  location.reload();
};

const onScrollToTop = () => {
  window.scroll({ top: 0, behavior: "smooth" });
};

const monthlyLiked = ref(0);

const unsubscribeStore = store.subscribe(async (mutation) => {
  if (mutation.type === "setFilter" || mutation.type === "setSchoolYear") {
    const cardSelector = store.state.cardSelector;
    if (cardSelector.filter == "schoolyear") {
      schoolYearCardNum.value = await getSchoolYearCardNum(
        store.state.user,
        cardSelector.schoolYear
      );
    }
  }
});

watch(store.state, async () => {
  if (typeof store.state.numLiked.lastLiked == "undefined") return;
  monthlyLiked.value = store.state.numLiked.getMonthlyNumLiked();
});

const onMonthlyLiked = () => {
  store.commit("setSort", "monthlyliked");
};

const openCardNumModal = () => {
  cardNumModal.value?.openModal();
};

onUnmounted(() => {
  unsubscribeStore();
});
</script>
