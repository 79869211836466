<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import { useStore } from "@/store/store";
import { useModal } from "./ModalInterface";

import { NewPasswordPair } from "@/domain/password";
import ProcessingSpinner, { useSpinner } from "@/components/ProcessingSpinner.vue";
import Hiragana from "@/components/Hiragana.vue";

export default defineComponent({
  name: "PasswordChanger",
  components: { ProcessingSpinner, Hiragana },

  setup() {
    const store = useStore();

    const newPasswordPair = new NewPasswordPair();
    const newPassword1 = ref("");
    const newPassword2 = ref("");
    const result = reactive({ isValid: false, errorMessages: [] as string[] });

    const initModal = () => {
      newPassword1.value = "";
      newPassword2.value = "";
      result.isValid = false;
      result.errorMessages = [];
    };

    const { openModal, closeModal } = useModal("changePasswordModal", initModal);

    const { spinner, startProcess, finishProcess } = useSpinner();

    const onChanged = async () => {
      const { isValid, errorMessages } = await newPasswordPair.setNewPair(
        newPassword1.value,
        newPassword2.value
      );
      result.isValid = isValid;
      result.errorMessages = errorMessages;
    };

    const onSubmit = async () => {
      startProcess();
      await newPasswordPair.toUser(store.state.auth.uid);

      finishProcess(() => {
        closeModal();
        store.dispatch("signOut");
      });
    };

    return {
      store,
      newPassword1,
      newPassword2,
      onChanged,
      result,
      openModal,
      closeModal,
      onSubmit,
      spinner,
    };
  },
});
</script>

<template>
  <div class="modal fade" id="changePasswordModal" tabindex="-1">
    <div class="modal-dialog">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <Hiragana f="パスワードのへんこう">パスワードの変更</Hiragana>
            </h5>
          </div>
          <div class="modal-body">
            <p>
              <Hiragana
                f="8もじいじょう にゅうりょくしてください。アルファベット(a〜z/A〜Z)と すうじ(0〜9)を りょうほう ふくめてください。"
                >8文字以上入力してください。アルファベット(a〜z/A〜Z)と数字(0〜9)を両方含めてください。</Hiragana
              >
            </p>
            <div class="mb-3 row">
              <label for="newPassword1" class="col-5 col-form-label"
                ><Hiragana f="あたらしい パスワード">新しいパスワード</Hiragana>
              </label>
              <div class="col-6">
                <input
                  type="password"
                  class="form-control"
                  id="newPassword1"
                  autocomplete="new-password"
                  v-model="newPassword1"
                  @input="onChanged"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="newPassword2" class="col-5 col-form-label"
                ><Hiragana f="かくにんようパスワード">確認用パスワード</Hiragana></label
              >
              <div class="col-6">
                <input
                  type="password"
                  class="form-control"
                  id="newPassword2"
                  autocomplete="new-password"
                  v-model="newPassword2"
                  @input="onChanged"
                />
              </div>
            </div>
            <div v-for="(msg, idx) in result.errorMessages" :key="idx" class="mb-3 row">
              <div class="col">
                <div class="alert alert-danger" role="alert">
                  {{ msg }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              キャンセル
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="onSubmit"
              :disabled="!result.isValid || spinner?.processing"
            >
              <ProcessingSpinner ref="spinner" />
              <Hiragana f="へんこう">変更</Hiragana>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
