import { SchoolType } from "./../domain/School";
import { getFirestore } from "@firebase/firestore";
import { getFunctions } from "../infrastructure/firebase";
import {
  FirestoreDataConverter,
  FieldValue,
  serverTimestamp,
  Bytes,
  doc,
  collection,
  addDoc,
  setDoc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  getCountFromServer,
  CollectionReference,
  DocumentReference,
} from "firebase/firestore";

import { httpsCallable } from "firebase/functions";
import { Card, Status } from "../domain/Card";
import { getSchool } from "../infrastructure/SchoolRepository";
// @ts-expect-error: for compatibility
import { randomBytes } from "crypto-browserify";
// @ts-expect-error: for compatibility
import { deflateSync, inflateSync } from "browserify-zlib";
import { Buffer } from "buffer";
import { User } from "../domain/User";
import { Timestamp } from "@google-cloud/firestore";
import { cardsColName } from "./CardsColName";

function generateRandomString(length: number) {
  return (randomBytes(length) as number[]).reduce(
    (p, i) => p + (i % 32).toString(32),
    ""
  );
}

const imageJsonToBytes = (imageJson: any) => {
  if (typeof imageJson === "undefined") {
    const bytes = Bytes.fromBase64String("");
    return bytes;
  }
  const stringifiedJson = JSON.stringify(imageJson);
  const zippedStringfiedJson = deflateSync(stringifiedJson);

  const bytes = Bytes.fromUint8Array(zippedStringfiedJson);
  return bytes;
};

const bytesToImageJson = (bytes: Bytes | undefined): any => {
  if (typeof bytes === "undefined") return null;
  if (bytes.toUint8Array().length > 0) {
    const zippedStringfiedJson = Buffer.from(bytes.toUint8Array());
    const stringifiedJson = inflateSync(zippedStringfiedJson).toString();
    const imageJson = JSON.parse(stringifiedJson);
    return imageJson;
  } else {
    return null;
  }
};

type docDataTypeFromFirebase = Partial<
  Card & {
    createdAt: { seconds: number; nanosecondes: number };
    updatedAt: { seconds: number; nanosecondes: number };
    reviewHandwritten: Bytes;
    lastLiked: Timestamp;
  }
>;

type docDataTypeToFirebase = Partial<
  Card & {
    createdAt: FieldValue;
    updatedAt: FieldValue;
    reviewHandwritten: Bytes;
  }
>;

export const cardConverter: FirestoreDataConverter<Card> = {
  toFirestore(card: Card) {
    const docData: docDataTypeToFirebase = {
      userID: card.userID,
      schoolID: card.schoolID,
      status: card.status,
      recommended: card.recommended,
      updatedAt: serverTimestamp(), // use servertimestamp
      bookISBN: card.bookISBN,
      bookTitle: card.bookTitle,
      bookAuthors: card.bookAuthors,
      bookPublishYear: card.bookPublishYear,
      bookPublisher: card.bookPublisher,
      bookCCode: card.bookCCode,
      bookThumbnailUrl: card.bookThumbnailUrl,
      reviewTyped: card.reviewTyped,
      reviewHandwritten: imageJsonToBytes(card.reviewHandwrittenJson), // converted for firestore
      tags: card.tags,
      reviewerAdmissionYear: card.reviewerAdmissionYear,
      reviewerSchoolYear: card.reviewerSchoolYear,
      reviewerSchoolYearClassHash: card.reviewerSchoolYearClassHash,
      random0: generateRandomString(8),
      random1: generateRandomString(8),
      random2: generateRandomString(8),
      random3: generateRandomString(8),
      random4: generateRandomString(8),
      random5: generateRandomString(8),
      random6: generateRandomString(8),
      random7: generateRandomString(8),
      random8: generateRandomString(8),
      random9: generateRandomString(8),
    };
    // if card id is undefined, the document is newliy created on Firestore
    if (typeof card.id === "undefined") {
      return {
        ...docData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        numLiked: 0,
        numReported: 0,
      };
    }
    return docData;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options) as docDataTypeFromFirebase;

    return new Card(
      snapshot.id,
      data.userID ?? "",
      data.schoolID ?? "",
      data.status ?? "draft",
      data.recommended ?? false,
      data.createdAt?.seconds ?? 0,
      data.updatedAt?.seconds ?? 0,
      data.bookISBN ?? "",
      data.bookTitle ?? "",
      data.bookAuthors ?? "",
      data.bookPublishYear ?? 0,
      data.bookPublisher ?? "",
      data.bookCCode ?? "",
      data.bookThumbnailUrl ?? "",
      data.reviewTyped ?? "",
      bytesToImageJson(data.reviewHandwritten ?? undefined) ?? null, // converted for firestore
      data.tags ?? ([] as string[]),
      data.reviewerAdmissionYear ?? 0,
      data.reviewerSchoolYear ?? 0,
      data.reviewerSchoolYearClassHash ?? "",
      data.random0 ?? "",
      data.random1 ?? "",
      data.random2 ?? "",
      data.random3 ?? "",
      data.random4 ?? "",
      data.random5 ?? "",
      data.random6 ?? "",
      data.random7 ?? "",
      data.random8 ?? "",
      data.random9 ?? "",
      data.numLiked ?? 0,
      data.numReported ?? 0,
      data.lastLiked?.toDate() ?? new Date()
    );
  },
};

export const getCardsRef = (
  schoolType: SchoolType
): CollectionReference<Card> =>
  collection(getFirestore(), cardsColName[schoolType]).withConverter(
    cardConverter
  );
export const getCardRef = (
  id: string,
  schoolType: SchoolType
): DocumentReference<Card> =>
  doc(getFirestore(), cardsColName[schoolType], id).withConverter(
    cardConverter
  );

let titleLastCreated = "";

export const storeCard = async (
  card: Card,
  schoolType: SchoolType
): Promise<string> => {
  const id = card.id;
  if (typeof id === "undefined") {
    // 二重で同じタイトルの本のカードが登録できないようにするための対処療法
    if (titleLastCreated === card.bookTitle) return "";
    titleLastCreated = card.bookTitle;

    const newRef = await addDoc(getCardsRef(schoolType), card);
    return newRef.id;
  } else {
    await setDoc(getCardRef(id, schoolType), card, { merge: true });
    return id;
  }
};

export const getCard = async (
  id: string,
  schoolType: SchoolType
): Promise<Card> => {
  const snapshot = await getDoc(getCardRef(id, schoolType));
  if (!snapshot.exists()) throw new Error(`Document for ${id} does not exist`);

  const card = snapshot.data();

  return card;
};

export const getCardPublicByUserId = async (
  userId: string,
  schoolType: SchoolType
): Promise<Card[]> => {
  const snapshot = await getDocs(
    query(
      getCardsRef(schoolType),
      where("userID", "==", userId),
      where("status", "==", "public"),
      orderBy("createdAt")
    )
  );

  const cards: Card[] = [];
  snapshot.forEach((doc) => {
    cards.push(doc.data());
  });

  return cards;
};

export const getCardExampleByIsbn = async (
  isbn: string,
  schoolType: SchoolType
): Promise<Card> => {
  const snapshot = await getDocs(
    query(getCardsRef(schoolType), where("bookISBN", "==", isbn), limit(1))
  );

  const cards: Card[] = [];
  snapshot.forEach((doc) => {
    cards.push(doc.data());
  });
  if (cards.length === 0) {
    console.warn("No example card.");
    return new Card();
  } else {
    return cards[0];
  }
};

export const getLastDuplicatedCard = async (
  isbn: string,
  uid: string,
  repeatedPostLimit: number,
  schoolType: SchoolType
): Promise<Card | undefined> => {
  const docs = await getDocs(
    query(
      getCardsRef(schoolType),
      where("bookISBN", "==", isbn),
      where("userID", "==", uid),
      orderBy("createdAt", "desc"),
      limit(1)
    )
  );

  if (!docs.empty) {
    let duplicatedCard: Card | undefined = undefined;
    const now = Date.now() / 1000;
    docs.forEach((doc) => {
      const card = doc.data();
      const cardLifetime = now - card.createdAtSecond;
      if (cardLifetime < repeatedPostLimit * 60) {
        duplicatedCard = card;
      }
    });
    return duplicatedCard;
  }
  return;
};

export const getNumLikesBySchool = async (
  cardId: string,
  schoolType: SchoolType
): Promise<
  {
    city: string;
    schoolName: string;
    numLikes: number;
  }[]
> => {
  // get schoolIds that like the card
  const snapshot = await getDoc(
    doc(getFirestore(), cardsColName[schoolType], cardId, "Likes", "schools")
  );
  const schoolIds = snapshot.data() as { [schoolID: string]: number };

  if (typeof schoolIds === "undefined") return [];

  // Add to numLikesBySchool
  const numLikesBySchool = (
    await Promise.all(
      Object.keys(schoolIds).map(async (key) => {
        const school = await getSchool(key);
        return {
          city: school.city,
          schoolName: school.schoolName,
          numLikes: schoolIds[key],
        };
      })
    )
  ).sort((a, b) => b.numLikes - a.numLikes);

  return numLikesBySchool;
};

export const deleteCard = async (
  card: Card,
  schoolType: SchoolType
): Promise<void> => {
  await httpsCallable(
    getFunctions(),
    "deleteCardCompletely"
  )({
    cardId: card.id,
    schoolType,
  });
};

export const reportCard = async (
  card: Card,
  schoolType: SchoolType
): Promise<void> => {
  await httpsCallable(
    getFunctions(),
    "onReport"
  )({
    cardId: card.id,
    reported: true,
    schoolType,
  });
};

export const unreportCard = async (
  card: Card,
  schoolType: SchoolType
): Promise<void> => {
  await httpsCallable(
    getFunctions(),
    "onReport"
  )({
    cardId: card.id,
    reported: false,
    schoolType,
  });
};

export const getLatestLikedCount = async (
  user: User,
  from: Date
): Promise<{ count: number; date: Date }> => {
  const countSnapshot = await getCountFromServer(
    query(
      getCardsRef(user.schoolType),
      where("userID", "==", user.id),
      where("status", "==", "public"),
      where("lastLiked", ">=", from),
      orderBy("lastLiked", "desc")
    )
  );
  const count = countSnapshot.data().count;

  if (count > 0) {
    const docSnapshot = await getDocs(
      query(
        getCardsRef(user.schoolType),
        where("userID", "==", user.id),
        where("status", "==", "public"),
        orderBy("lastLiked", "desc"),
        limit(1)
      )
    );
    if (!docSnapshot.empty) {
      const date = docSnapshot.docs[0].data().lastLiked;
      return { count, date };
    }
  }
  return { count: 0, date: new Date("2020-01-01") };
};

export const getLatestLikedCards = async (
  user: User,
  from: Date
): Promise<Card[]> => {
  const snapshot = await getDocs(
    query(
      getCardsRef(user.schoolType),
      where("userID", "==", user.id),
      where("status", "==", "public"),
      where("lastLiked", ">=", from),
      orderBy("lastLiked", "desc")
    )
  );

  const cards = snapshot.docs.map((doc) => doc.data());

  return cards;
};

export const setBanCard = async (
  cardId: string,
  schoolType: SchoolType,
  banned: boolean
): Promise<void> => {
  const status: Status = banned ? "banned" : "public";
  await updateDoc(getCardRef(cardId, schoolType), { status });
};
