import { QueryConstraint, where, orderBy } from "firebase/firestore";
import { CardSelector } from "@/domain/cardSelector";

export const getBookQueryConstrains = (
  cardSelector: CardSelector
): QueryConstraint[] => {
  const queryConstraints: QueryConstraint[] = [];
  // Clear query buffer
  queryConstraints.splice(0);
  if (
    cardSelector.reviewsCreatedFiscalYear > 0 &&
    cardSelector.reviewsSchoolYear > 0
  ) {
    queryConstraints.push(
      where(
        `numsy${cardSelector.reviewsSchoolYear}cy${cardSelector.reviewsCreatedFiscalYear}`,
        ">",
        0
      ),
      orderBy(
        `numsy${cardSelector.reviewsSchoolYear}cy${cardSelector.reviewsCreatedFiscalYear}`,
        "desc"
      )
    );
  } else if (cardSelector.reviewsSchoolYear > 0) {
    queryConstraints.push(
      where(`numsy${cardSelector.reviewsSchoolYear}`, ">", 0),
      orderBy(`numsy${cardSelector.reviewsSchoolYear}`, "desc")
    );
  } else if (cardSelector.reviewsCreatedFiscalYear > 0) {
    queryConstraints.push(
      where(`numcy${cardSelector.reviewsCreatedFiscalYear}`, ">", 0),
      orderBy(`numcy${cardSelector.reviewsCreatedFiscalYear}`, "desc")
    );
  } else {
    queryConstraints.push(where("num", ">", 0), orderBy("num", "desc"));
  }
  return queryConstraints;
};
