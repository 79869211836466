<template>
  <div class="modal fade" id="cardNumModal" tabindex="-1">
    <div class="modal-dialog modal-xl modal-fullscreen-xl-down modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">カード<Hiragana f="枚数">枚数</Hiragana></h3>
          <button type="button" class="btn-close" @click="onClose"></button>
        </div>
        <div class="modal-body">
          <UserCardNum v-if="store.state.user?.id" :user="store.state.user" />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="onClose"
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, onMounted, computed } from "vue";
import { useModal } from "./ModalInterface";
import { useStore } from "@/store/store";
import UserCardNum from "@/components/UserCardNum.vue";
import Hiragana from "@/components/Hiragana.vue";

export default defineComponent({
  name: "CardNumModal",
  components: { UserCardNum, Hiragana },

  setup(props) {
    const store = useStore();

    onMounted(() => {
      //openModal();
    });

    const initModal = () => {};

    const { openModal, closeModal } = useModal("cardNumModal", initModal, true);

    const onClose = () => {
      closeModal();
    };

    return {
      store,
      openModal,
      props,
      onClose,
    };
  },
});
</script>
